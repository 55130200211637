import React from 'react';
import { Link } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import { Col, Row, Card, CardHeader, Button, Alert } from 'reactstrap';

import Toggle from '../../Notify/components/Toggle';

export default class TouristsTable extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      toggleErrors: {},
    };
  }

  render() {
    const {
      isSuperAdmin,
      items,
      toggleItem,
      toggleEmail,
      toggleSMS,
      toggleErrors,
      selected,
    } = this.props;

    return (
      <div>
        <table
          className="table table--blue table-bordered table-striped
          table-sm animated fadeIn"
        >
          <thead>
            <tr>
              <th className="text-center tableItem">*</th>
              <th className="text-center tableItem">номер заявки в СБ</th>
              <th className="text-center tableItem">Имя</th>
              <th className="text-center tableItem">ДР</th>
              <th className="text-center tableItem">Телефоны</th>
              <th className="text-center tableItem">Эл. почты</th>
              <th className="text-center tableItem">Менеджер сейчас</th>
              <th className="text-center tableItem">Менеджер ранее</th>
              <th className="text-center tableItem">Уведомления</th>
              <th className="text-center tableItem">Метки</th>
              <th className="">Офис</th>
              <th className="">Город вылета</th>
            </tr>
          </thead>
          <tbody>
            {items.map((item, idx) => {
              const info_id = item.tourist_fp_info.id;
              const {
                id,
                fp_pk,
                full_name,
                full_name_eng,
                born,
                phone,
                email,
                managers,
                managers_fp,
                tourist_fp_info,
                tag,
                office,
                depart_cities,
              } = item;

              return (
                <tr key={idx}>
                  <td className="text-center tableItem">
                    <input
                      id={`tourist-${id}`}
                      type="checkbox"
                      checked={selected.includes(id)}
                      onChange={() => toggleItem(item)}
                    />
                  </td>
                  <td className="text-center tableItem">{fp_pk}</td>
                  <td className="text-center tableItem">
                    <Link
                      to={`/tourists/${id}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {full_name.length > 1 ? full_name : full_name_eng}
                    </Link>
                  </td>
                  <td className="text-center tableItem">
                    {moment(born).format('DD.MM.YYYY')}
                  </td>
                  <td className="text-center tableItem">
                    {Array.isArray(phone) &&
                      phone.map((i, idx) => (
                        <a key={idx} href={`tel://${i.value}`}>
                          {i.value}
                        </a>
                      ))}
                  </td>
                  <td className="text-center tableItem">
                    {Array.isArray(email) && email.length
                      ? email
                          .map((i) => i.value)
                          .filter((i) => i)
                          .join(`, `)
                      : null}
                  </td>
                  <td className="text-center tableItem">
                    {managers
                      ? managers.map((m, i) => {
                          return (
                            <div key={i}>
                              <b>{m.name ? m.name : m.username}</b>
                            </div>
                          );
                        })
                      : null}
                  </td>
                  <td className="text-center tableItem">
                    {managers_fp
                      ? managers_fp.map((m, i) => {
                          return (
                            <div key={i}>
                              <b>{m.name}</b>
                            </div>
                          );
                        })
                      : null}
                  </td>
                  <td className="text-center tableItem">
                    <div className="text-small-mobile flex-column-mobile">
                      <Toggle
                        id={info_id}
                        lang="Email"
                        checked={tourist_fp_info.is_email_notify}
                        onChange={() => toggleEmail(item)}
                      />
                      <Toggle
                        id={info_id}
                        lang="SMS"
                        checked={tourist_fp_info.is_sms_notify}
                        onChange={() => toggleSMS(item)}
                      />
                      {!!toggleErrors[info_id] &&
                      Object.keys(toggleErrors[info_id]).length
                        ? Object.keys(toggleErrors[info_id]).map((key) => (
                            <Alert color="danger" key={key}>
                              {toggleErrors[info_id][key]}
                            </Alert>
                          ))
                        : null}
                    </div>
                  </td>
                  <td className="text-center tableItem">
                    {tag
                      ? tag.map((tag, key) => {
                          return (
                            <p
                              className="badge badge-success badge-pill"
                              key={key}
                            >
                              {tag.name}
                            </p>
                          );
                        })
                      : null}
                  </td>
                  <td className="">
                    {office && <span>{office.address}</span>}
                  </td>
                  <td className="">
                    <span>
                      {depart_cities.map((el) =>
                        el === null ? '' : el + ', '
                      )}
                    </span>
                  </td>
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    );
  }
}
