import moment from 'moment';

import PropTypes from 'prop-types';

import React, { Component } from 'react';
import Select from 'react-select';
import { Alert } from 'reactstrap';
import ReactSelectCreatable from 'react-select/creatable';

import {
  SUPER_ADMIN,
  ADMIN,
  getUserInfo,
  getUserRole,
} from '../../../../connect/auth';
import APIClient, {
  POSITIVE_ACTION_STATUSES,
  EventsAPI,
} from '../../../../api/Client';
import ClientAPI from '../../../../api/Client';
import './CalendarAddEvent.css';

const TIMEOUT = 2000;

class CalendarAddEvent extends Component {
  constructor(props) {
    super(props);
    this.onSubmitClick = this.onSubmitClick.bind(this);
    this.onCancelClick = this.onCancelClick.bind(this);
    this.onChangeStart = this.onChangeStart.bind(this);
    this.onFocusStart = this.onFocusStart.bind(this);
    this.onChangeEnd = this.onChangeEnd.bind(this);
    this.onFocusEnd = this.onFocusEnd.bind(this);
    this.onBlurNotes = this.onBlurNotes.bind(this);
    this.onChangeNotes = this.onChangeNotes.bind(this);
    this.onFocusNotes = this.onFocusNotes.bind(this);
    this.onChangeUser = this.onChangeUser.bind(this);

    this.state = {
      start_time: null,
      end_time: null,
      is_start_time_error: false,
      is_end_time_error: false,
      notes: '',
      is_notes_error: false,
      isSuccess: false,
      isError: null,

      user: null,
      users: [],
    };
  }

  componentDidMount() {
    const userRole = getUserRole(),
      canSetUser = userRole === SUPER_ADMIN || userRole === ADMIN;
    let status;
    const user_info = getUserInfo();
    ClientAPI.getJSON(`/users/${user_info.user_id}/`).then((user) => {
      this.setState({ user });
    });

    if (canSetUser) {
      EventsAPI.fetchList(1, {}, `user_list/`)
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((r) => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              users: r || [],
            });
          }
        });
    }
  }

  onSubmitClick(e) {
    e.preventDefault();
    const self = this;

    self.setState(
      (prevState) => ({
        is_start_time_error: !prevState.start_time,
        is_end_time_error: !prevState.end_time,
        is_notes_error: !prevState.notes,
      }),
      () => {
        const {
          start_time,
          is_start_time_error,
          end_time,
          is_end_time_error,
          notes,
          is_notes_error,
          user,
        } = self.state;
        const { date, onSubmit } = self.props;
        let status;

        if (
          [is_start_time_error, is_end_time_error, is_notes_error].every(
            (item) => !item
          )
        ) {
          let data = {
            start_time: start_time.value,
            end_time: end_time.value,
            notes: notes,
            day: date,
          };
          if (user) {
            data['user'] = user.id || user.user_id;
          }
          APIClient.create(`/events`, data)
            .then((r) => {
              status = r.status;
              return r.json();
            })
            .then((r) => {
              if (status === POSITIVE_ACTION_STATUSES.create) {
                self.setState(
                  {
                    isSuccess: true,
                    isError: null,
                  },
                  () => setTimeout(() => onSubmit(r), TIMEOUT)
                );
              } else {
                self.setState({
                  isSuccess: false,
                  isError: r.user[0],
                });
              }
            });
        }
      }
    );
  }

  onCancelClick(e) {
    e.preventDefault();
    this.props.onCancel();
  }

  validateTime(time) {
    return moment(
      `${this.props.date} ${time}`,
      'YYYY-MM-DD hh:mm',
      true
    ).isValid();
  }

  onChangeStart(start_time) {
    this.setState({ start_time });
  }

  onFocusStart(e) {
    this.setState({
      is_start_time_error: false,
      isSuccess: false,
      isError: null,
    });
  }

  onChangeEnd(end_time) {
    this.setState({ end_time });
  }

  onFocusEnd(e) {
    this.setState({
      is_end_time_error: false,
      isSuccess: false,
      isError: null,
    });
  }

  onBlurNotes(e) {
    this.setState({ is_notes_error: !this.state.notes });
  }

  onChangeNotes(e) {
    this.setState({
      notes: e.target.value,
      is_notes_error: !e.target.value,
    });
  }

  onFocusNotes(e) {
    this.setState({
      is_notes_error: false,
      isSuccess: false,
      isError: null,
    });
  }

  onChangeUser(user) {
    this.setState({ user });
  }

  render() {
    let times = [];
    for (let i = 6; i < 24; i++) {
      let _value = i < 10 ? `0${i}:00` : `${i}:00`;
      times.push({ value: _value, label: _value });
      _value = i < 10 ? `0${i}:15` : `${i}:15`;
      times.push({ value: _value, label: _value });
      _value = i < 10 ? `0${i}:30` : `${i}:30`;
      times.push({ value: _value, label: _value });
      _value = i < 10 ? `0${i}:45` : `${i}:45`;
      times.push({ value: _value, label: _value });
    }
    const {
      is_start_time_error,
      is_end_time_error,
      is_notes_error,
      end_time,
      start_time,
      notes,
      isSuccess,
      isError,
    } = this.state;
    
    return (
      <div className="add-event-form container filter">
        <div className="card">
          <div className="card-header">
            <strong>Добавить</strong> событие
          </div>
          <div className="card-body">
            <form onSubmit={this.onSubmitClick} className="form-horizontal">
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-addon col-sm-4">Начало</span>
                      <ReactSelectCreatable
                        className="form-control p-0"
                        closeOnSelect={true}
                        name="start_time"
                        onChange={this.onChangeStart}
                        onFocus={this.onFocusStart}
                        options={times}
                        placeholder="Выберите / Введите время"
                        formatOptionLabel={(option) => `${option.label}`}
                        value={start_time}
                      />
                    </div>
                    {is_start_time_error && (
                      <div className="invalid-feedback">
                        Пожалуйста, укажите начало
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-addon col-sm-4">
                        Окончание
                      </span>
                      <ReactSelectCreatable
                        className="form-control p-0"
                        closeOnSelect={true}
                        name="end_time"
                        onChange={this.onChangeEnd}
                        onFocus={this.onFocusEnd}
                        options={times}
                        placeholder="Выберите / Введите время"
                        formatOptionLabel={(option) => `${option.label}`}
                        value={end_time}
                      />
                    </div>
                    {is_end_time_error && (
                      <div className="invalid-feedback">
                        Пожалуйста, укажите окончание
                      </div>
                    )}
                  </div>
                </div>
              </div>
              <div className="row" style={{ position: 'relative' }}></div>
              <div className="row">
                <div className="col-sm-6">
                  <div className="form-group">
                    <div className="input-group">
                      <span className="input-group-addon col-sm-4">
                        Текст события
                      </span>
                      <textarea
                        className="form-control"
                        onBlur={this.onBlurNotes}
                        onChange={this.onChangeNotes}
                        onFocus={this.onFocusNotes}
                        name="notes"
                        value={notes}
                      />
                    </div>
                    {is_notes_error && (
                      <div className="invalid-feedback">
                        Пожалуйста укажите текст события
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {this.state.users.length > 1 && (
                <div className="row">
                  <div className="col-sm-6">
                    <div className="form-group">
                      <div className="input-group">
                        <span className="input-group-addon col-sm-4">
                          Назначить на
                        </span>
                        <Select
                          className="form-control p-0"
                          isClearable={false}
                          closeOnSelect={true}
                          name="user"
                          onChange={this.onChangeUser}
                          options={this.state.users}
                          placeholder="Себя"
                          value={this.state.user}
                          formatOptionLabel={(option) =>
                            option.owner
                              ? 'Себя'
                              : option.first_name
                              ? 'Себя'
                              : option.full_name
                          }
                          getOptionValue={(option) => option.id}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              )}
              <div className="row">
                <button className="btn btn-sm btn-primary">Добавить</button>
                <button
                  onClick={this.onCancelClick}
                  className="btn btn-sm btn-danger"
                >
                  Отмена
                </button>
              </div>
            </form>
          </div>
        </div>
        {isSuccess && <Alert>Успешно</Alert>}
        {isError && <Alert color="warning">Ошибка: {isError}</Alert>}
      </div>
    );
  }
}

CalendarAddEvent.propTypes = {
  onSubmit: PropTypes.func,
  onCancel: PropTypes.func,
  _day: PropTypes.string,
  _start_time: PropTypes.string,
  _end_time: PropTypes.string,
  _notes: PropTypes.string,
};

export default CalendarAddEvent;
