import React from 'react';
import { Link } from 'react-router-dom';
import {
  Col,
  Row,
  Card,
  CardBody,
  CardHeader,
  Button,
  Alert,
} from 'reactstrap';
import Paginator from '../../components/Paginator';
import ProgressBar from '../../components/ProgressBar';
import ClientAPI, {
  PersonAPI,
  POSITIVE_ACTION_STATUSES,
  TouristFpAPI,
} from '../../api/Client';
import TouristsFpFilter from '../TouristsFp/components/TouristsFpFilter';
import TouristsTable from './components/TouristsTable';
import TouristsList from './components/TouristsList';
import { getUserRole, SUPER_ADMIN } from '../../connect/auth';
import { changePageNumber, initPageNumber } from '../../lib/url-search-params';
import Swal from 'sweetalert';
import { clearAllFilterParams } from '../../Utils/clearEmailfilter';

const PAGE_NAME = 'tourist_page';

export default class TouristsFp extends React.PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      count: 0,
      items: [],
      pageNumber: initPageNumber(PAGE_NAME),
      tags: [],
      selected: [],
      filters: {},
      mergerIsError: false,
      mergerIsSuccess: false,
      deactivateIsSuccess: false,
      errorMessages: [],
      mergerRelations: {},
      isDisabledFilter: true,
      toggleErrors: {},
      type: 'table',
      isLoading: false,
    };
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.handleOnPageChange = this.handleOnPageChange.bind(this);
    this.toggleEmailNotify = this.toggleEmailNotify.bind(this);
    this.toggleSmsNotify = this.toggleSmsNotify.bind(this);
    this.toggleViberNotify = this.toggleViberNotify.bind(this);
    this.createMail = this.createMail.bind(this);
    this.createMailWarning = this.createMailWarning.bind(this);
    this.toggleItem = this.toggleItem.bind(this);
    this.toggleNotify = this.toggleNotify.bind(this);
    this.createMergers = this.createMergers.bind(this);
    this.deactivateTourist = this.deactivateTourist.bind(this);

    clearAllFilterParams();
  }

  createMail() {
    if (this.state.filters) {
      localStorage.setItem(
        'touristsfp_filter',
        JSON.stringify(this.state.filters)
      );
      localStorage.setItem(
        'touristsfp_total',
        JSON.stringify(this.state.count)
      );
      localStorage.setItem('type', 'touristfp');
    }
    return false;
  }

  createMailWarning() {
    Swal(`Для создания рассылки выберите фильтры по e-mail, телефон или viber. Можно выбрать по меткам.

      После этого список будет сформирован.`);
  }

  deactivateTourist(e) {
    e.preventDefault();
    const { selected, pageNumber } = this.state;
    let status = 200;
    PersonAPI.deactivateTourist(
      `touristsfp/activity/?${
        selected.length ? selected.map((i) => `id=${i}`).join('&') : ''
      }`
    )
      .then((response) => {
        status = response.status;
        return response.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.update) {
          this.setState({ deactivateIsSuccess: true }, () =>
            setTimeout(() => this.fetchItems(pageNumber), 1000)
          );
        }
      });
  }

  createMergers(e) {
    e.preventDefault();
    const { selected } = this.state;
    if (selected.length < 2) {
      Swal('выберите клиентов для слияния');
      return false;
    }
    const selectedToManipulate = [...selected];
    const target = e.target;
    const { items } = this.state;
    const tourists = {};

    items.forEach((item) => (tourists[item.id] = item.fp_pk));

    selectedToManipulate.sort((a, b) => {
      return tourists[b] - tourists[a];
    });
    const main = selectedToManipulate.shift();
    const mergerRelations = [];
    const query = {
      duplicates: selectedToManipulate.map((elem) => {
        mergerRelations.push({
          duplicate: tourists[elem],
          tourist: tourists[main],
        });
        return { duplicate: elem };
      }),
    };
    let status = 200;
    PersonAPI.update(`/touristsfp/${main}/duplicates`, query)
      .then((response) => {
        selectedToManipulate.push(main);
        status = response.status;
        return response.json();
      })
      .then((result) => {
        try {
          if (status === POSITIVE_ACTION_STATUSES.update) {
            this.setState(
              {
                ...this.state,
                mergerIsSuccess: true,
                mergerIsError: false,
                errorMessages: {},
                mergerRelations: mergerRelations,
                toggleErrors: {},
              },
              () => {
                target.disabled = true;
              }
            );
          } else {
            this.setState(
              {
                ...this.state,
                mergerIsSuccess: false,
                mergerIsError: true,
                errorMessages: !result.duplicates ? result : result.duplicates,
                mergerRelations: mergerRelations,
                toggleErrors: {},
              },
              () => {
                target.disabled = false;
              }
            );
          }
        } catch (e) {
          this.setState(
            {
              ...this.state,
              mergerIsSuccess: false,
              mergerIsError: true,
              errorMessages: { non_field_errors: ['Неизвестная ошибка'] },
              mergerRelations: mergerRelations,
              toggleErrors: {},
            },
            () => {
              target.disabled = false;
            }
          );
        }
      });

    this.setState({
      ...this.state,
      selected: [],
    });
  }

  filterItems(st) {
    let result = {};
    if (st.queryInput) {
      result.q = st.queryInput;
    }
    if (st.phone) {
      result.q = st.phone;
    }
    if (st.closedAgent !== null) {
      result.closed_agent = st.closedAgent.value;
    }
    if (st.email) {
      result.q = st.email;
    }
    if (st.cityDeparture) {
      result.q = st.cityDeparture;
    }
    if (st.selectedTags) {
      result.tag = st.selectedTags.map((tag) => tag.value);
    }
    if (st.orderings) {
      result.order_by = st.orderings.map((order) => order.value);
    }
    if (st.manager) {
      result.orders__manager = st.manager.value;
    }
    if (st.manager_fp) {
      result.orders__manager_fp = st.manager_fp;
    }
    if (st.hasEmails) {
      result.has_emails = st.hasEmails.value;
    }
    if (st.hasPhones) {
      result.has_phones = st.hasPhones.value;
    }
    if (st.hasViber) {
      result.has_viber = st.hasViber.value;
    }
    if (st.isActive) {
      result.is_active = st.isActive.value;
    }
    if (st.offices) {
      result.office_id = st.offices.map(o => o.id);
    }
    if (result) {
      this.setState(
        {
          filters: result,
          selected: [],
          mergerIsError: false,
          mergerIsSuccess: false,
          errorMessages: [],
          isDisabledFilter: true,
          toggleErrors: {},
        },
        () => this.fetchItems(1)
      );
    }
  }

  fetchItems(page = 1) {
    const self = this;
    const filters = { ...self.state.filters };
    let status;
    this.setState(
      {
        ...this.state,
        isLoading: true,
      },
      () => {
        TouristFpAPI.fetchList(page, filters)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((result) => {
            self.setState({
              items: result.results || [],
              count: result.count || 0,
              pageNumber: page,
              selected: [],
              mergerIsError: false,
              mergerIsSuccess: false,
              deactivateIsSuccess: false,
              errorMessages: [],
              toggleErrors: {},
              isDisabledFilter: status !== POSITIVE_ACTION_STATUSES.list,
              isLoading: false,
            });
          });
      }
    );
  }

  handleOnPageChange(data) {
    const page = data.selected + 1;
    changePageNumber(PAGE_NAME, page);
    this.fetchItems(page);
  }

  toggleNotify(item, type) {
    let status;
    const infoId = parseInt(item.tourist_fp_info.id, 10);
    if (!isNaN(infoId)) {
      ClientAPI.update(`/touristsfp_info/${infoId}`, {
        [`is_${type}_notify`]: !item.tourist_fp_info[`is_${type}_notify`],
      })
        .then((r) => {
          status = r.status;
          return r.json();
        })
        .then((res) => {
          if (status === POSITIVE_ACTION_STATUSES.update && this.state.items) {
            let isUpdate = false;
            const items = this.state.items.map((item) => {
              const data = item;
              if (data.tourist_fp_info.id === infoId) {
                data.tourist_fp_info[`is_${type}_notify`] =
                  res[`is_${type}_notify`];
                isUpdate = true;
              }
              return data;
            });

            if (isUpdate) {
              this.setState({
                items: items,
                toggleErrors: {},
              });
            }
          } else {
            this.setState({
              toggleErrors: {
                [infoId]: res,
              },
            });
          }
        });
    }
  }

  toggleEmailNotify(e) {
    this.toggleNotify(e, 'email');
  }

  toggleSmsNotify(e) {
    this.toggleNotify(e, 'sms');
  }

  toggleViberNotify(e) {
    this.toggleNotify(e, 'viber');
  }

  toggleItem(item) {
    let selected = [...this.state.selected];
    if (selected.includes(item.id)) {
      selected = selected.filter((pk) => pk !== item.id);
    } else {
      selected = selected.concat(item.id);
    }

    this.setState({
      ...this.state,
      mergerIsError: false,
      mergerIsSuccess: false,
      selected: selected,
      toggleErrors: {},
    });
  }

  componentDidMount() {
    const { pageNumber } = this.state;
    this.fetchItems(pageNumber);
  }
  componentDidUpdate() {
    // console.log("this.state from Update",this.state)
  }

  render() {
    const {
      items,
      count,
      filters,
      pageNumber,
      selected,
      isDisabledFilter,
      mergerIsError,
      mergerIsSuccess,
      errorMessages,
      mergerRelations,
      deactivateIsSuccess,
      toggleErrors,
    } = this.state;
    const handledErrorMessages = [];
    if (mergerIsError) {
      if (Array.isArray(errorMessages) && errorMessages.length) {
        errorMessages.forEach((item, index) => {
          ['non_field_errors', 'duplicate'].map((f) => {
            if (item[f]) {
              item[f].forEach((message) => {
                handledErrorMessages.push(
                  `Слияние №${mergerRelations[index].tourist}-№${mergerRelations[index].duplicate}: ${message}`
                );
              });
            }
          });
        });
      } else if (errorMessages === 'object' && !!errorMessages) {
        ['non_field_errors', 'duplicates'].forEach((f) => {
          if (Array.isArray(errorMessages[f]) && errorMessages[f].length) {
            errorMessages[f].map((message) =>
              handledErrorMessages.push(`Ошибка запроса: ${message}`)
            );
          }
        });
        if (errorMessages['detail'] === 'string') {
          handledErrorMessages.push(
            `Ошибка запроса: ${errorMessages['detail']}`
          );
        }
      }
    }
    const userRole = getUserRole();
    const isSuperAdmin = userRole === SUPER_ADMIN;
    const toggleItem = (item) => {
      this.toggleItem(item);
    };
    const toggleEmail = (e) => {
      this.toggleNotify(e, 'email');
    };
    const toggleSMS = (e) => {
      this.toggleNotify(e, 'sms');
    };
    const toggleViber = (e) => {
      this.toggleNotify(e, 'viber');
    };

    return (
      <div className="animated fadeIn">
        <div className="filter filter--blue">
          <TouristsFpFilter
            onFilter={this.filterItems}
            disabled={isDisabledFilter}
          />
        </div>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader className="card-header__container">
                <div className="card-header__info-group">
                  <span className="badge badge-info">
                    <i className="fa fa-align-justify hidden-mobile">{''}</i>
                    Всего&nbsp;{count}
                  </span>
                  <div className="card-header__table-mode">
                    <i
                      className="fa fa-list"
                      onClick={() => this.setState({ type: 'list' })}
                      title="показать в виде списка"
                      style={{
                        cursor: 'pointer',
                        color: this.state.type == 'list' ? 'green' : 'white',
                      }}
                    ></i>
                    <i
                      className="fa fa-table"
                      onClick={() => this.setState({ type: 'table' })}
                      title="показать в виде таблицы"
                      style={{
                        cursor: 'pointer',
                        color: this.state.type == 'table' ? 'green' : 'white',
                      }}
                    ></i>
                  </div>
                  {mergerIsError && handledErrorMessages.length
                    ? handledErrorMessages.map((item, index) => (
                        <Alert className="bg-danger" key={index}>
                          {item}
                        </Alert>
                      ))
                    : null}
                  {deactivateIsSuccess ? <Alert>Успешно</Alert> : null}
                  {mergerIsSuccess ? (
                    <div className="row">
                      {mergerRelations.map((item, index) => (
                        <div className="col-md-12" key={index}>
                          <Alert>{`Слияние №${mergerRelations[index].tourist}-№${mergerRelations[index].duplicate}: завершено`}</Alert>
                        </div>
                      ))}
                    </div>
                  ) : null}
                </div>
                <div className="card-header__btn-group">
                  <div
                    className="card-header__delete-btn"
                    hidden={!selected.length}
                  >
                    <Button
                      className="btn btn-danger"
                      onClick={this.deactivateTourist}
                    >
                      <span>
                        <i className="fa fa-exclamation-triangle mr-2">{''}</i>
                        Удалить
                      </span>
                    </Button>
                  </div>
                  {!Object.keys(filters).length ? (
                    <button
                      className="btn btn-info mt-0"
                      onClick={this.createMailWarning}
                    >
                      <i className="fa fa-send mr-2">{''}</i>
                      Создать рассылку
                    </button>
                  ) : (
                    <Link
                      className="btn btn-success mt-0"
                      onClick={this.createMail}
                      to="/templates/"
                      style={{ display: 'inline-block' }}
                    >
                      <span>
                        <i className="fa fa-send mr-2">{''}</i>
                        Создать рассылку
                      </span>
                    </Link>
                  )}
                  <div className="">
                    <Button
                      className="btn btn-info"
                      onClick={this.createMergers}
                    >
                      <span>
                        <i className="fa fa-tasks mr-2">{''}</i>Создать слияние
                      </span>
                    </Button>
                  </div>
                </div>
              </CardHeader>
              {isDisabledFilter ? (
                <CardBody>
                  <div className="row animated fadeIn">
                    <div className="col-md-12 col-md-offset-6 text-center">
                      <ProgressBar />
                    </div>
                  </div>
                </CardBody>
              ) : (
                <CardBody className="card-body_responsive">
                  {!this.state.items.length ? (
                    <p>Туристов не найдено</p>
                  ) : (
                    <div>
                      {this.state.type == 'table' ? (
                        <TouristsTable
                          toggleItem={toggleItem}
                          toggleEmail={toggleEmail}
                          toggleSMS={toggleSMS}
                          toggleViber={toggleViber}
                          toggleErrors={toggleErrors}
                          isSuperAdmin={isSuperAdmin}
                          items={items}
                          selected={selected}
                        />
                      ) : (
                        <TouristsList
                          isSuperAdmin={isSuperAdmin}
                          items={items}
                          selected={selected}
                        />
                      )}

                      <Paginator
                        total={count}
                        onPageChange={this.handleOnPageChange}
                        forcePage={0}
                        initialPage={pageNumber - 1}
                      />
                    </div>
                  )}
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
