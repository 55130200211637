import React, { Component } from 'react';
import { IssuesAPI } from '../../api/Client';
import IssueAddForm from './components/IssueAddForm';
import IssueFilter from './components/IssueFilter';
import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';
import Paginator from '../../components/Paginator';
import { Table, Row, Card, CardBody, CardHeader, Col } from 'reactstrap';
import {
  ISSUE_STATUSES,
  ISSUE_STATUSES_LANG,
  ISSUE_SOURCE_MAP,
} from './components/IssueItem';
import ProgressBar from '../../components/ProgressBar';
import replaceLinks from '../../Utils/ReplaceLinks';
import { connect } from 'react-redux';
import { switchCurrentIssuesPage } from '../../actions/actions';

export const ISSUE_STATUS_ICONS = {
  [ISSUE_STATUSES.new]: 'fa-arrow-up',
  [ISSUE_STATUSES.active]: 'fa-hourglass-half',
  [ISSUE_STATUSES.tours]: 'fa-tasks',
  [ISSUE_STATUSES.call]: 'fa-phone',
  [ISSUE_STATUSES.meet]: 'fa-calendar',
  [ISSUE_STATUSES.deffered]: 'fa-frown-o',
  [ISSUE_STATUSES.cancel]: 'fa-history',
  [ISSUE_STATUSES.order]: 'fa-user',
  [ISSUE_STATUSES.we_cancel]: 'fa-user',
  [ISSUE_STATUSES.client_cancel]: 'fa-user',
  transer: 'fa-user',
};

export const ISSUE_STATUS_COLOR = {
  [ISSUE_STATUSES.new]: 'badge-danger',
  [ISSUE_STATUSES.active]: 'badge-warning',
  [ISSUE_STATUSES.tours]: 'badge-green',
  [ISSUE_STATUSES.call]: 'badge-green',
  [ISSUE_STATUSES.meet]: 'badge-green',
  [ISSUE_STATUSES.deffered]: 'badge-default',
  [ISSUE_STATUSES.cancel]: 'badge-gray',
  [ISSUE_STATUSES.order]: 'badge-order',
  [ISSUE_STATUSES.we_cancel]: 'badge-brown',
  [ISSUE_STATUSES.client_cancel]: 'badge-brown',
  transer: 'badge-default',
};

class Issues extends Component {
  constructor(props) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
    this.onInitialFilter = this.onInitialFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.toggle = this.toggle.bind(this);
    this.onChangeFilter = this.onChangeFilter.bind(this);
    this.state = {
      items: [],
      count: 0,
      pageNumber: 1,
      filters: {
        status: ['new', 'active', 'meet', 'deffered', 'tours', 'call'],
      },
      isLoading: false,
      isFormVisible: false,
    };
  }
  onCreate() {
    this.props.onCreate();
  }
  onInitialFilter(filters) {
    if (filters) {
      this.setState({ filters }, () => this.fetchItems());
    }
  }
  onChangeFilter(filters) {
    this.setState({ filters: filters }, () => this.fetchItems());
  }

  fetchItems(page) {
    const self = this;
    const { filters } = this.state;
    let pageToLoad = page ? page : this.props.pageNumber;

    self.setState(
      {
        ...self.state,
        isLoading: true,
      },
      () => {
        IssuesAPI.getList(pageToLoad, filters).then((res) => {
          self.setState({
            ...self.state,
            items: res.results || [],
            hasNext: res.next != null,
            hasPrev: res.previous != null,
            count: res.count,
            isLoading: false,
          });
        });
      }
    );
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.props.switchCurrentIssuesPage(page);
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  toggle() {
    this.setState({
      ...this.state,
      isFormVisible: !this.state.isFormVisible,
    });
  }

  render() {
    const { isFormVisible, count, items, isLoading } = this.state;
    return (
      <div className="issues1">
        <div className="filter filter--green">
          <div className="card">
            <div className="card-header">
              <strong>
                Работа с запросами из разных источников: звонок, соцсети, сайт,
                посетитель и т.д
              </strong>
            </div>
            <IssueFilter
              onInitialFilter={this.onInitialFilter}
              onChangeFilter={this.onChangeFilter}
              isFormVisible={isFormVisible}
              handlePageChange={this.handlePageChange}
            >
              <IssueAddForm toggleForm={this.toggle} person={true} />
            </IssueFilter>
          </div>
        </div>

        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify hidden-mobile">{''}</i>{' '}
                Всего&nbsp;
                <span className="badge badge-info">{count}</span>
              </CardHeader>
              <CardBody className="card-body_responsive">
                {isLoading ? (
                  <ProgressBar />
                ) : !items.length ? (
                  <div className="row animated fadeIn">
                    <div className="col-md-12 col-md-offset-6 text-center">
                      <div className="col-md-12 col-md-offset-6 text-center">
                        <p>Запросов не найдено</p>
                      </div>
                    </div>
                  </div>
                ) : (
                  <Table
                    striped
                    bordered
                    size="sm"
                    className="font-xs table--green table-sm"
                  >
                    <thead>
                      <tr>
                        <th className="text-center tableItem hidden-mobile">
                          номер запроса
                        </th>
                        <th className="text-center tableItem">Cоздан</th>
                        <th className="text-center tableItem hidden-mobile">
                          Изменён
                        </th>
                        <th className="text-center tableItem">Статус</th>
                        <th className="text-center tableItem hidden-mobile">
                          Источник
                        </th>
                        <th className="text-center tableItem">Город вылета</th>
                        <th className="text-center tableItem">Страна</th>
                        <th className="text-center tableItem">Текст запроса</th>
                        <th className="text-center tableItem hidden-mobile">
                          Номер заявки в СБ
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Cайт
                        </th>
                        <th className="text-center tableItem">Клиент</th>
                        <th className="text-center tableItem hidden-mobile">
                          Менеджер
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Офис
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {items.map((item) => {
                        const {
                          id,
                          type,
                          person,
                          tourist,
                          created,
                          updated,
                          status,
                          source,
                          city,
                          destination_country,
                          title,
                          order,
                          fp_number,
                          lead,
                          manager,
                          office,
                        } = item;
                        return (
                          <tr key={id}>
                            <td className="text-center hidden-mobile tableItem">
                              <Link
                                to={
                                  type === 'person'
                                    ? `/persons/${person.id}`
                                    : `/tourists/${tourist.id}`
                                }
                              >
                                <span>{id}</span>
                              </Link>
                            </td>
                            <td className="text-center tableItem">
                              {type === 'person' ? (
                                <Link to={`/persons/${person.id}`}>
                                  {moment(created).format('DD.MM.YY HH:mm')}
                                </Link>
                              ) : (
                                <Link to={`/tourists/${tourist.id}`}>
                                  {moment(created).format('DD.MM.YY HH:mm')}
                                </Link>
                              )}
                            </td>
                            <td className="text-center hidden-mobile tableItem">
                              {moment(updated).format('DD.MM.YY HH:mm') !==
                              moment(created).format('DD.MM.YY HH:mm') ? (
                                <span>
                                  {moment(updated).format('DD.MM.YY HH:mm')}
                                </span>
                              ) : (
                                <span>-</span>
                              )}
                            </td>
                            <td className="text-center tableItem">
                              <span
                                className={`badge badge-pill ${ISSUE_STATUS_COLOR[status]}`}
                              >
                                <i
                                  className={`fa ${ISSUE_STATUS_ICONS[status]}`}
                                >
                                  {''}
                                </i>{' '}
                                <span className="text-small-mobile">
                                  {ISSUE_STATUSES_LANG[status]}
                                </span>
                              </span>
                            </td>
                            {item.source && ISSUE_SOURCE_MAP[source] ? (
                              <td className="text-left hidden-mobile tableItem">
                                <i
                                  className={`fa ${
                                    ISSUE_SOURCE_MAP[source].icon || 'fa-star'
                                  }`}
                                  style={{
                                    fontSize: '14px',
                                    backgroundColor: '#E4087E',
                                    textAlign: 'center',
                                    color: '#fff',
                                    padding: '5px',
                                    borderRadius: '30px',
                                    width: '24px',
                                    height: '24px',
                                    marginRight: '10px',
                                  }}
                                >
                                  {''}
                                </i>
                                {ISSUE_SOURCE_MAP[source].name}
                              </td>
                            ) : (
                              <td className="text-left tableItem">{''}</td>
                            )}
                            <td className="text-center tableItem">{city}</td>
                            <td className="text-center tableItem">
                              {destination_country}
                            </td>
                            <td className="text-center tableItem">
                              <div
                                dangerouslySetInnerHTML={{
                                  __html: replaceLinks(title),
                                }}
                              ></div>
                            </td>
                            <td className="text-center hidden-mobile tableItem">
                              {!!item.order ? (
                                <Link to={`/ordersfp/${order.id}`}>
                                  {order.fp_pk}
                                </Link>
                              ) : (
                                fp_number
                              )}
                            </td>
                            <td className="text-center hidden-mobile tableItem">
                              {!!lead ? (
                                <Link to={`/leads/${lead}`}>{lead}</Link>
                              ) : (
                                ``
                              )}
                            </td>
                            <td className="text-center tableItem">
                              {type === 'person' ? (
                                <Link to={`/persons/${person.id}`}>
                                  {person.full_name || `ID:<${person.id}>`}
                                </Link>
                              ) : (
                                <Link to={`/tourists/${tourist.id}`}>
                                  {tourist.full_name || `ID:<${tourist.id}>`}
                                </Link>
                              )}
                            </td>
                            <td className="text-center hidden-mobile tableItem">
                              {!!manager
                                ? [manager.last_name, manager.first_name].join(
                                    ' '
                                  ) || `${manager.username}`
                                : ``}
                            </td>
                            <td className="hidden-mobile">
                              {!!office ? (
                                <Link to={`/agencies/${office.agency_id}`}>
                                  {office.address}
                                </Link>
                              ) : (
                                ``
                              )}
                            </td>
                          </tr>
                        );
                      })}
                    </tbody>
                  </Table>
                )}
                <Paginator
                  total={count}
                  onPageChange={this.handlePageChange}
                  initialPage={this.props.pageNumber - 1}
                  forcePage={this.props.pageNumber - 1}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

Issues.displayName = 'Issues';

const mapStateToProps = function (store) {
  return {
    pageNumber: store.issuesReducer.pageNumber,
  };
};

const mapDispatchToProps = {
  switchCurrentIssuesPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Issues);
