import React, { Component } from 'react';
import { CardHeader, CardFooter, CardBody, Row, Col } from 'reactstrap';
import { OrderFpAPI } from '../../../api/Client';
import BackButton from '../../../components/BackButton';
import moment from 'moment';
import 'moment/locale/ru';

import 'react-datepicker/dist/react-datepicker.css';

export default class OrderFpEdit extends Component {
    constructor(props) {
        super(props);

        this.getInfo = this.getInfo.bind(this);

        this.state = {
            info: {},
            formChanged: false,
            itemId: this.props.match.params.number,
            officeTitle: '',
            touristsFp: [],
        };
    }

    getChangedValues() {
        let result = {};
        return result;
    }

    getInfo() {
        OrderFpAPI.getInfo(this.state.itemId)
            .then((result) => this.setState({
                info: result,
                officeTitle: result.office && result.office.title,
                touristsFp: result.tourists_fp,
            }));
    }

    componentDidMount() {
        this.getInfo();
    }

    render() {
        if (!this.state.info) {
            return null;
        }
        const item = this.state.info;
        const jsData = item.extra_info || {};
        const subManager = jsData.subagent_manager || {};
        return (
            <div className="animated fadeIn">
                <Row>
                    <Col md="12">
                        <div className="card card-accent-primary">
                            <CardHeader>
                                <i className="icon-paper-plane"></i>
                                Заявка &#x02116; {item.fp_pk} создана {subManager.name} {subManager.email}
                                <span className="badge badge-info float-right">{moment(item.create_date).format('DD.MM.YYYY, HH:mm')}</span>
                            </CardHeader>
                            <CardBody>
                                <Row>
                                    <label className="text-right col-md-2">Офис:</label>
                                    <p className="col-md-8">{this.state.officeTitle}</p>
                                </Row>
                                <Row>
                                    <label className="text-right col-md-2">Инфо:</label>
                                    <p className="col-md-8">
                                        {item.depart_city} - {item.destination_country} с {moment(item.begin_date).format('DD.MM.YY')} по {moment(item.end_date).format('DD.MM.YY')} на {moment.duration(moment(item.end_date).diff(moment(item.begin_date))).asDays()} ночей за {jsData.summ} {jsData.currency}
                                    </p>
                                </Row>
                                <h5>Список туристов:</h5>
                                <ul>
                                    {
                                        this.state.touristsFp.map((t, q) =>
                                            <li key={q}>
                                                {t.full_name}
                                            </li>
                                        )
                                    }
                                </ul>

                                <BackButton />
                            </CardBody>
                            <CardFooter>
                            </CardFooter>
                        </div>
                    </Col>
                </Row>
            </div>
        )
    }
}
