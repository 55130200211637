import React, {Component} from 'react';
import {PassportAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import Passport from './components/Passport';
import {CardBody, Col} from 'reactstrap';

export const PASSPORT_FOREIGN = 'foreign';
export const PASSPORT_CIVIL = 'civil';

export const PASSPORT_TYPES = [
  {label: 'Заграничный', value: PASSPORT_FOREIGN},
  {label: 'Гражданский', value: PASSPORT_CIVIL},
];

export default class Passports extends Component {
  constructor(props) {
    super(props);
    this.fetchItems = this.fetchItems.bind(this);
    this.toggleViewAddForm = this.toggleViewAddForm.bind(this);
    this.onSuccessResult = this.onSuccessResult.bind(this);

    this.state = {
      items: [],
      count: 0,
      isShowAddForm: false,
    };
  }

  fetchItems() {
    const {checkPassport, tourist} = this.props;
    let status = 200;

    PassportAPI.
        fetchList(1, {tourist: tourist}).
        then(result => {
          status = result.status;
          return result.json();
        }).
        then(result => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              items: result.results || [],
              count: result.count,
              isShowAddForm: false,
            }, () => checkPassport(result.results));
          } else {
            this.setState({
              items: [],
              count: 0,
              isShowAddForm: false,
            });
          }
        });
  }

  toggleViewAddForm() {
    const {isShowAddForm} = this.state;
    this.setState({
      isShowAddForm: !isShowAddForm,
    });
  }

  onSuccessResult(result, action) {
    const {checkPassport} = this.props;
    if (action === 'destroy') {
      this.setState((prevState) => {
        const newItems = prevState.items.filter(item => item.id !== result.id);
        return {
          items: newItems,
          count: newItems.length,
          isShowAddForm: false,
        };
      }, () => () => checkPassport(this.state.items));
    } else if (action === 'create') {
      this.setState((prevState) => {
        const newItems = [...prevState.items];
        newItems.unshift(result);
        return {
          items: newItems,
          count: newItems.length,
          isShowAddForm: false,
        };
      }, () => checkPassport(this.state.items));
    } else if (action === 'partial_update') {
      this.setState((prevState) => {
        const newItems = [...prevState.items];
        const index = newItems.findIndex(item => item.id === result.id);
        newItems[index] = result;
        return {
          items: newItems,
          isShowAddForm: false,
        };
      }, () => checkPassport(this.state.items));
    }
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {
    const emptyObj = {};
    const {items, count, isShowAddForm} = this.state;
    const {tourist} = this.props;
    return (
        <div>
          {count < PASSPORT_TYPES.length && (
              <div>
                {!isShowAddForm
                    ?
                    <Col xs="12" className="animated fadeIn">
                      <div className="card card-accent-primary">
                        <CardBody>
                          <button className="btn btn-info mt-3" onClick={this.toggleViewAddForm}>
                            <i className="fa fa-plus mr-3">{''}</i>
                            Добавить паспорт
                          </button>
                        </CardBody>
                      </div>
                    </Col>
                    : <Passport
                        passport={emptyObj}
                        onSuccess={this.onSuccessResult}
                        tourist={tourist}
                        onCancel={this.toggleViewAddForm}
                    />
                }
              </div>
          )}
          {items.length ? items.map(item => (
              <div key={`passport-${item.id}`}>
                {<Passport
                    passport={item}
                    onSuccess={this.onSuccessResult}
                    tourist={tourist}
                    onCancel={this.toggleViewAddForm}
                />}
              </div>
          )) : null}
        </div>
    );
  }
}
