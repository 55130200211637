import React, {Component} from 'react';
import {IssuesAPI, MergersAPI} from '../../../api/Client';
import {Alert} from 'reactstrap';
import Select from 'react-select'

class IssueMergerForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);
    this.handleTouristsOnChange = this.handleTouristsOnChange.bind(this);
    this.getOrderTourists = this.getOrderTourists.bind(this);

    this.state = {
      showErrorMessage: false,
      showSuccessMessage: false,
      // issue: this.props.issue,
      orderTourists: [],
      touristId: 0,
      touristName: '',
      merger: null,
      formChanged: false,
      form_changed: false,
    };
  }

  submit(e) {
    e.preventDefault();
    const data = {
      tourist: this.state.touristId,
      person: this.props.issue.person.id,
    };

    // if (this.state.merger && !this.state.touristId) {
    //   MergersAPI.delete(this.state.merger.id).then((result) => {
    //     result.status === 204 ? this.successSubmit() : this.errorMessage();
    //   });
    // } else if (this.state.merger && this.state.touristId !== this.state.merger.tourist) {
    //   MergersAPI.update(`/mergers/${this.state.merger.id}`, data).then((response) => {
    //     response.status === 200 ? this.successSubmit() : this.errorMessage();
    //   });
    // } else if (!this.state.merger && this.state.touristId) {
    //   MergersAPI.create(data).then((response) => {
    //     response.status === 201 ? this.successSubmit() : this.errorMessage();
    //   });
    // }
    if (this.state.touristId) {
      MergersAPI.create(data).then((response) => {
        response.status === 201 ? this.successSubmit() : this.errorMessage();
      });
    }
  }

  doWithTimeout(timeout = 2000) {
    setTimeout(() => {
      this.setState({
        ...this.state,
        showErrorMessage: false,
        showSuccessMessage: false,
      });
      this.props.onCreate();
    }, timeout);
    setTimeout(this.getOrderTourists, timeout);
  }

  successSubmit() {
    this.setState({
      ...this.state,
      showSuccessMessage: true,
      showErrorMessage: false,
    });

    this.doWithTimeout();
  }

  errorMessage() {
    this.setState({
      ...this.state,
      showSuccessMessage: false,
      showErrorMessage: true,
    });

    this.doWithTimeout();
  }

  getOrderTourists() {
    IssuesAPI.getList(1, {}, `${this.props.issue.id}/order_tourists/`).then((res) => {
      let merger = null;
      let findMerger = null;
      let tourist = null;

      for (let i = 0; i < res.length; i++) {
        for (let j = 0; j < res[i]['tourist_person_mergers'].length; j++) {
          findMerger = res[i]['tourist_person_mergers'][j];
          if (this.props.issue.person.id === findMerger.person) {
            tourist = res[i];
            merger = findMerger;
            break;
          }
        }
      }

      this.setState({
        ...this.state,
        orderTourists: res,
        touristId: tourist ? tourist.id : 0,
        touristName: tourist ? tourist.full_name : '',
        merger: merger,
      });
    });
  }

  componentDidMount() {
    this.getOrderTourists();
  }

  handleTouristsOnChange = (selected) => {
    this.setState({
      ...this.state,
      touristId: selected ? selected.value : 0,
      touristName: selected ? selected.label : '',
      formChanged: true,
      form_changed: true,
    });
  };

  render() {
    const TOURIST_OPTIONS = this.state.orderTourists.map((tourist) => {
      return {
        value: tourist.id,
        label: `${tourist.full_name}${!!tourist.is_customer ? ` (заказчик)` : ``}`
      };
    });
    const selected = {label: this.state.touristName, value: this.state.touristId};
    const isDisabled = !!this.state.merger && (this.state.merger.is_done && !this.state.merger.is_cancelled ||
        !this.state.merger.is_done && this.state.merger.is_cancelled ||
        !this.state.merger.is_done && !this.state.merger.is_cancelled);
    return (
        <form>
          <div className="form-group row">
            <label className="text-right col-md-2 col-form-label">Выбрать постоянного туриста</label>
            <div className="col-md-8 ">
              {(this.props.type == 8) ? (
                  <Select
                      disabled={isDisabled}
                      id="touristId"
                      className="mb-3"
                      name="tourists"
                      placeholder="Выберите туриста из спиcка..."
                      closeOnSelect={true}
                      removeSelected={false}
                      value={selected}
                      onChange={this.handleTouristsOnChange}
                      options={TOURIST_OPTIONS}
                      required={true}
                  />
              ) : ''}
              <p className="bg-danger">{/* {error.manager_manager_fp} */}</p>
            </div>
          </div>
          {!isDisabled ?
              <button className="issue_btn btn btn-success" onClick={this.submit}>Сохранить</button>
              : ''}
          {this.state.showErrorMessage
              ? (<Alert color="warning"> <strong>Ошибка!</strong> Не удалось создать запрос на слияние</Alert>)
              : ''
          }
          {this.state.showSuccessMessage ?
              (<Alert color="success"><strong>Запрос на слияние</strong> будет обработан в течение 5 минут.</Alert>)
              : ''
          }
        </form>
    );
  }
}

export default IssueMergerForm;
