import React, { Component, useEffect, useState } from 'react';
import 'moment/locale/ru';
import Select from 'react-select';
import {
  POSITIVE_ACTION_STATUSES,
  CabinetAPI,
  TouristFpAPI,
  OrderFpAPI,
  PersonAPI,
  IssuesAPI,
} from '../../api/Client';
import { Alert, TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import { Card, CardBody, CardHeader } from 'reactstrap';
import OrdersFpTable from '../../views/OrdersFpTable';
import Issues from '../Issues/Issues';
import classnames from 'classnames';
import moment from 'moment';
import {
  AiFillCreditCard,
  AiFillEye,
  AiFillHeart,
  AiFillProfile,
  AiFillShopping,
} from 'react-icons/ai';
import { Link, useParams } from 'react-router-dom';
import { getUserRole } from '../../connect/auth';
const CLIENTS_TYPES = [
  { label: 'Постоянный клиент', value: 'tourist' },
  { label: 'Потенциальный клиент', value: 'person' },
];

function checkServerResponse(r) {
  if (r.ok) {
    return r.json();
  } else return Promise.reject(r);
}

function TouristCabinet() {
  const { cabinetId } = useParams();

  const [cabinetInfo, setCabinetInfo] = useState({});
  const [ordersFp, setOrdersFp] = useState([]);
  const [touristsFp, setTouristsFp] = useState([]);
  const [touristSearchResult, setTouristSearchResult] = useState('');
  const [touristOrders, setTouristOrders] = useState('');
  const [touristInfo, setTouristInfo] = useState('');
  const [touristIssues, setTouristIssues] = useState('');
  const [errorMessage, setErrorMessage] = useState('');
  const [personData, setPersonData] = useState([]);
  const [personInfo, setPersonInfo] = useState({});
  const [personIssues, setPersonIssues] = useState([]);
  const [comment, setComment] = useState('');
  const [redirectInfo, setRedirectInfo] = useState('');
  const [isCommentSuccess, setCommentSuccess] = useState(false);
  const [activeTab, setActiveTab] = useState('1');
  const [searchValue, setSearchValue] = useState('');
  const [searchError, setSearchError] = useState('');
  const [clientType, setClientType] = useState({
    value: 'none',
    label: 'Выберите тип туриста',
  });

  useEffect(() => {
    getInfo();
    getRedirectLink();
  }, []);

  function getInfo() {
    CabinetAPI.getInfo(`${cabinetId}`).then((r) => {
      setCabinetInfo(r);
      if (r.orders_ids.length > 0) {
        findTouristId(r.orders_ids);
      }
      if (r.touristfp || r.person) {
        getOrders();
        getIssues();
      }
      findCabinetTouristInTouristFP(r.email_registered);
    });
  }

  const findTouristId = async (orders) => {
    const filter = {
      fp_pk: orders[0],
    };
    OrderFpAPI.fetchList(1, filter)
      .then(checkServerResponse)
      .then((r) => {
        const { results, count } = r;
        setOrdersFp(results);
        if (count >= 1) {
          setTouristsFp(results[0].tourists_fp);
        }
      })
      .catch((err) => console.log(err));
  };

  function getOrders() {
    const { touristfp } = cabinetInfo;
    if (touristfp) {
      TouristFpAPI.fetchList(1, {}, `${touristfp.id}/orders/`)
        .then(checkServerResponse)
        .then((result) => {
          setTouristOrders(result.results);
        })
        .catch((err) => console.log(err));
    }
  }

  function getIssues() {
    const { touristfp, person } = cabinetInfo;
    if (touristfp) {
      TouristFpAPI.fetchJSON(`${touristfp.id}/`)
        .then(checkServerResponse)
        .then((r) => {
          setTouristInfo(r);
        });

      TouristFpAPI.fetchList(1, {}, `${touristfp.id}/issues/`)
        .then(checkServerResponse)
        .then((r) => {
          setTouristIssues(r.results);
        });
    }
    if (person) {
      PersonAPI.getInfo(person.id).then((r) => {
        setPersonInfo(r);
      });
      IssuesAPI.getList(1, { person: person.id }).then((r) => {
        setPersonIssues(r.results);
      });
    }
  }

  function updateIssues() {
    getIssues();
  }

  function findCabinetTouristInTouristFP(email) {
    const filter = { q: email };

    TouristFpAPI.fetchList(1, filter)
      .then(checkServerResponse)
      .then((result) => {
        if (0 < result.count > 2) {
          setTouristSearchResult(result.results);
        }
      })
      .catch((err) => console.log(err));
  }

  function connectTouristToCabinet(id) {
    const data = { touristfp: id };
    CabinetAPI.modify(cabinetId, data).then((r) => {
      setCabinetInfo(r);
      getInfo();
    });
  }

  function connectPersonToCabinet(id) {
    const data = { person: id };
    CabinetAPI.modify(cabinetId, data).then((r) => {
      setCabinetInfo(r);
      getInfo();
    });
  }

  function handlePersonSearch(value) {
    setErrorMessage('');
    PersonAPI.fetchList(1, { q: value })
      .then(checkServerResponse)
      .then((res) => {
        if (res.count === 0) {
          setErrorMessage(`По запросу "${value}", ничего не найдено`);
        }
        setPersonData(res.results);
      })
      .catch((err) => {
        console.log(err);
      });
  }

  function handleComment(e) {
    const { value } = e.target;
    setComment(value);
  }

  function handleCommentSave() {
    const data = {
      text: comment,
      type: 'comment',
    };
    CabinetAPI.comment(cabinetId, data)
      .then(checkServerResponse)
      .then((res) => {
        setCabinetInfo((prevState) => ({
          ...prevState,
          comments: [res, ...prevState.comments],
        }));
        console.log(cabinetInfo);
        if (res.text) {
          setCommentSuccess(true);
          setTimeout(handleCommentReset, 3000);
        }
      });
  }

  function handleCommentReset() {
    setComment('');
    setCommentSuccess(false);
  }

  function getRedirectLink() {
    CabinetAPI.getInfo(`${cabinetId}/login_to_user_cabinet`).then((r) => {
      setRedirectInfo(r.redirect);
    });
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }
  return (
    <div className="animated fadeIn filter filter--green">
      <Card>
        <CardHeader>
          <h4>
            {cabinetInfo.last_name} {cabinetInfo.name}
          </h4>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <h5>Инфо о туристе</h5>
              <p>
                ID в кабинете: <b>{cabinetInfo.id}</b>
              </p>
              <p>
                Фамилия: <b>{cabinetInfo.last_name}</b>
              </p>
              <p>
                Имя: <b>{cabinetInfo.name}</b>
              </p>
              <p>
                Последняя активность:{' '}
                <b> {moment(cabinetInfo.dt_sync).format('DD.MM.YY HH:mm')} </b>
              </p>
              <p>
                Зарегистрировался:{' '}
                <b>
                  {' '}
                  {moment(cabinetInfo.dt_registered).format('DD.MM.YY HH:mm')}
                </b>
              </p>
              <p>
                Электронная почта при регистрации:{' '}
                <b>{cabinetInfo.email_registered}</b>
              </p>
              <p>
                Электронная почта из настроек ЛК:{' '}
                <b>{cabinetInfo.email_settings}</b>
              </p>
              <p>
                Логин: <b>{cabinetInfo.username}</b>
              </p>
              <p>
                Телефон: <b>{cabinetInfo.phone_settings}</b>
              </p>
            </div>
            <div className="col-md-3 col-lg-3 mb-4">
              <h5>Инфо из ЛК</h5>
              <div>
                <p>
                  <AiFillEye />
                  &nbsp; Просмотрено: <b>&nbsp; {cabinetInfo.count_seen}</b>
                </p>
                <p>
                  <AiFillHeart />
                  &nbsp; Понравилось: <b>&nbsp; {cabinetInfo.count_likes}</b>
                </p>
                <p>
                  <AiFillShopping />
                  &nbsp; В корзине: <b>&nbsp; {cabinetInfo.count_cart}</b>
                </p>
                <p>
                  <AiFillCreditCard />
                  &nbsp; Заказов: <b>&nbsp; {ordersFp.length}</b>
                </p>
                <p>
                  <AiFillProfile />
                  &nbsp; Постоянный клиент:{' '}
                  <b>
                    &nbsp;{' '}
                    {cabinetInfo.touristfp ? (
                      <Link
                        target="_blank"
                        to={`../../tourists/${cabinetInfo.touristfp.id}`}
                      >
                        {cabinetInfo.touristfp.full_name}
                      </Link>
                    ) : (
                      'Не привязан'
                    )}
                  </b>
                </p>
                <p>
                  <AiFillProfile />
                  &nbsp; Потенциальный клиент:{' '}
                  <b>
                    &nbsp;{' '}
                    {cabinetInfo.person ? (
                      <Link
                        target="_blank"
                        to={`../../persons/${cabinetInfo.person.id}`}
                      >
                        {cabinetInfo.person.fio}
                      </Link>
                    ) : (
                      'Не привязан'
                    )}
                  </b>
                </p>
              </div>
            </div>
            <div className="col-md-1 col-lg-1 mb-1">
              <a
                href={redirectInfo}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-sm btn-primary"
              >
                Перейти в ЛК на сайте
              </a>
            </div>
          </div>
          <div className="mb-3">
            <h5>Комментарий:</h5>

            <div className="form-group row">
              <div className="col-md-6">
                <textarea
                  autoFocus
                  name="textarea-input"
                  rows="7"
                  className="form-control mb-2"
                  placeholder="Введите сообщение"
                  value={comment}
                  onChange={handleComment}
                ></textarea>
                <button
                  className="btn btn-success mb-2"
                  onClick={handleCommentSave}
                >
                  Сохранить
                </button>
                {isCommentSuccess ? (
                  <Alert color="success">Комментарий успешно добавлен</Alert>
                ) : null}
              </div>
            </div>

            {cabinetInfo.comments && cabinetInfo.comments.length ? (
              <div>
                {cabinetInfo.comments.map((comment) => (
                  <div
                    key={comment.created}
                    className="p-2 mb-1"
                    style={{
                      backgroundColor: '#d9eee0',
                      display: 'flex',
                      borderRadius: '5px',
                      justifyContent: 'space-between',
                    }}
                  >
                    <div>{comment.text}</div>
                    <div>{moment(comment.created).format('DD.MM.YYYY')}</div>
                  </div>
                ))}
              </div>
            ) : null}
          </div>
          <Nav tabs>
            {cabinetInfo.touristfp ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => toggleTab('1')}
                >
                  <i className="fa fa-plane">{''}</i> Куда летал
                </NavLink>
              </NavItem>
            ) : null}
            {cabinetInfo.person ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => toggleTab('2')}
                >
                  <i className="fa fa-list">{''}</i> Запросы потенциального
                  клиента
                </NavLink>
              </NavItem>
            ) : null}
            {cabinetInfo.touristfp ? (
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => toggleTab('3')}
                >
                  <i className="fa fa-list">{''}</i> Запросы постоянного клиента
                </NavLink>
              </NavItem>
            ) : null}
            <NavItem>
              <NavLink
                className={classnames({ active: activeTab === '7' })}
                onClick={() => toggleTab('7')}
              >
                <i className="fa fa-tasks">{''}</i> Связать кабинет с клиентом
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="mb-10" activeTab={activeTab}>
            {cabinetInfo.touristfp ? (
              <TabPane tabId="1">
                <OrdersFpTable
                  count={ordersFp ? ordersFp.length : 0}
                  items={ordersFp || []}
                />
              </TabPane>
            ) : null}
            {cabinetInfo.person ? (
              <TabPane tabId="2">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Issues
                    person={cabinetInfo.person.id}
                    type="person"
                    issues={personIssues || []}
                    info={personInfo}
                    onCreate={getIssues}
                    onChangeActivity={getIssues}
                  />
                </div>
              </TabPane>
            ) : null}
            {cabinetInfo.touristfp ? (
              <TabPane tabId="3">
                <div className="col-sm-12 col-md-12 col-lg-12">
                  <Issues
                    tourist={cabinetInfo.touristfp.id}
                    type="tourist"
                    issues={touristIssues || []}
                    info={touristInfo}
                    onCreate={getIssues}
                    onChangeActivity={getIssues}
                  />
                </div>
              </TabPane>
            ) : null}
            <TabPane tabId="7">
              <div className="tourists col-md-6 mb-xl-5">
                <Select
                  placeholder="Прикрепить потенциального или постоянного клиента"
                  name="status"
                  isMulti={false}
                  joinValues={true}
                  simpleValue={true}
                  onChange={(type) => setClientType(type)}
                  options={CLIENTS_TYPES}
                  value={clientType}
                  autoFocus
                />
                {clientType.value === 'tourist' ? (
                  <div className="tourists">
                    <div className="tourists__alert">
                      К кабинету туриста может быть привязан только один
                      постоянный клиент
                    </div>
                    {touristsFp.length > 0 ? (
                      touristsFp.map((tourist) => {
                        let isConnected = false;
                        if (cabinetInfo.touristfp) {
                          isConnected = tourist.id === cabinetInfo.touristfp.id;
                        }
                        return (
                          <div
                            className={`tourist ${
                              !!isConnected ? 'tourist_connected' : ''
                            }`}
                            key={tourist.id}
                          >
                            <div className="tourist__id">{tourist.id}</div>
                            <div className="tourist__name">
                              {tourist.full_name}
                            </div>
                            <div className="tourist__actions">
                              <Link
                                className="tourist__button"
                                target="_blank"
                                to={`../../tourists/${tourist.id}`}
                              >
                                Посмотреть подробные данные
                              </Link>
                              {isConnected ? (
                                <div className="tourist__connected-msg">
                                  Этот турист привязан к кабинету
                                </div>
                              ) : (
                                <button
                                  className="tourist__button"
                                  onClick={() =>
                                    connectTouristToCabinet(tourist.id)
                                  }
                                >
                                  Привязать туриста к этому кабинету
                                </button>
                              )}
                            </div>
                          </div>
                        );
                      })
                    ) : (
                      <div className="tourists__alert">Заказы не найдены</div>
                    )}
                  </div>
                ) : null}
                {clientType.value === 'person' ? (
                  <div className="tourists">
                    <div className="tourists__alert">
                      К кабинету туриста может быть привязан только один
                      потенциальный клиент
                    </div>
                    <div className="search filter-row">
                      <input
                        onChange={(e) => setSearchValue(e.target.value)}
                        placeholder="ФИО, email, телефон"
                        type="text"
                        className="form-control"
                      ></input>
                      <button
                        className="btn btn-success"
                        type="button"
                        onClick={(e) => handlePersonSearch(searchValue)}
                      >
                        Искать
                      </button>
                    </div>
                    {personData.length > 0
                      ? personData.map((person) => {
                          let isConnected = false;
                          if (cabinetInfo.person) {
                            isConnected = person.id === cabinetInfo.person.id;
                          }
                          return (
                            <div
                              className={`tourist ${
                                !!isConnected ? 'tourist_connected' : ''
                              }`}
                              key={person.id}
                            >
                              <div className="tourist__id">{person.id}</div>
                              <div className="tourist__name">{person.fio}</div>
                              <div className="tourist__actions">
                                <Link
                                  className="tourist__button"
                                  target="_blank"
                                  to={`/persons/${person.id}`}
                                >
                                  Посмотреть подробные данные
                                </Link>
                                {isConnected ? (
                                  <div className="tourist__connected-msg">
                                    Этот клиент привязан к кабинету
                                  </div>
                                ) : (
                                  <button
                                    className="tourist__button"
                                    onClick={() =>
                                      connectPersonToCabinet(person.id)
                                    }
                                  >
                                    Привязать клиента к этому кабинету
                                  </button>
                                )}
                              </div>
                            </div>
                          );
                        })
                      : null}
                    {errorMessage ? (
                      <div
                        className="tourists__alert"
                        style={{ borderColor: 'red' }}
                      >
                        {errorMessage}
                      </div>
                    ) : null}
                  </div>
                ) : null}
              </div>
            </TabPane>
          </TabContent>
        </CardBody>
      </Card>
    </div>
  );
}

export default TouristCabinet;
