import React from 'react';
export const ISSUE_STATUS_COLOR = {
  new: '#f35050',
  active: '#f0b505',
  tours: '#f0b505',
  call: '#f0b505',
  meet: '#f0b505',
  deffered: '#9c9c9c',
  cancel: '#9c9c9c',
  order: '#66bb6a',
  transer: '#f0b505',
};

function IssuesStatusPoint({ color, text }) {
  return (
    <p className="mr-3" style={{display: 'flex', alignItems: 'center'}}>
      <i
        className={`fa fa-circle fa-2`}
        style={{ color: ISSUE_STATUS_COLOR[color] }}
        aria-hidden="true"
      ></i>
      <span style={{ fontSize: '12px' }}>{text}</span>
    </p>
  );
}

export default IssuesStatusPoint;
