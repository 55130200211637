import React, { Component } from 'react';
import Select from 'react-select';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import 'moment/locale/ru';

import {
  FranchiseApplicationAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';

import 'react-datepicker/dist/react-datepicker.css';
import {
  FRANCHISE_COUNTRY_OPTIONS,
  FRANCHISE_EXPERIENCE_OPTIONS,
  FRANCHISE_MANAGER_OPTIONS,
  FRANCHISE_SOURCE_OPTIONS,
  FRANCHISE_STATUS_OPTIONS,
} from './constants';

export class FranchiseApplicationFilter extends Component {
  constructor(props) {
    super(props);
    this.onFilter = this.onFilter.bind(this);
    this.onReset = this.onReset.bind(this);
    this.handleExperience = this.handleExperience.bind(this);
    this.handleStatus = this.handleStatus.bind(this);
    this.handleSource = this.handleSource.bind(this);
    this.handleCountry = this.handleCountry.bind(this);
    this.handleCity = this.handleCity.bind(this);
    this.handleManager = this.handleManager.bind(this);
    this.handleSearch = this.handleSearch.bind(this);
    this.fetchCities = this.fetchCities.bind(this);
    this.startDateChange = this.startDateChange.bind(this);
    this.endDateChange = this.endDateChange.bind(this);
    this.startContractDateChange = this.startContractDateChange.bind(this);
    this.endContractDateChange = this.endContractDateChange.bind(this);

    this.state = {
      status: '',
      experience: '',
      source: '',
      country: '',
      manager: '',
      startDate: '',
      endDate: '',
      startContractDate: '',
      endContractDate: '',
      city: '',
      cities: [],
      search: '',
    };
  }
  handleSource(source) {
    this.setState({ source }, () => {
      this.onFilter();
    });
  }
  handleStatus(status) {
    this.setState({ status }, () => {
      this.onFilter();
    });
  }

  handleExperience(experience) {
    this.setState({ experience }, () => {
      this.onFilter();
    });
  }
  handleCountry(country) {
    this.setState({ country }, () => {
      this.onFilter();
    });
  }

  startDateChange(startDate) {
    this.setState({ startDate });
  }
  endDateChange(endDate) {
    this.setState({ endDate });
  }

  startContractDateChange(startContractDate) {
    this.setState({ startContractDate });
  }
  endContractDateChange(endContractDate) {
    this.setState({ endContractDate });
  }

  handleManager(manager) {
    console.log('manager==>', manager, FRANCHISE_MANAGER_OPTIONS);

    if (typeof manager === 'number') {
      return this.setState({ manager: manager.toString() }, () => {
        this.onFilter();
      });
    } else {
      return this.setState({ manager }, () => {
        this.onFilter();
      });
    }
  }
  handleCity(city) {
    this.setState({ city }, () => {
      this.onFilter();
    });
  }
  handleSearch(e) {
    this.setState({ search: e.target.value });
  }

  onFilter() {
    let filter = {};
    if (this.state.experience !== '') {
      filter.have_tourism_experience = this.state.experience.value;
    }
    if (this.state.status.length === 0) {
      // filter.status = this.state.status;
    } else if (this.state.status.length !== 0) {
      filter.status = this.state.status.map((s) => s.value);
    }
    if (this.state.source.length === 0) {
      // filter.source = this.state.source;
    } else if (this.state.source.length !== 0) {
      filter.source = this.state.source.map((s) => s.value);
    }
    if (this.state.country.length === 0) {
    } else if (this.state.country.length !== 0) {
      filter.country = this.state.country.map((c) => c.value);
    }
    if (!this.state.manager) {
      this.state.manager = '';
    } else if (
      (this.state.manager.length !== 0) &
      (typeof this.state.manager !== 'number')
    ) {
      filter.manager = this.state.manager.map((m) => m.value);
    }
    if (this.state.startDate) {
      filter.created__gte = moment(this.state.startDate).format('DD.MM.YYYY');
    }
    if (this.state.endDate) {
      filter.created__lte = moment(this.state.endDate).format('DD.MM.YYYY');
    }
    if (this.state.startContractDate) {
      filter.contract_date__gte = moment(this.state.startContractDate).format(
        'DD.MM.YYYY'
      );
    }
    if (this.state.endContractDate) {
      filter.contract_date__lte = moment(this.state.endContractDate).format(
        'DD.MM.YYYY'
      );
    }
    if (this.state.city.length === 0) {
    } else if (this.state.city.length !== 0) {
      filter.city = this.state.city.map((c) => c.value);
    }
    if (this.state.search) {
      filter.search = this.state.search;
    }

    this.props.onFilter(filter);
  }

  onReset() {
    this.setState(
      {
        status: '',
        experience: '',
        source: '',
        country: '',
        manager: '',
        startDate: '',
        endDate: '',
        startContractDate: '',
        endContractDate: '',
        city: '',
        cities: [],
        search: '',
      },
      () => {
        this.onFilter();
      }
    );
  }

  fetchCities() {
    let self = this;
    let status;
    FranchiseApplicationAPI.fetchJSON(`filters/city/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        self.setState({
          cities: status === POSITIVE_ACTION_STATUSES.list ? r : [],
        });
      });
  }

  componentDidMount() {
    this.fetchCities();
  }
  render() {
    const {
      status,
      experience,
      source,
      country,
      manager,
      city,
      cities,
      search,
    } = this.state;

    const FRANCHAISE_CITIES_OPTIONS = cities.map((city) => {
      return {
        value: city.name.toString(),
        label: city.name.toString(),
      };
    });
    const customStyles = {
      ///.....
      menuPortal: (provided) => ({ ...provided, zIndex: 5 }),

      ///.....
    };

    return (
      <div className="animated fadeIn filter-container">
        <div className="filter filter--blue">
          <div className="card card--search-bg">
            <div className="card-header">
              <strong>Заявки на фрачзайзинг</strong>
            </div>
            <div className="card-body">
              <div className="filter-row">
                <div className="filter-field date-from">
                  <div className="date-from">
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={this.state.startDate}
                      selectsStart
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.startDateChange}
                      className="d-block"
                      placeholderText="дата создания заявки ОТ"
                    />
                  </div>
                </div>
                <div className="filter-field date-to">
                  <div className="date-to">
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={this.state.endDate}
                      selectsEnd
                      startDate={this.state.startDate}
                      endDate={this.state.endDate}
                      onChange={this.endDateChange}
                      placeholderText="дата создания заявки ДО"
                    />
                  </div>
                </div>
                <div className="filter-field filter__source">
                  <Select
                    id="franchaise_source"
                    name="source"
                    placeholder="Источник"
                    isMulti={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    value={source}
                    onChange={this.handleSource}
                    options={FRANCHISE_SOURCE_OPTIONS}
                  />
                </div>
                <div className="filter-field filter__source">
                  <Select
                    id="franchaise_status"
                    name="status"
                    placeholder="Статус"
                    isMulti={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    value={status}
                    onChange={this.handleStatus}
                    options={FRANCHISE_STATUS_OPTIONS}
                  />
                </div>
              </div>
              <div className="filter-row ">
                <div className="filter-field filter__type">
                  <Select
                    id="franchaise_country"
                    name="country"
                    placeholder="Страна"
                    isMulti={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    value={country}
                    onChange={this.handleCountry}
                    options={FRANCHISE_COUNTRY_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <Select
                    id="franchaise_experience"
                    name="experience"
                    placeholder="Опыт в туризме"
                    simpleValue={true}
                    closeOnSelect={true}
                    value={experience}
                    onChange={this.handleExperience}
                    options={FRANCHISE_EXPERIENCE_OPTIONS}
                  />
                </div>
                <div className="filter-field">
                  <Select
                    id="manager"
                    name="manager"
                    placeholder="Выберите менеджера"
                    isMulti={true}
                    simpleValue={true}
                    value={manager}
                    onChange={this.handleManager}
                    options={FRANCHISE_MANAGER_OPTIONS}
                  />
                </div>
                <div className="filter-field filter__type">
                  <Select
                    id="franchaise_office_city"
                    name="office_city"
                    placeholder="Город"
                    isMulti={true}
                    simpleValue={true}
                    closeOnSelect={true}
                    value={city}
                    onChange={this.handleCity}
                    options={FRANCHAISE_CITIES_OPTIONS}
                  />
                </div>
              </div>
              <div className="filter-row ">
                <div className="filter-field">
                  <label>Телефон или почта</label>
                  <input
                    type="text"
                    className="form-control"
                    id="search"
                    placeholder="Телефон или почта"
                    name="search"
                    value={search}
                    onChange={this.handleSearch}
                  />
                </div>
                <div className="filter-field date-from">
                  <div className="date-from">
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={this.state.startContractDate}
                      selectsStart
                      startDate={this.state.startContractDate}
                      endDate={this.state.endContractDate}
                      onChange={this.startContractDateChange}
                      className="d-block"
                      placeholderText="дата договора ОТ"
                    />
                  </div>
                </div>
                <div className="filter-field date-to">
                  <div className="date-to">
                    <DatePicker
                      dateFormat="dd.MM.yyyy"
                      selected={this.state.endContractDate}
                      selectsEnd
                      startDate={this.state.startContractDate}
                      endDate={this.state.endContractDate}
                      onChange={this.endContractDateChange}
                      placeholderText="дата договора ДО"
                    />
                  </div>
                </div>
              </div>
              <div
                className={`card-footer-container ${
                  this.props.isShowForm
                    ? 'card-footer-container-form_active'
                    : ''
                }`}
              >
                {this.props.children}
                <div className="card-footer">
                  <button
                    className="btn btn-sm btn-primary"
                    onClick={this.onFilter}
                  >
                    Найти
                  </button>
                  <button
                    type="reset"
                    className="btn btn-sm btn-danger"
                    onClick={this.onReset}
                  >
                    <i className="fa fa-ban"></i> Очистить
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
