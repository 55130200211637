import React, { PureComponent } from 'react';
import { NavLink } from 'react-router-dom';
import moment from 'moment';
import 'moment/locale/ru';
import { Collapse } from 'reactstrap';

class CalendarEventList extends PureComponent {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);

    this.state = { collapse: false };
  }

  toggle() {
    this.setState({ collapse: !this.state.collapse });
  }
  render() {
    if (!this.props.eventList.length) {
      return <ul className="list-group"></ul>;
    }
    return (
      <ul className="list-group" onClick={this.toggle}>
        <li
          className="list-group-item"
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
          }}
        >
          <span className={`badge badge-pill badge-${this.props.color}`}>
            {this.props.title}
          </span>
          <span
            className={`float-right badge badge-${this.props.color} badge-pill`}
          >
            {this.props.eventList.length}
          </span>
        </li>
        <Collapse isOpen={this.state.collapse}>
          {this.props.eventList.map((event, idx) => (
            <li
              className="list-group-item"
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                maxHeight: 60,
                alignItems: 'center',
              }}
              key={idx}
            >
              <div style={{ minWidth: 90 }}>
                <i className="fa fa-circle fa-1" aria-hidden="true"></i>
                <NavLink to={`/calendar/${event.day}`}>
                  <span className="">
                    {moment(event.day).format('DD.MM.YY')}
                  </span>
                </NavLink>
              </div>
              <div style={{ textAlign: 'right' }}>
                <span>{event.notes}</span>
              </div>
            </li>
          ))}
        </Collapse>
      </ul>
    );
  }
}

export default CalendarEventList;
