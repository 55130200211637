import React from 'react';
import { Link } from 'react-router-dom';
import CalendarDayNotificationsPanel from './CalendarDayNotificationsPanel';
const iStyle = {
  display: 'inline-block',
};
const EVENT_TYPE_LANG = {
  meet: 'Событие',
  deffered: 'Напомнить',
  call: 'Позвонить',
};
const EVENT_TYPE_CLASS = {
  meet: 'fa fa-thumb-tack',
  deffered: 'fa fa-bell',
  call: 'fa fa-phone',
};

function CalendarDay({ event, createEvent }) {
  return (
    <td className={event.style} style={{ cursor: 'auto' }}>
      <div className="numeric-day-container">
        <Link
          className={`numeric-day ${event.events.length ? 'task' : ''}`}
          to={`${event.dateId}`}
        >
          {event.date}
        </Link>
      </div>
      <div className="day-notes">
        {event.events.length
          ? event.events.map(
              ({ comment_type, id, notes, start_time, user }, key) => (
                <Link key={key} to={`${event.dateId}#${id}`}>
                  <div title={`Исполнитель: ${user && user.full_name}`}>
                    {comment_type ? (
                      <div className="item-count">
                        <i className={EVENT_TYPE_CLASS[comment_type]}></i>
                        <span className="item-label" style={{ marginLeft: 5 }}>
                          {start_time ? `${start_time.substring(0, 5)} ` : ''}
                          {EVENT_TYPE_LANG[comment_type]}
                        </span>
                      </div>
                    ) : (
                      <div className="item-count">
                        <i className="fa fa-bookmark"></i>
                        <span className="item-label" style={{ marginLeft: 5 }}>
                          {`${start_time.substring(0, 5)} ${notes}`}
                        </span>
                      </div>
                    )}
                  </div>
                </Link>
              )
            )
          : null}
      </div>
      <CalendarDayNotificationsPanel event={event} iStyle={iStyle} />
      {!event.style.includes('past') && (
        <button onClick={createEvent} className="add-event-btn">
          <i
            id={event.dateId}
            className="fa fa-plus-circle"
            title="Добавить событие"
          />
        </button>
      )}
    </td>
  );
}

export default CalendarDay;
