import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';

import { AuthRequired, getUserRole, SUBAGENT } from '../../connect/auth';

import Header from './header';
import Sidebar from './sidebar';
import Dashboard from '../../views/Dashboard/';
import DashboardMobile from '../../views/Dashboard/DashboardMobile';
import Leads from '../../views/Leads';
import Lead from '../../views/Lead';
import Persons from '../../views/Persons';
import Person from '../../views/Person';
import PersonEdit from '../../views/Person/components/PersonEdit';
import OrdersFp from '../../views/OrdersFp';
import OrderFp from '../../views/OrderFp';
import OrderFpEdit from '../../views/OrderFp/components/OrderFpEdit';
import Offices from '../../views/Offices';
import Office from '../../views/Office';
import Templates from '../../views/Templates';
import Telebot from '../../views/Telebot/Telebot';
import Mailbox from '../../views/Mailbox/Mailbox';
import Report from '../../views/Mailbox/components/Report';
import Options from '../../views/Options';
import Instructions from '../../views/Instructions';
import Calendar from '../../views/Components/Calendar';
import CalendarDetail from '../../views/Components/Calendar/Components/CalendarDetail';
import Notify from '../../views/Notify';
import Breadcrumb from '../../components/__Breadcrumb/';
import Tourists from '../../views/TouristsFp';
import Tourist from '../../views/TouristFp';
import IssuesReport from '../../views/IssuesReport';
import Issues from '../../views/Issues/IssuesPage';
import Issue from '../../views/Issue';
import Tags from '../../views/Tags';
import Quotes from '../../views/Tours';
import Quote from '../../views/Quote';
import Employee from '../../views/Employees';
import TouristEdit from '../../views/TouristFp/components/TouristFpEdit';
import Employees from '../../views/Employees';
import EmployeesDetail from '../../views/Employees/components/EmployeesDetail';
import TemplateSettings from '../../views/Options/components/TemplateSettings';
import MailigenSetting from '../../views/MailigenSetting';
import CashAccounts from '../../views/CashAccounts/CashAccounts';
import CashAccount from '../../views/CashAccounts/components/CashAccount';
import Invoice from '../../views/Invoices/Invoice';
import Agencies from '../../views/Agencies/Agencies';
import Agency from '../../views/Agencies/components/Agency';
import FormSubagent from '../../views/Employees/components/FormSubagent';
import SendPulseSetting from '../../views/SendPulseSetting/SendPulseSetting';
import { SIMPLE, NOTIFICATION } from '../../views/Mailbox/Mailbox';
import SummariesForOffices from '../../views/Offices/SummariesForOffices';
import FranchiseApplications from '../../views/FranchiseApplicationList/FranchiseApplications';
import FranchiseApplication from '../../views/FranchiseApplicationList/FranchiseApplication';
import FranchiseApplicationAction from '../../views/FranchiseApplicationList/FranchiseApplicationAction';
import SellBook from '../../views/SellBook/components/SellBook';
import Subscription from '../../views/Subscription/Subscription';
import AbandonedCustomers from '../../views/AbandonedCustomers/AbandonedCustomers';
import TouristCabinets from '../../views/TouristCabinet/TouristCabinets';
import TouristCabinet from '../../views/TouristCabinet/TouristCabinet';
/*

Схема приложения
----------------

1. Header <Header/>
2. Body
  2.1 Sidebar <Sidebar/>
  2.2 Main
    2.2.1.Breadcrumb <Breadcrumb/>
    2.2.2 Container
3. Footer <Footer/>

*/

const mainContainerStyle = {
  padding: '0px 16px 0px 16px',
  // height:'100%',
};
const screenWidth = window.screen.width;
const screenWidthMaxMobile = 680;

const containerRender = () => {
  const userRole = getUserRole();
  return (
    <>
      <Breadcrumb />
      <div className="container-fluid" style={mainContainerStyle}>
        <Routes>
          {/*..Notify..*/}
          <Route path="/notify" element={<Notify />} />

          {/*..Dashboard..*/}
          <Route
            path="/dashboard"
            element={
              screenWidth > screenWidthMaxMobile ? (
                <Dashboard />
              ) : (
                <DashboardMobile />
              )
            }
          />

          {/*..Offices..*/}
          <Route path="/offices">
            <Route index element={<Offices />} />
            <Route path=":officeId" element={<Office />} />
          </Route>
          <Route path="/summaries" element={<SummariesForOffices />} />

          {/*..Orders..*/}
          <Route path="/ordersfp">
            <Route index element={<OrdersFp />} />
            <Route path=":orderId" element={<OrderFp />} />
            <Route path=":orderId/edit" element={<OrderFpEdit />} />
          </Route>

          {/*..Tourists..*/}
          <Route path="/tourists">
            <Route index element={<Tourists />} />
            <Route path=":touristId" element={<Tourist />} />
            <Route path=":touristId/edit/" element={<TouristEdit />} />
          </Route>

          {/*..Leads..*/}
          <Route path="/leads">
            <Route index element={<Leads />} />
            <Route path=":leadId" element={<Lead />} />
          </Route>

            {/*..Persons..*/}
          <Route path="/persons">
            <Route index element={<Persons />} />
            <Route path=":personId" element={<Person />} />
            <Route path=":personId/edit" element={<PersonEdit />} />
          </Route>

          <Route path="/issues">
            <Route index element={<Issues />} />
            <Route path=":issueId" element={<Issue />} />
          </Route>
          <Route path="/issues-report" element={<IssuesReport />} />

          {/*..Employees..*/}
          <Route path="/employees">
            <Route index element={<Employees />} />
            <Route path=":employeeId" element={<EmployeesDetail />} />
            <Route path=":employeeId/edit/" element={<FormSubagent />} />
          </Route>

          <Route path="/tags" element={<Tags />} />
          <Route path="/sellbook" element={<SellBook />} />

          {/*..User..*/}
          <Route path="/adduser" element={<Employee />} />

          <Route path="/cashaccounts">
            <Route index element={<CashAccounts />} />
            <Route path=":accountId" element={<CashAccount />} />
          </Route>

          <Route path="/invoices/:invoiceId" element={<Invoice />} />

          <Route path="/agencies">
            <Route index element={<Agencies />} />
            <Route path=":agencyId" element={<Agency />} />
          </Route>

          <Route path="/subscription" element={<Subscription />} />

          <Route path="/abandonedcustomers" element={<AbandonedCustomers />} />

          {/*..Templates...*/}
          <Route path="/templates">
            <Route index element={<Templates />} />
            <Route path=":templateId" element={<TemplateSettings />} />
          </Route>

          <Route path="/telebot" element={<Telebot />} />

          <Route path="/mailbox">
            <Route
              index
              element={
                <Mailbox
                  campaignInitType={SIMPLE}
                  isDesktop={screenWidth > screenWidthMaxMobile}
                />
              }
            />
            <Route path=":reportId" element={<Report />} />
          </Route>

          <Route path="/notification">
            <Route
              index
              element={
                <Mailbox
                  campaignInitType={NOTIFICATION}
                  isDesktop={screenWidth > screenWidthMaxMobile}
                />
              }
            />
            <Route path=":reportId" element={<Report />} />
          </Route>

          <Route path="/options" element={<Options />} />
          <Route path="/instructions" element={<Instructions />} />

          <Route path="/mailigen/:number" element={<MailigenSetting />} />
          <Route path="/mailigen/:number/edit" element={<MailigenSetting />} />

          <Route path="/sendpulse/:number" element={<SendPulseSetting />} />
          <Route
            path="/sendpulse/:number/edit/"
            element={<SendPulseSetting />}
          />

          <Route path="/franchising">
            <Route index element={<FranchiseApplications />} />
            <Route path=":applicationId" element={<FranchiseApplication />} />
            <Route
              path=":applicationId/edit"
              element={<FranchiseApplicationAction />}
            />
          </Route>

          <Route path="/touristcabinets">
            <Route index element={<TouristCabinets />} />
            <Route path=":cabinetId" element={<TouristCabinet />} />
          </Route>

          <Route path="/calendar">
            <Route index element={<Calendar />} />
            <Route path=":day" element={<CalendarDetail />} />
          </Route>

          <Route path="/quotes">
            <Route index element={<Quotes />} />
            <Route path=":quoteId" element={<Quote />} />
          </Route>

          {/*.redirect..*/}
          {userRole !== SUBAGENT && (
            <Route path="*" element={<Navigate to="/dashboard" replace />} />
          )}

          {userRole === SUBAGENT && (
            <Route path="*" element={<Navigate to="/quotes" replace />} />
          )}
        </Routes>
      </div>
    </>
  );
};

export default (props) => {
  return (
    <AuthRequired>
      <div className="app">
        <Header />
        <div className="app-body">
          <Sidebar />
          <main className="main">{containerRender()}</main>
        </div>
      </div>
    </AuthRequired>
  );
};
