import React from 'react';
import { Navigate } from 'react-router-dom';
import jwt_decode from 'jwt-decode';
import { UsersAPI } from '../api/Client';
export const USER_TOKEN = 'token';
export const CURRENT_USER_OFFICE = 'office';
export const SUPER_ADMIN = 'super_admin';
export const ADMIN = 'admin';
export const MANAGER = 'manager';
export const SUBAGENT = 'subagent';

/**
 * Функция для записи токена в localStorage
 * @param token {string}
 */
export function pushToken(token) {
  localStorage.setItem(USER_TOKEN, token);
}

/**
 * Функция для удаления токена в localStorage
 */
export function removeToken() {
  localStorage.removeItem(USER_TOKEN);
}

/**
 * Функция для получения токена в localStorage
 * @returns {string}
 */
export function fetchToken() {
  return localStorage.getItem(USER_TOKEN) || ``;
}

/**
 * Функция для записи данных о выбранном офисе в localStorage
 * @param office {object}
 * @returns {boolean}
 */
export function pushCurrentOffice(office) {
  try {
    localStorage.setItem(CURRENT_USER_OFFICE, JSON.stringify(office));
    return true;
  } catch (e) {
    return false;
  }
}

/**
 * Функция для удаления данных о выбранном офисе из localStorage
 */
export function removeCurrentOffice() {
  localStorage.removeItem(CURRENT_USER_OFFICE);
}

/**
 * Функция для извлечения данных о выбранном офисе из localStorage
 * @returns {string}
 */
export function fetchCurrentOffice() {
  return localStorage.getItem(CURRENT_USER_OFFICE);
}

/**
 * Функция для получения данных о выбранном офисе
 * @returns {object}
 */
export function getCurrentOfficeData() {
  const office = fetchCurrentOffice();
  let officeData = {};

  if (typeof office !== 'string') {
    return {};
  }

  try {
    officeData = JSON.parse(office);
  } catch (e) {
    return {};
  }

  if (typeof officeData !== 'object' || !officeData) {
    return {};
  }

  return officeData;
}

/**
 * Функция для получения идентификатора выбранного офиса
 * @returns {number}
 */
export function getCurrentOfficeId() {
  const office = fetchCurrentOffice();
  let officeData = {};
  let officeId = 0;

  if (typeof office !== 'string') {
    return 0;
  }

  try {
    officeData = JSON.parse(office);
  } catch (e) {
    return 0;
  }

  if (typeof officeData !== 'object' || !officeData) {
    return 0;
  }

  officeId = parseInt(officeData.id, 10);

  return isNaN(officeId) ? 0 : officeId;
}

/**
 * Функция деаутентификации пользователя
 * @returns {number}
 */
export function deauthenticateUser() {
  removeToken();
  removeCurrentOffice();
}

/**
 * Функция для для декодирования данных пользователя из токена
 * @param token
 * @returns {object}
 */
export function getUserInfoByToken(token) {
  return jwt_decode(token);
}

/**
 * Функция для получения данных пользователя из токена
 * @returns {object | null}
 */
export function getUserInfo() {
  try {
    return getUserInfoByToken(fetchToken());
  } catch (e) {
    return null;
  }
}

/**
 * Функция для получения роли пользователя из токена
 * @returns {string}
 */
export function getUserRole() {
  try {
    const info = getUserInfo();
    switch (info.role) {
      case 1:
        return SUPER_ADMIN;
      case 2:
        return ADMIN;
      case 3:
        return MANAGER;
      case 4:
        return SUBAGENT;
      default:
        return '';
    }
  } catch (e) {
    return '';
  }
}

/**
 * Функция для проверки даты истечения токена
 * @param timestamp
 * @returns {boolean}
 */
export function checkTokenExpirationDate(timestamp) {
  return timestamp > new Date().getTime() / 1000;
}

export function AuthRequired(props) {
  const token_info = getUserInfo();
  const officeId = getCurrentOfficeId();

  if (!token_info || !checkTokenExpirationDate(token_info.exp)) {
    deauthenticateUser();
    return <Navigate to="/login" replace={true} />;
  }

  if (!officeId) {
    deauthenticateUser();
    return <Navigate to="/officein" replace={true} />;
  }

  if (
    !token_info.is_admin ||
    !Object.keys(token_info.is_admin).includes(officeId.toString())
  ) {
    deauthenticateUser();
    return <Navigate to="/login" replace={true}/>;
  }

  return props.children;
}

export function getUserAvatar() {
  let status;
  const userInfo = getUserInfo();

  UsersAPI.get(userInfo.user_id)
    .then((r) => {
      status = r.status;
      return r.json();
    })
    .then((r) => {
      console.log(r);
    });
}
