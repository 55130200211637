import { useState } from 'react';
import './style.css';
import {
  checkTokenExpirationDate,
  getUserInfo,
  pushToken,
} from '../../connect/auth';
import APIClient, { POSITIVE_ACTION_STATUSES } from '../../api/Client';
import { Navigate, useNavigate } from 'react-router-dom';

const Login = () => {
  const [login, setLogin] = useState('');
  const [password, setPassword] = useState('');
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();

  function handleChange(e) {
    const { name, value } = e.target;
    setShowErrorMessage(false);
    name === 'login' ? setLogin(value) : setPassword(value);
  }

  function error() {
    setShowErrorMessage(true);
  }

  function success(r) {
    pushToken(r.access);
    navigate('/officein');
  }

  function handleSubmit(e) {
    e.preventDefault();
    let status;
    APIClient.fetchAuthToken(login, password)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        status === POSITIVE_ACTION_STATUSES.retrieve ? success(r) : error(r);
      });
  }

  const userInfo = getUserInfo();

  if (!!userInfo && checkTokenExpirationDate(userInfo.exp)) {
    return <Navigate to="/officein" />;
  }

  if (!localStorage.getItem('auth')) {
    localStorage.clear();
    localStorage.setItem('auth', 1);
  }

  return (
    <div className="app flex-row align-items-center auth--bg-img">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4 ">
            <img height="64" src="../img/logo.client.png" />
            <div className="hidden-mobile">
              <p>
                Геоклиент - система управления эффективной коммуникацией с
                клиентами в офисах туристической сети "География". Внедрение
                программы позволит вам оперативно и сегментированно работать с
                клиентсткой базой, автоматизировать отправку типовых и
                обязательных обращений клиентам.{' '}
              </p>
              <p>
                Сохранить в копилке агентства каждое обращение и не потерять ни
                одного контакта потенциального клиента.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-group mb-0">
              <div className="card p-4">
                <div className="card-body">
                  <form action="" onSubmit={handleSubmit}>
                    <h1>Вход</h1>

                    <div className="input-group mb-3">
                      <span className="input-group-addon">
                        <i className="icon-user"> </i>
                      </span>
                      <input
                        type="text"
                        className="form-control"
                        placeholder="Логин"
                        name="login"
                        value={login}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="input-group mb-4">
                      <span className="input-group-addon">
                        <i className="icon-lock"> </i>
                      </span>
                      <input
                        type="password"
                        className="form-control"
                        placeholder="Пароль"
                        name="password"
                        value={password}
                        onChange={handleChange}
                      />
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <button type="submit" className="btn btn-primary px-4">
                          Войти
                        </button>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-4">
            {showErrorMessage ? (
              <div className="alert alert-danger fade show" role="alert">
                <strong>Ошибка!</strong> Не удалось войти
              </div>
            ) : null}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
