import React, { Component } from 'react';
import { Link } from 'react-router-dom';
import { Col, Row, Card, CardBody, CardHeader } from 'reactstrap';
import Paginator from '../../components/Paginator';
import moment from 'moment';
import 'moment/locale/ru';
import { getCurrencyIcon } from './CurrencySelector';
import ProgressBar from '../../components/ProgressBar';

class OrdersFpTable extends Component {
  constructor(props) {
    super(props);

    this.createMail = this.createMail.bind(this);
    this.state = {};

    localStorage.removeItem('abandonedcustomers_filter');
    localStorage.removeItem('franchise_filter');
    localStorage.removeItem('franchise_leads_filter');
    localStorage.removeItem('leads_filter');
    localStorage.removeItem('persons_filter');
    localStorage.removeItem('subscription_filter');
    localStorage.removeItem('tourist_filter');
    localStorage.removeItem('tourists_cabinet_filter');
    localStorage.removeItem('tourists_filter');
    localStorage.removeItem('tourists_report_filter');
    localStorage.removeItem('touristsfp_filter');

    localStorage.removeItem('leads_total');
    localStorage.removeItem('tourists_total');
    localStorage.removeItem('tourist_total');
    localStorage.removeItem('touristsfp_total');
    localStorage.removeItem('persons_total');
    localStorage.removeItem('tourists_cabinet_total');
    localStorage.removeItem('franchise_total');
    localStorage.removeItem('subscription_total');
    localStorage.removeItem('franchise_leads_total');
    localStorage.removeItem('tourists_report_type');
    localStorage.removeItem('tourists_report_address_books');
    localStorage.removeItem('abandonedcustomers_total');
    localStorage.removeItem('type');
  }

  createMail() {
    const { count, filters } = this.props;
    if (filters) {
      localStorage.setItem('tourists_filter', JSON.stringify(filters));
      localStorage.setItem('tourists_total', JSON.stringify(count));
      localStorage.setItem('type', 'tourist');
    }
    return false;
  }

  render() {
    let franchColor = '#f6fff6';
    const { count, isShowActions, items, page, handlePageChange, isLoading } =
      this.props;

    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify hidden-mobile">{''}</i>{' '}
                Всего&nbsp;
                <span className="badge badge-info">{count}</span>
                <div className="float-right" hidden={!isShowActions}>
                  <Link
                    className="btn btn-info"
                    onClick={this.createMail}
                    to="/templates/"
                  >
                    Создать рассылку
                  </Link>
                </div>
              </CardHeader>

              <CardBody className="card-body_responsive">
                {isLoading ? (
                  <div className="row animated fadeIn">
                    <div className="col-md-12 col-md-offset-6 text-center">
                      <ProgressBar />
                    </div>
                  </div>
                ) : !items.length ? (
                  <div className="row animated fadeIn">
                    <div className="col-md-12 col-md-offset-6 text-center">
                      Ничего не нашлось, смените критерии поиска
                    </div>
                  </div>
                ) : (
                  <table className="table table--pink table-bordered table-striped table-sm">
                    <thead>
                      <tr>
                        <th className="text-center tableItem">Номер</th>
                        <th className="text-center tableItem">Статус</th>
                        <th className="text-center tableItem hidden-mobile">
                          Создана
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Начало
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Конец
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          <i
                            className="fa fa-moon-o fa-lg"
                            style={{ color: 'orange' }}
                          >
                            {''}
                          </i>
                        </th>
                        <th className="text-center tableItem  hidden-mobile">
                          Город вылета
                        </th>
                        <th className="text-center tableItem">Страна</th>
                        <th className="text-center tableItem hidden-mobile">
                          <i
                            className="fa fa-money fa-lg"
                            style={{ color: 'green' }}
                          >
                            {''}
                          </i>
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Всего/Вз/Дет
                        </th>
                        <th className="text-center tableItem">Туристы</th>
                        <th className="text-center tableItem hidden-mobile">
                          Заказчик
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Менеджер
                        </th>
                        <th className="text-center tableItem hidden-mobile">
                          Менеджер СБ
                        </th>
                      </tr>
                    </thead>
                    <tbody>
                      {!!items &&
                        items.map((item) => {
                          const {
                            id,
                            fp_pk,
                            status,
                            depart_city,
                            destination_country,
                            tourists_count,
                            manager_fp,
                            manager,
                            customer,
                            tourists_fp,
                            create_date,
                            begin_date,
                            end_date,
                            currency,
                            summ,
                          } = item;

                          const managers =
                            Array.isArray(item.manager) && item.manager.length
                              ? manager
                              : [];
                          const tourists =
                            Array.isArray(tourists_fp) && tourists_fp.length
                              ? item.tourists_fp
                              : [];
                          const naturalCustomer = !!customer
                            ? customer
                            : tourists.length
                            ? tourists[0]
                            : {};

                          return (
                            <tr key={`order-${fp_pk}`}>
                              <td className="text-center tableItem">
                                <Link to={`/ordersfp/${id}`}>{fp_pk}</Link>
                              </td>
                              <td className="text-center tableItem">
                                {status.name}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {moment(create_date).format('DD.MM.YY HH:mm')}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {moment(begin_date).format('DD.MM.YY')}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {moment(end_date).format('DD.MM.YY')}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {moment
                                  .duration(
                                    moment(end_date).diff(moment(begin_date))
                                  )
                                  .asDays()}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {depart_city}
                              </td>
                              <td className="text-center tableItem">
                                {destination_country}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {summ}{' '}
                                {!!currency && getCurrencyIcon(currency.name)}
                              </td>
                              <td className="text-center tableItem hidden-mobile">
                                {tourists_count}
                              </td>
                              <td className="text-center tableItem">
                                {tourists.length &&
                                  tourists.map((tourist) => (
                                    <p
                                      className="tableItem"
                                      key={`tourist-${tourist.id}`}
                                    >
                                      <Link to={`/tourists/${tourist.id}`}>
                                        {tourist.full_name}
                                      </Link>
                                    </p>
                                  ))}
                              </td>
                              <th className="text-center tableItem hidden-mobile">
                                {!!naturalCustomer && naturalCustomer.full_name}
                              </th>
                              <th className="text-center tableItem hidden-mobile">
                                {!!managers.length &&
                                  managers.map((m) => {
                                    let name = [m.last_name, m.first_name]
                                      .join(' ')
                                      .trim();
                                    return (
                                      <p
                                        className="tableItem"
                                        key={`manger-${m.id}`}
                                      >
                                        {!name ? m.username : name}
                                      </p>
                                    );
                                  })}
                              </th>
                              <td className="text-center tableItem hidden-mobile">
                                {!!manager_fp && manager_fp.name}
                              </td>
                            </tr>
                          );
                        })}
                    </tbody>
                  </table>
                )}
                <Paginator
                  total={count}
                  onPageChange={handlePageChange}
                  forcePage={page - 1}
                />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

OrdersFpTable.defaultProps = {
  filters: null,
  handleRefresh: () => null,
};

export default OrdersFpTable;
