import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import DatePicker from 'react-datepicker';
import moment from 'moment';
import React, { Component } from 'react';
import Select from 'react-select';

import ManagersSelectContainer from '../../../components/ManagersSelectContainer/ManagersSelectContainer';
import OfficeSelectContainer from '../../../components/OfficeSelectContainer/OfficeSelectContainer';

export const STATUS_DESCRIPTION_MAP = {
  new: 'Новая',
  work: 'Открыл',
  reserved: 'Покупка',
  cancelled: 'Аннулирована',
  call: 'Звонок',
  meet: 'Встреча',
  tours: 'Отправлена подборка',
  client_cancel: 'Турист отказался',
  we_cancel: 'Мы отказали',
  deffered: 'Отложен',
};

export const SOURCE_OPTIONS = [
  {
    label: 'Сайт',
    options: [
      { label: 'Горящая заявка', value: 'geo_site_form_hot' },
      { label: 'Чат', value: 'geo_site_chat' },
      { label: 'Геоподборка', value: 'geo_site_quote' },
      { label: 'Агентский поисковик', value: 'agency_tour' },
      { label: 'Экскурсионный тур', value: 'cabinet_excursion_tour' },
    ],
  },
  {
    label: 'Кабинет',
    options: [
      { label: 'ЛК (офис)', value: 'cabinet' },
      { label: 'ЛК (покупка)', value: 'cabinet_online' },
    ],
  },
];

export const SOURCE_OPTIONS_NAMES = [
  { label: 'Горящая заявка', value: 'geo_site_form_hot' },
  { label: 'Чат', value: 'geo_site_chat' },
  { label: 'Геоподборка', value: 'geo_site_quote' },
  { label: 'Агентский поисковик', value: 'agency_tour' },
  { label: 'Экскурсионный тур', value: 'cabinet_excursion_tour' },
  { label: 'ЛК (офис)', value: 'cabinet' },
  { label: 'ЛК (покупка)', value: 'cabinet_online' },
];

const sourceOptions = SOURCE_OPTIONS;

const statusOptions = Object.keys(STATUS_DESCRIPTION_MAP).map((k) => ({
  label: STATUS_DESCRIPTION_MAP[k],
  value: k,
}));

class FilterLeads extends Component {
  constructor(props) {
    super(props);

    this.handleReset = this.handleReset.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.changeStartDate = this.changeStartDate.bind(this);
    this.changeEndDate = this.changeEndDate.bind(this);
    this.changeInput = this.changeInput.bind(this);
    this.changeManagers = this.changeManagers.bind(this);
    this.changeOffice = this.changeOffice.bind(this);
    this.changeAngecyCity = this.changeAngecyCity.bind(this);
    this.changeAngecyCountry = this.changeAngecyCountry.bind(this);
    this.changeSource = this.changeSource.bind(this);
    this.changeStatus = this.changeStatus.bind(this);
    this.changeWasTransferred = this.changeWasTransferred.bind(this);
    this.loadFilter = this.loadFilter.bind(this);
    this.saveFilter = this.saveFilter.bind(this);
    this.handleFilterUpdate = this.handleFilterUpdate.bind(this);

    moment.locale('ru');

    const filter = this.loadFilter();
    this.state = filter || {
      office: '',
      managers: [],
      startDate: '',
      endDate: '',
      city: '',
      agencyCitySelected: null,
      agencyCountrySelected: null,
      agencySelectedOffice: [],
      destination_country: '',
      source: null,
      status: null,
      was_transferred: null,
    };
    this.props.onFilter(this.state);
  }

  changeInput(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value });
  }

  changeAngecyCity(agencyCitySelected) {
    this.setState({ agencyCitySelected });
  }

  changeAngecyCountry(agencyCountrySelected) {
    this.setState({ agencyCountrySelected });
  }

  changeOffice(agencySelectedOffice) {
    this.setState({ agencySelectedOffice });
  }

  changeManagers(managers) {
    this.setState({ managers });
  }

  changeSource(source) {
    this.setState({ source });
  }

  changeStatus(status) {
    this.setState({ status });
  }

  changeWasTransferred(was_transferred) {
    this.setState({ was_transferred });
  }

  changeStartDate(startDate) {
    this.setState({ startDate });
  }

  changeEndDate(endDate) {
    this.setState({ endDate });
  }

  handleReset() {
    this.props.setPage({ selected: 0 });
    this.setState(
      {
        office: '',
        managers: [],
        agencyCitySelected: null,
        agencyCountrySelected: null,
        agencySelectedOffice: [],
        startDate: '',
        endDate: '',
        city: '',
        destination_country: '',
        source: null,
        status: null,
        was_transferred: null,
      },
      this.handleFilterUpdate
    );
  }

  _get_valid_serialize_state(data) {
    const fields = [
      'office',
      'managers',
      'agencyCitySelected',
      'startDate',
      'endDate',
      'agencyCountrySelected',
      'agencySelectedOffice',
      'city',
      'destination_country',
      'source',
      'status',
      'was_transferred',
    ];
    const validated_data = {};

    for (let field in data) {
      if (~fields.indexOf(field)) {
        if (field == 'startDate' || field == 'endDate') {
          validated_data[field] = data[field] ? data[field] : '';
        } else {
          validated_data[field] = data[field];
        }
      }
    }
    return validated_data;
  }

  saveFilter(state) {
    const valid_state = this._get_valid_serialize_state(state);
    localStorage.setItem('leads_search_filter', JSON.stringify(valid_state));
  }

  resetFilter() {
    localStorage.removeItem('leads_search_filter');
  }

  loadFilter() {
    if (localStorage.getItem('leads_search_filter')) {
      const data = JSON.parse(localStorage.getItem('leads_search_filter'));
      return this._get_valid_serialize_state(data);
    }
  }

  handleFilterUpdate() {
    this.saveFilter(this.state);
    this.props.onFilter(this.state);
  }

  handleSubmit() {
    this.props.setPage({ selected: 0 });
    this.saveFilter(this.state);
    this.props.onFilter(this.state);
  }

  componentDidMount() {
    this.props.onFilter(this.state);
  }

  render() {
    const WAS_TRANSFERRED_OPTIONS = [
      { value: true, label: 'Да' },
      { value: false, label: 'Нет' },
    ];
    return (
      <div className="card card--search-bg filter-container">
        <div className="card-header">
          <strong>Заявки поступившие с сайта и их статус</strong>
        </div>
        <div className="card-body">
          <form className="" action="" method="post">
            <div className="filter-row">
              <div className="filter-field filter__source">
                <Select
                  isMulti={true}
                  joinValues={true}
                  simpleValue={true}
                  onChange={this.changeSource}
                  options={sourceOptions}
                  placeholder="Источник"
                  value={this.state.source}
                />
              </div>
              <div className="filter-field date-from">
                <label htmlFor="leadDate" className="col-form-label">
                  Дата от
                </label>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.startDate}
                  selectsStart
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.changeStartDate}
                  className="d-block"
                  isClearable
                  placeholderText="Дата создания заявки ОТ"
                />
              </div>
              <div className="filter-field date-to">
                <label htmlFor="leadDate" className="col-form-label">
                  Дата до
                </label>
                <DatePicker
                  dateFormat="dd.MM.yyyy"
                  selected={this.state.endDate}
                  selectsEnd
                  startDate={this.state.startDate}
                  endDate={this.state.endDate}
                  onChange={this.changeEndDate}
                  placeholderText="Дата создания заявки ДО"
                  isClearable
                />
              </div>
              <div className="filter-field filter__type">
                <Select
                  onChange={this.changeWasTransferred}
                  options={WAS_TRANSFERRED_OPTIONS}
                  placeholder="Был перевод"
                  simpleValue={true}
                  value={this.state.was_transferred}
                />
              </div>
            </div>
            <div className="filter-row">
              <div className="filter-field departure">
                <label htmlFor="leadCity" className="col-form-label">
                  Город вылета
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="leadCity"
                  name="city"
                  placeholder="Город вылета"
                  value={this.state.city}
                  onChange={this.changeInput}
                />
              </div>
              <div className="filter-field country">
                <label
                  htmlFor="leadDestinationCountry"
                  className="col-form-label"
                >
                  Страна назначения
                </label>
                <input
                  type="text"
                  className="form-control"
                  id="leadDestinationCountry"
                  name="destination_country"
                  placeholder="Страна назначения"
                  value={this.state.destination_country}
                  onChange={this.changeInput}
                />
              </div>
              <div className="filter-field filter__type">
                <Select
                  isMulti={true}
                  joinValues={true}
                  simpleValue={true}
                  onChange={this.changeStatus}
                  options={statusOptions}
                  placeholder="Статус"
                  value={this.state.status}
                />
              </div>
              <div className="filter-field filter__type">
                <ManagersSelectContainer
                  manager_selected={this.state.managers}
                  changeManager={this.changeManagers}
                  isMulti={true}
                />
              </div>
            </div>
            <OfficeSelectContainer
              changeCity={this.changeAngecyCity}
              changeCountry={this.changeAngecyCountry}
              changeOffice={this.changeOffice}
              multi_office={true}
              selected_city={this.state.agencyCitySelected}
              selected_country={this.state.agencyCountrySelected}
              selected_office={this.state.agencySelectedOffice}
            />
          </form>
          <div className="card-footer">
            <button
              type="submit"
              className="btn btn-sm btn-primary"
              onClick={this.handleSubmit}
            >
              Найти
            </button>
            <button
              type="reset"
              className="btn btn-sm btn-danger"
              onClick={this.handleReset}
            >
              Сбросить
            </button>
          </div>
        </div>
      </div>
    );
  }
}

export default FilterLeads;
