import React from 'react';
import CalendarDay from './CalendarDay';

const CalendarTable = ({ onDayId = (f) => f, settings }) => {
  const createEvent = (e) => {
    e.preventDefault();
    return onDayId(e.target.id);
  };
  return (
    <div className="container">
      <div id="calendar-table">
        <table>
          <tbody>
            <tr className="calendar-head" style={{ cursor: 'auto' }}>
              <th>ПН</th>
              <th>ВТ</th>
              <th>СР</th>
              <th>ЧТ</th>
              <th>ПТ</th>
              <th>СБ</th>
              <th>ВС</th>
            </tr>
            {settings.data.map((row, i) => {
              return (
                <tr key={i}>
                  {row.map((event) => (
                    <CalendarDay
                      event={event}
                      createEvent={createEvent}
                      key={event.dateId}
                    />
                  ))}
                </tr>
              );
            })}
          </tbody>
        </table>
      </div>
    </div>
  );
};

export default CalendarTable;
