import React, { PureComponent } from 'react';
import ClientAPI, { StagingsAPI } from '../../../api/Client/Client';
import Select from 'react-select';

export default class ListTouristsTags extends PureComponent {
  constructor(props) {
    super(props);
    this.state = {
      tags: [],
      user_tags: [],
      tag: [],
      persons: 0,
      count_tourist: 0,
    };
  }

  fetchTags() {
    let self = this;
    ClientAPI.getJSON('/stagings/tags/').then((res) =>
      self.setState({
        user_tags: res || [],
      })
    );
  }

  handleTagsOnChange = (tags) => {
    console.log(tags);
    this.setState(
      {
        ...this.state,
        tag: tags,
        count_tourist: 0,
      },
      () => this.fetchTourists()
    );
  };

  fetchTourists = () => {
    const { tag } = this.state;
    let filter_tag = 0;
    let onlyPerson = 0;
    if (tag && tag.length) {
      filter_tag = tag.filter((t) => t.id !== 0);
    }
    if (tag.filter((t) => t.id === 0).length) {
      onlyPerson = 1;
    }
    this.props.onFilter({
      type: 'tags',
      tags: filter_tag,
      only_person: onlyPerson,
    });

    StagingsAPI.getList(1, { tag: filter_tag, only_person: onlyPerson }).then(
      (result) => this.setState({ count_tourist: result.count })
    );
  };

  componentDidMount() {
    if (this.state.info === '') {
      this.getInfo();
    }
    this.fetchTags();
    this.props.onFilter({ type: 'tags' });
  }

  render() {
    const { tags, user_tags } = this.state;
    const { isDisabled } = this.props;
    const TAGS_OPTIONS = this.state.user_tags.map((tag) => {
      return { value: tag.id, label: tag.name };
    });
    TAGS_OPTIONS.unshift({ label: 'Потенциальные клиенты', value: 0 });
    return (
      <div className="form-group row">
        <label className="text-right col-md-2 col-form-label">Метки:</label>
        <div className="col-md-8">
          <Select
            id="tagsTouristID"
            className="mb-3"
            name="tags"
            placeholder="Выберите из спиcка..."
            isMulti={true}
            closeOnSelect={true}
            value={
              this.state.tag.length
                ? this.state.tag.filter((tag) => user_tags.indexOf(tag) != 1)
                : []
            }
            getOptionLabel={(o) => o.name}
            getOptionValue={(o) => o.id}
            onChange={this.handleTagsOnChange}
            options={user_tags}
            isDisabled={isDisabled}
          />
        </div>
        <div>
          <h4 className="text-success">
            Выбрано туристов: {this.state.count_tourist}
          </h4>
        </div>
      </div>
    );
  }
}
