import React from 'react'
import {TagsAPI} from '../../api/Client'
import Paginator from '../../components/Paginator/Paginator';
import {getCurrentOfficeId} from '../../connect/auth';
import TagList from './components/TagList';
import Swal from 'sweetalert';


class Tags extends React.Component {
  constructor(props) {
    super(props);
    this.onCreate = this.onCreate.bind(this);
    this.showTagForm = this.showTagForm.bind(this);
    this.addTag = this.addTag.bind(this);
    this.removeTag = this.removeTag.bind(this);
    this.fetchTags = this.fetchTags.bind(this);
    this.handleTag = this.handleTag.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleClickIcon = this.handleClickIcon.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.state = {
      tag: "",
      tags: [],
      isShow: false,
      hasNext: false,
      hasPrev: false,
      count: 0,
      pageNumber: 1,
    };

    // пагинация
    // бесконечная прокрутка
  }
  onCreate() {
    this.props.onCreate();
  }
  fetchTags(page = 1) {
    const self = this;
    TagsAPI.getList(page).then((res) =>
      self.setState({
        ...self.state,
        tags: res.results || [],
        hasNext: res.next != null,
        hasPrev: res.previous != null,
        count: res.count,
      })
    );
  }
  showTagForm() {
    this.setState({
      isShow: true,
    });
  }
  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({
        pageNumber: page,
        isShow: false,
    },() => this.fetchTags(page));
  }
  addTag(e) {
    e.stopPropagation();
    let self = this;
    TagsAPI.create({
      name: this.state.tag,
      is_active: true,
      office: getCurrentOfficeId(),
    }).then((res) =>
      self.setState(
        {
          // tags: res.results,
          isShow: false,
        },
        self.fetchTags(this.state.pageNumber)
      )
    );
  }
  removeTag(e) {
    let self = this;
    let tagId = e.target.title;
    let title = `удалить метку с ID ${e.target.title}?`
    Swal({
        title: "Вы уверены?",
        text: title,
        icon: "error",
        buttons: true,
        dangerMode: true,
        confirmButtonText: 'Удалить',
        cancelButtonText: 'Нет',
      })
      .then((willDelete) => {
        if (willDelete) {
          TagsAPI.delete(tagId).then((res) =>
            self.setState(
              {
                isShow: false,
              },
              self.fetchTags(this.state.pageNumber)
            )
          );
          // swal("Poof! Your imaginary file has been deleted!", {
          //   icon: "success",
          // });
        } else {
          // swal("Your imaginary file is safe!");
        }
      });


  }
  handleClickIcon(e) {
    e.stopPropagation();
  }
  handleTag(e) {
    this.setState({
      // tags: res.results,
      tag: e.target.value,
    });
  }
  handleSubmit(e) {
    e.preventDefault();
  }
  componentDidMount() {
    this.fetchTags(this.state.pageNumber);
  }
  render() {
    return (
      <div>
        <h5>Придумайте метки для разных категорий своих туристов, это поможет делать  рассылку согласно запросам</h5>
        {/* <Tags >
              <TagFilter />
              <TagService>
                <Pagination>
                  <TagListView>
                    <ListView/>
                  </TagListView>
                </Pagination>
              </TagService>
            </Tags> */}
        <div className="col-sm-6 col-lg-6">
          <div className="card">
            <div className="card-header">
              <i className="fa fa-tags"></i>
              <span>Мои метки</span>
              <span className="float-right col-sm-6 col-lg-6">
                {this.state.isShow ? (
                  <form
                    className="form-horisontal"
                    onSubmit={this.handleSubmit}
                  >
                    <div className="input-group">
                      <input
                        type="text"
                        value={this.tag}
                        className="form-control"
                        onChange={this.handleTag}
                        placeholder="Введите название метки"
                      />
                      <div className="input-group-append">
                        <button
                          onClick={this.addTag}
                          className="btn btn-success"
                          style={{ marginTop: 0 }}
                        >
                          <i className="fa fa-check"></i>Добавить
                        </button>
                      </div>
                    </div>
                  </form>
                ) : (
                  <button
                    onClick={this.showTagForm}
                    className="btn btn-success float-right"
                    style={{ marginTop: 0 }}
                  >
                    <i className="fa fa-plus"></i> Новая метка
                  </button>
                )}
              </span>
            </div>
            <div className="card-body">
              <TagList tags={this.state.tags} total={this.state.count} onPageChange={this.handlePageChange} onDelete={this.removeTag} />
            </div>
            {/* <Paginator  onPageChange={this.handlePageChange} /> */}
          </div>
        </div>
      </div>
    );
  }
}

export default Tags
