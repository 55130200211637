import React, {Component} from 'react';
import Select from 'react-select'
import APIClient from '../../../api/Client';
import moment from 'moment';
import 'moment/locale/ru';

class Client extends Component {
  constructor(props) {
    super(props);

    this.handleStatusOnChange = this.handleStatusOnChange.bind(this);
  }

  handleStatusOnChange(val) {
    const {merger, client, clientAction} = this.props;
    let status = 200;
    let url;

    if (client === `person_tourist`) {
      url = `mergers`;
    } else if (client === `person`) {
      url = `person_mergers`;
    } else if (client === `tourist`) {
      url = `tourist_mergers`;
    }

    if (url) {
      APIClient.
          update(`/${url}/${merger.id}`, {is_cancelled: val}).
          then(response => {
            status = response.status;
            return response.json();
          }).
          then(result => {
            if (status === 200) {
              const timeMessage = `будет отменено в течение 5 минут`;
              let message;
              if ([`person`, `tourist`].includes(client)) {
                message = `Слияние №${merger[client].id}-№${merger.duplicate.id}: ${timeMessage}`;
              } else {
                message = `Слияние Потен. клиента №${merger.person.id} с туристом №${merger.tourist.id}: ${timeMessage}`;
              }
              clientAction({
                result: result,
                messages: [message],
                isError: false,
              });
            } else {
              clientAction({
                messages: [`Неизвестная ошибка`],
                isError: true,
              });
            }
          });
    }
  }

  render() {
    const {merger, client} = this.props;
    let client_data;
    if ([`person`, `tourist`].includes(client)) {
      client_data = merger.duplicate;
    } else {
      client_data = merger.person;
    }
    const STATUS_OPTIONS = [
      {value: false, label: `Слияние`},
      {value: true, label: `Отмена`},
    ];
    const STATUSES = {
      merge: STATUS_OPTIONS[0],
      cancel: STATUS_OPTIONS[1],
    };

    return (
        <tr key={client_data.id}>
          <td>{client === `tourist` ? client_data.fp_pk : client_data.id}</td>
          <td>{client_data.full_name}</td>
          <td>{Array.isArray(client_data.email) && client_data.email.length ? client_data.email.map(item => {
            return !item.value ? `` : item.value
          }).join(``) : null}</td>
          <td>{Array.isArray(client_data.phone) && client_data.phone.length ? client_data.phone.map(item => {
            return !item.value ? `` : item.value
          }).join(``) : null}</td>
          <td>{
            !!merger.creator
                ? `${merger.creator.first_name} ${merger.creator.last_name}`
                : ``
          }</td>
          <td>{moment(merger.created).format(`DD.MM.YY HH:mm`)}</td>
          <td>{
            !!merger.canceler
                ? `${merger.canceler.first_name} ${merger.canceler.last_name}`
                : ``
          }</td>
          <td>{
            !!merger.cancelled
                ? moment(merger.cancelled).format(`DD.MM.YY HH:mm`)
                : ``
          }</td>
          <td>
            <Select
                id={`personMergerStatus${merger.id}`}
                name="isCancelled"
                style={{width: `100px`}}
                placeholder="Выберите из спиcка..."
                closeOnSelect={true}
                simpleValue={true}
                value={merger.isCancelled ? STATUSES.cancel : STATUSES.merge}
                onChange={this.handleStatusOnChange}
                options={STATUS_OPTIONS}
                isClearable={false}
                disabled={!!merger.is_done && !!merger.is_cancelled || !merger.is_done}
            />
          </td>
          <td>{merger.is_done ? `Да` : `Нет`}</td>
        </tr>
    );
  }
}

export default Client;
