import React from 'react';
import { Link } from 'react-router-dom';

function LinkToClient({ name, id }) {
  return (
    <Link to={`/persons/${id}`} target="_blank" rel="noopener noreferrer">
      {name}
    </Link>
  );
}

export default LinkToClient;
