import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import moment from 'moment';
import React, { Component, useEffect, useState } from 'react';
import Select from 'react-select';
import {
  CardHeader,
  CardFooter,
  CardBody,
  Button,
  Row,
  Col,
  Alert,
} from 'reactstrap';
import { Link, useNavigate, useParams } from 'react-router-dom';

import BackButton from '../../components/BackButton';
import EmployeesSelector from '../Employees/components/EmployeesSelector';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';
import OfficeSelectContainer from '../../components/OfficeSelectContainer/OfficeSelectContainer';
import { getUserRole, SUPER_ADMIN } from '../../connect/auth';
import { LeadAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import {
  SOURCE_TYPE_DICT,
  STATUS_DESCRIPTION_MAP,
} from '../../views/LeadsTable';
import { LeadTextdata } from './components/LeadTextdata';

const statusOptions = Object.keys(STATUS_DESCRIPTION_MAP).map((k) => ({
  value: k,
  label: STATUS_DESCRIPTION_MAP[k],
}));

function Lead() {
  const { leadId } = useParams();

  const [leadInfo, setLeadInfo] = useState({});
  const [initialLeadInfo, setInitialLeadInfo] = useState({});
  const [isFormChanged, setFormChanged] = useState(false);
  const [isErrorMessage, setErrorMessage] = useState(false);
  const [isShowSuccessMessage, setShowSuccessMessage] = useState(false);
  const [isErrorMessages, setErrorMessages] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getInfo();
  }, []);

  function getInfo() {
    LeadAPI.getInfo(leadId).then((result) => {
      setLeadInfo(result);
      setInitialLeadInfo(result);
    });
  }

  function sortOfficesInSelect(a, b) {
    if (a.is_lead_notify !== b.is_lead_notify) {
      // Если поля "status" различны, сортируем по возрастанию (true -> false)
      return a.is_lead_notify ? -1 : 1;
    } else {
      return a.title.localeCompare(b.title);
    }
  }

  function handleChange(e) {
    const { name, value } = e.target;
    setLeadInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    onFromChanged();
  }

  function updateManager(manager) {
    setLeadInfo((prevState) => ({
      ...prevState,
      manager,
    }));
    onFromChanged();
  }

  function changeStatus(v) {
    setLeadInfo((prevState) => ({
      ...prevState,
      status: v.value,
    }));
    onFromChanged();
  }

  function changeOffice(v) {
    setLeadInfo((prevState) => ({
      ...prevState,
      office: { ...prevState.office, id: v.value },
    }));
    onFromChanged();
  }

  function changeOfficeCity(city) {
    setLeadInfo((prevState) => ({
      ...prevState,
      office: { ...prevState.office, city: city.value },
    }));
  }

  function onFromChanged() {
    setFormChanged(true);
    setErrorMessage(false);
    setShowSuccessMessage(false);
  }

  function onFormSuccess() {
    setFormChanged(false);
    setErrorMessage(false);
    setShowSuccessMessage(true);
  }

  function onFormError(err) {
    setErrorMessage(err);
    setShowSuccessMessage(false);
  }

  function getChangedValues() {
    let result = {};
    const { status, fp_number, comment, manager, office } = leadInfo;
    if (status !== initialLeadInfo.status) {
      result.status = status;
    }

    if (fp_number !== initialLeadInfo.fp_number) {
      result.fp_number = fp_number === '' ? null : fp_number;
    }

    if (comment !== initialLeadInfo.comment) {
      result.comment = comment === '' ? null : comment;
    }

    if (manager.id) {
      result.manager = manager.id;
    }

    if (initialLeadInfo.office) {
      result.office_id = initialLeadInfo.office.id;
      if (result.office_id !== office.id) {
        result.office_id = office.id;
      }
    } else {
      result.office_id = office.id;
    }
    return result;
  }

  function createIssue() {
    const data = getChangedValues();
    const timeout = 3000;

    LeadAPI.createIssue(leadId, {
      office: data.office_id,
      manager: data.manager,
      status: data.status || '',
      comment: data.comment || '',
    })
      .then((r) => r.json())
      .then((r) => {
        setLeadInfo((prevState) => ({
          ...prevState,
          issues: { ...prevState.issue, id: r.id },
        }));
        onFormSuccess();
        setTimeout(() => {
          navigate(`/persons/${r.person}`, { replace: true });
        }, timeout);
      })
      .catch((err) => onFormError(err));
  }

  function handleSave() {
    LeadAPI.modify(leadId, getChangedValues())
      .then((r) => r.status !== 201)
      .then((r) => onFormSuccess())
      .catch((err) => onFormError(err));
  }

  function handleDelete() {
    const timeout = 2000;

    LeadAPI.delete(leadId)
      .then((r) => r.json())
      .then((r) => {
        onFormSuccess();
        setTimeout(() => {
          navigate('/leads/', { replace: true });
        }, timeout);
      })
      .catch((err) => onFormError(err));
  }

  function renderErrorMessages() {
    return Object.keys(isErrorMessages).map((key) => {
      if (key === 'office') {
        return null;
      }
      return Array.isArray(isErrorMessages[key]) ? (
        isErrorMessages[key].map((item) => (
          <Alert color="warning">{item}</Alert>
        ))
      ) : (
        <Alert color="warning">{isErrorMessages[key]}</Alert>
      );
    });
  }

  if (!initialLeadInfo) {
    return null;
  }
  const userRole = getUserRole();
  const isSuperAdmin = userRole === SUPER_ADMIN;
  const {
    id,
    created,
    author,
    issues,
    utm_labels,
    office,
    city,
    comment,
    status,
    fp_number,
    destination_country,
    tour_amount,
    manager,
    source,
    tour_info,
  } = leadInfo;
  const statusValue =
    typeof status === 'string'
      ? statusOptions.find((s) => s.value === status)
      : status;
  return leadInfo ? (
    <div className="animated fadeIn">
      <Row>
        <Col md="12">
          <div className="card card-accent-primary">
            <CardHeader>
              <i className="icon-paper-plane">{''}</i>
              Заявка &#x02116; {id}
              <span className="badge badge-info float-right">
                {moment(created).format('YYYY-DD-MM, HH:mm')}
              </span>
            </CardHeader>
            <CardBody>
              {issues ? (
                <Row>
                  <label className="text-right col-md-2">Запрос:</label>
                  <p className="col-md-8">
                    <Link to={`/persons/${author.id}`}>
                      &#x02116; {issues.id}
                    </Link>
                  </p>
                </Row>
              ) : (
                ``
              )}
              <Row>
                <label className="text-right col-md-2">Источник:</label>
                <p className="col-md-8">
                  {SOURCE_TYPE_DICT[source] ? SOURCE_TYPE_DICT[source] : source}
                </p>
              </Row>
              <Row>
                <label className="text-right col-md-2">
                  {(office && <Link to={`/offices/${office.id}`}>Офис</Link>) ||
                    'Офис'}
                  :
                </label>
                <div className="col-md-10">
                  <OfficeSelectContainer
                    changeCity={changeOfficeCity}
                    changeOffice={changeOffice}
                    disabledSelect={!!issues}
                    selected_city={office?.city}
                    selected_office={office?.id}
                    sortOffices={sortOfficesInSelect}
                  />
                  <FieldErrorViewer
                    errorMessages={isErrorMessage}
                    field="office"
                  />
                </div>
              </Row>
              <Row>
                <label className="text-right col-md-2">Клиент:</label>
                <p className="col-md-8">
                  <Link to={`/persons/${author?.id}`}>
                    {author?.fio || 'ID:<' + author?.id + '>'}
                  </Link>
                </p>
              </Row>
              <Row>
                <label className="text-right col-md-2">Назначить:</label>
                <div className="col-md-8">
                  <EmployeesSelector
                    isDisabled={!!issues || !office?.id}
                    updateManager={updateManager}
                    key={office?.id}
                    managerSelected={manager?.id ? manager?.id : null}
                    officeSelected={
                      leadInfo.office?.id ? leadInfo.office?.id : office?.id
                    }
                    isMultiple={false}
                  />
                </div>
              </Row>
              <Row>
                <label className="text-right col-md-2">Перелет:</label>
                <p className="col-md-8">
                  {city} - {destination_country}
                </p>
              </Row>
              <Row>
                <label className="text-right col-md-2">Стоимость:</label>
                <p className="col-md-8">{tour_amount}</p>
              </Row>
              <Row>
                <label className="text-right col-md-2">Текст:</label>
                <LeadTextdata source={source} text={tour_info} />
              </Row>
              <Row>
                <label className="text-right col-md-2">UTM-метки:</label>
                <p className="col-md-8">
                  {utm_labels && JSON.stringify(utm_labels)}
                </p>
              </Row>

              {isSuperAdmin ? (
                <>
                  <Row>
                    <label className="text-right col-md-2 form-control-label">
                      Статус:
                    </label>
                    <div className="col-md-8">
                      <Select
                        isDisabled={!!issues}
                        isClearable={false}
                        onChange={changeStatus}
                        options={statusOptions}
                        placeholder="Источник"
                        value={statusValue}
                      />
                    </div>
                  </Row>
                  <Row>
                    <label className="text-right col-md-2 form-control-label">
                      ФП:
                    </label>
                    <div className="col-md-8">
                      <input
                        type="email"
                        id="email-input"
                        name="fp_number"
                        disabled={!!issues}
                        value={fp_number ?? ''}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Номер заявки в ФП"
                      />
                    </div>
                  </Row>
                  <Row>
                    <label className="text-right col-md-2 form-control-label">
                      Комментарий:
                    </label>
                    <div className="col-md-8">
                      <textarea
                        id="comment"
                        name="comment"
                        rows="9"
                        disabled={!!issues}
                        value={comment ?? ''}
                        onChange={handleChange}
                        className="form-control"
                        placeholder="Замечания..."
                      />
                    </div>
                  </Row>
                </>
              ) : null}

              <BackButton />
            </CardBody>
            <CardFooter>
              {manager?.id ? (
                <Button
                  size="sm"
                  color={isFormChanged ? 'success' : 'secondary'}
                  disabled={!isFormChanged}
                  onClick={createIssue}
                >
                  <i className="fa fa-dot-circle-o">{''}</i> Назначить
                </Button>
              ) : (
                <Button
                  size="sm"
                  color={isFormChanged ? 'success' : 'secondary'}
                  disabled={!isFormChanged}
                  onClick={handleSave}
                >
                  <i className="fa fa-dot-circle-o">{''}</i> Сохранить
                </Button>
              )}

              {isSuperAdmin ? (
                <Button
                  outline
                  color="danger"
                  className="float-right"
                  disabled={!!issues}
                  onClick={handleDelete}
                >
                  <i className="fa fa-gavel">{''}</i> Удалить
                </Button>
              ) : null}

              {isErrorMessage && renderErrorMessages()}
              {isShowSuccessMessage && (
                <Alert color="success">
                  <strong>
                    {issues ? `Запрос №${issues.id} создан!` : 'Успешно!'}
                  </strong>{' '}
                  {issues && 'Вы будете перенаправлены на него.'}
                </Alert>
              )}
            </CardFooter>
          </div>
        </Col>
      </Row>
    </div>
  ) : null;
}

export default Lead;
