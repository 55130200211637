import React, { PureComponent } from 'react';
import Swal from 'sweetalert';
import {
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
  Table,
} from 'reactstrap';
import { Link } from 'react-router-dom';

import {
  POSITIVE_ACTION_STATUSES,
  AbandonedCustomersAPI,
} from '../../api/Client';
import Paginator from '../../components/Paginator';
import ProgressBar from '../../components/ProgressBar';

import AbandonedCustomersFilter from './components/AbandonedCustomersFilter';
import { clearAllFilterParams } from '../../Utils/clearEmailfilter';

class AbandonedCustomers extends PureComponent {
  constructor(props) {
    super(props);

    this.deleteItems = this.deleteItems.bind(this);
    this.createMail = this.createMail.bind(this);

    this.handleFetch = this.handleFetch.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleToggleItem = this.handleToggleItem.bind(this);

    this.state = {
      count: 0,
      errors: {},
      filter: '',
      isLoading: false,
      isSuccess: false,
      items: [],
      page: 1,
      selected: [],
    };

    clearAllFilterParams();
  }

  componentDidMount() {
    const { page } = this.state;
    this.handleFetch(page);
  }

  deleteItems() {
    const { filter, count, page, selected } = this.state;
    let _count = selected.length ? selected.length : count,
      _filter = selected.length ? { id__in: selected.join() } : filter || {},
      status;

    Swal({
      title: `Вы уверены?`,
      text: `Удаляем ${_count} записей`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        AbandonedCustomersAPI.deleteItems(_filter)
          .then((r) => {
            status = r.status;
            if (status === 400) {
              return r.json();
            } else if (status === 403) {
              this.setState({
                errors: {
                  detail:
                    'У вас недостаточно прав для выполнения данного действия.',
                },
                isSuccess: false,
              });
            }
            return {};
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.destroy) {
              this.setState(
                {
                  isSuccess: true,
                  selected: [],
                  errors: null,
                },
                () => this.handleFetch(page)
              );
            } else if (status === 400) {
              this.setState({
                errors: r,
                isSuccess: false,
              });
            }
          });
      }
    });
  }

  createMail() {
    const { count, filter, selected } = this.state;
    let _count = selected.length ? selected.length : count,
      _filter = selected.length ? { id__in: selected.join() } : filter || {};
    Swal(
      `Создаем рассылку для раздела "Клиенты закрышихся агентов" на ${_count} записей`
    );
    localStorage.setItem('abandonedcustomers_filter', JSON.stringify(_filter));
    localStorage.setItem('abandonedcustomers_total', JSON.stringify(_count));
    localStorage.setItem('type', 'abandonedcustomers');
    return false;
  }

  handleFetch(page = 1) {
    this.setState(
      {
        isLoading: true,
      },
      () => {
        let status;
        let { filter } = this.state;
        AbandonedCustomersAPI.fetchList(page, filter)
          .then((r) => {
            status = r.status;
            return r.json();
          })
          .then((r) => {
            if (status === POSITIVE_ACTION_STATUSES.list) {
              this.setState({
                count: r.count,
                isLoading: false,
                items: r.results || [],
                page: page,
              });
            } else {
              this.setState({
                count: 0,
                isLoading: false,
                items: [],
                page: 1,
              });
            }
          });
      }
    );
  }

  handleFilter(filter) {
    this.setState(
      {
        filter,
      },
      () => this.handleFetch()
    );
  }

  handlePageChange(data) {
    this.handleFetch(data.selected + 1);
  }

  handleToggleItem(item) {
    let selected = [...this.state.selected];

    if (selected.includes(item.id)) {
      selected = selected.filter((pk) => pk !== item.id);
    } else {
      selected = selected.concat(item.id);
    }
    selected.sort((a, b) => {
      return b - a;
    });

    this.setState({ selected: selected });
  }

  render() {
    const { count, filter, isLoading, items, page, selected } = this.state;
    return (
      <div>
        <AbandonedCustomersFilter onFilter={this.handleFilter} />
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader>
                <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                <span className="badge badge-info">{count}</span>
                <Link
                  className="btn btn-success ml-3 mt-0"
                  onClick={this.createMail}
                  to="/templates/"
                  style={{ display: 'inline-block' }}
                >
                  <span>
                    <i className="fa fa-send mr-2">{''}</i>
                    Создать рассылку
                  </span>
                </Link>
                <div
                  className="float-right"
                  hidden={!(selected.length >= 1 || filter.office)}
                >
                  <Button
                    className="btn btn-danger mr-2"
                    onClick={this.deleteItems}
                    type="button"
                  >
                    <span>
                      <i className="fa fa-close mr-2">{''}</i>Удалить{' '}
                      {selected.length || count}
                    </span>
                  </Button>
                </div>
              </CardHeader>
              {isLoading ? (
                <ProgressBar />
              ) : (
                <CardBody className="card-body_responsive">
                  <div>
                    <Table
                      striped
                      bordered
                      size="sm"
                      className="font-xs table--orange"
                    >
                      <thead>
                        <tr>
                          <th className="text-center tableItem hidden-mobile">
                            *
                          </th>
                          <th className="text-center tableItem">ФИО</th>
                          <th className="text-center tableItem">Телефоны</th>
                          <th className="text-center tableItem">Почта</th>
                          <th className="text-center tableItem">Метки</th>
                          <th className="text-center tableItem">Офис</th>
                          <th className="text-center tableItem">Тип</th>
                        </tr>
                      </thead>
                      <tbody>
                        {items.length
                          ? items.map((item) => (
                              <tr key={item.id}>
                                <td className="text-center tableItem hidden-mobile">
                                  <input
                                    type="checkbox"
                                    onChange={() => this.handleToggleItem(item)}
                                    checked={selected.includes(item.id)}
                                  />
                                </td>
                                <td className="text-center tableItem">
                                  {item.type == 'person' ? (
                                    <Link to={`/persons/${item.related_id}`}>
                                      {item.name}
                                    </Link>
                                  ) : (
                                    <Link to={`/tourists/${item.related_id}`}>
                                      {item.name}
                                    </Link>
                                  )}
                                </td>
                                <td className="text-center tableItem">
                                  {Array.isArray(item.phone) &&
                                    item.phone
                                      .map((i) => i.value || i.origin)
                                      .join(`, `)}
                                </td>
                                <td className="text-center tableItem">
                                  {Array.isArray(item.email) &&
                                    item.email
                                      .map((i) => i.value || i.origin)
                                      .filter((i) => i)
                                      .join(`, `)}
                                </td>
                                <td className="text-center tableItem">
                                  {Array.isArray(item.tag) &&
                                    item.tag.map((tag) => (
                                      <span
                                        className="badge badge-pill badge-success ml-2"
                                        key={`item-${item.id}-tag-${tag.id}`}
                                      >
                                        {tag.name}
                                      </span>
                                    ))}
                                </td>
                                <td className="text-center tableItem">
                                  {item.office && (
                                    <span>{item.office.address}</span>
                                  )}
                                </td>
                                <td className="text-center tableItem">
                                  {item.type == 'person'
                                    ? 'Потенциальный'
                                    : 'Турист'}
                                </td>
                              </tr>
                            ))
                          : null}
                      </tbody>
                    </Table>
                  </div>
                  <Paginator
                    total={count}
                    onPageChange={this.handlePageChange}
                    forcePage={0}
                    initialPage={page - 1}
                  />
                </CardBody>
              )}
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}

export default AbandonedCustomers;
