import React, { Component } from 'react';
import { Link } from 'react-router-dom';

import Paginator from '../../components/Paginator/Paginator';
import FilterOrdersFp from '../Components/FilterOrdersFp/FilterOrdersFp';
import { OrderFpAPI } from '../../api/Client/Client';

import moment from 'moment';
import 'moment/locale/ru';
import OrderFpWorkItem from './components/OrderFpWorkItem';
import { CardBody } from 'reactstrap';
import ProgressBar from '../../components/ProgressBar';

class OrdersFpWork extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return (
      <CardBody>
        {this.props.isLoading ? (
          <ProgressBar />
        ) : (
          <>
            <ul id="dashboard__orders" className="list-group">
              {this.props.items.map((item, idx) => (
                <OrderFpWorkItem item={item} key={idx} />
              ))}
            </ul>
            <Paginator
              total={this.props.count}
              onPageChange={this.props.handlePageChange}
            />
          </>
        )}
      </CardBody>
    );
  }
}

OrdersFpWork.defaultProps = {
  filters: null,
  handleRefresh: () => null,
};

export default OrdersFpWork;
