import React, { Component } from 'react';
import 'moment/locale/ru';

export default class FieldErrorViewer extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    return Array.isArray(this.props.errorMessages[this.props.field]) &&
      this.props.errorMessages[this.props.field].length
      ? this.props.errorMessages[this.props.field].map((error, idx) => {
          return (
            <div className="invalid-feedback" key={idx}>
              {error}
            </div>
          );
        })
      : null;
  }
}
