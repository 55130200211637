import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { CashAccountsAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import Paginator from '../../components/Paginator';
import {
  Alert,
  Button,
  Card,
  CardBody,
  CardHeader,
  Col,
  Row,
} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';
import { getUserRole, SUPER_ADMIN } from '../../connect/auth';

export default class CashAccounts extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.addAccount = this.addAccount.bind(this);
    this.updateAccount = this.updateAccount.bind(this);

    this.state = {
      items: [],
      count: 0,
      errors: {},
    };
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  fetchItems(page = 1) {
    let status;
    CashAccountsAPI.fetchList(page)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            items: r.results || [],
            count: r.count || 0,
          });
        }
      });
  }

  addAccount() {
    let status;
    CashAccountsAPI.create()
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.create) {
          this.fetchItems();
        } else {
          this.setState({
            errors: r,
          });
          const errors = Object.keys(r);
          errors.forEach((key) => {
            swal(r[key].toString())
          })
        }
      });
  }

  updateAccount(id, isActive) {
    let status;
    CashAccountsAPI.update(`${CashAccountsAPI.resource_url}${id}`, {
      is_active: !isActive,
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.update) {
          this.setState((prevState) => {
            const newItems = [...prevState.items];
            const index = newItems.findIndex((item) => item.id === result.id);
            newItems[index] = result;
            return {
              items: newItems,
            };
          });
        }
      });
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {
    const { items, count, errors } = this.state;
    const userRole = getUserRole();
    const errorKeys =
      typeof errors === 'object' && !!errors ? Object.keys(errors) : [];
    return (
      <div className="animated fadeIn">
        <Row>
          <Col lg="12"></Col>
        </Row>
        <Row>
          <Col lg="12">
            <Card>
              <CardHeader className="card-header__container">
                <span className="badge badge-info">
                  <i className="fa fa-align-justify" />Всего {count}
                </span>
                <div className='card-header__btn-group'>
                  <Button onClick={this.addAccount}>
                    Добавить денежный счет
                  </Button>
                </div>
              </CardHeader>
              <CardBody className="card-body_responsive">
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>Id</th>
                      <th>Создан</th>
                      <th>Создатель</th>
                      <th>Плательщик</th>
                      <th>Обновлен</th>
                      <th>Обновивший</th>
                      <th>Агентство</th>
                      <th hidden={userRole !== SUPER_ADMIN}>Состояние</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items.map((item) => (
                      <tr key={item.id}>
                        <td>
                          <Link to={`/cashaccounts/${item.id}`}>{item.id}</Link>
                        </td>
                        <td>
                          <Link to={`/cashaccounts/${item.id}`}>
                            {moment(item.created).format('DD.MM.YY HH:mm')}
                          </Link>
                        </td>
                        <td>{`${['first_name', 'last_name']
                          .map((i) => item.creator[i])
                          .join(' ')} (${item.creator.username})`}</td>
                        <td>
                          {item.invoice &&
                            item.invoice.creator &&
                            `${['first_name', 'last_name']
                              .map((i) => item.invoice.creator[i])
                              .join(' ')}`}
                        </td>
                        <td>{moment(item.updated).format('DD.MM.YY HH:mm')}</td>
                        <td>{`${['first_name', 'last_name']
                          .map((i) => item.updater[i])
                          .join(' ')} (${item.updater.username})`}</td>
                        <td>
                          <Link to={`/agencies/${item.agency.id}`}>
                            {item.agency.public_name || 'не указано имя'}
                          </Link>
                        </td>
                        <td hidden={userRole !== SUPER_ADMIN}>
                          <Button
                            onClick={() =>
                              this.updateAccount(item.id, item.is_active)
                            }
                          >
                            {item.is_active ? `Отключить` : `Подключить`}
                          </Button>
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
                <Paginator total={count} onPageChange={this.handlePageChange} />
              </CardBody>
            </Card>
          </Col>
        </Row>
      </div>
    );
  }
}
