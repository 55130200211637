import React, { useEffect, useState } from 'react';
import { Card, CardBody, CardHeader, Col, Row } from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';
import { InvoicesAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import Paginator from '../../components/Paginator';
import BackButton from '../../components/BackButton';
import { useParams } from 'react-router-dom';

function Invoice() {
  const { invoiceId } = useParams();

  const [data, setData] = useState({});
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);

  useEffect(() => {
    fetchData();
    fetchItems();
  });

  function fetchData() {
    let status;

    InvoicesAPI.fetchJSON(`${invoiceId}/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          setData(r);
        }
      });
  }

  function fetchItems(page = 1) {
    let status;

    InvoicesAPI.fetchList(1, {}, `${invoiceId}/payments`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          setItems(r.results);
          setCount(r.count);
        }
      });
  }

  function handlePageChange(data) {
    let page = data.selected + 1;
    setPage(page);
    fetchItems(page);
  }

  return (
    <div className="animated fadeIn">
      {typeof data === 'object' && data !== null && Object.keys(data).length ? (
        <Row>
          <Col lg="12">
            <h2>Cчет #{data.id}</h2>
            <p>Создан: {moment(data.created).format('DD.MM.YY HH:mm')}</p>
            <p>
              Создатель:
              {`${['first_name', 'last_name']
                .map((i) => data.creator[i])
                .join(' ')} (${data.creator.username})`}
            </p>
            <p>Обновлен: {moment(data.created).format('DD.MM.YY HH:mm')}</p>
            <p>Статус: {data.status.name}</p>
            {!!data.link ? (
              <p>
                <a href={data.link} rel={`nofollow noopener noreferrer`}>
                  Ссылка на оплату
                </a>
              </p>
            ) : null}
          </Col>
        </Row>
      ) : null}
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify">{}</i> Всего оплат&nbsp;
              <span className="badge badge-info">{count}</span>
            </CardHeader>
            <CardBody>
              <table className="table table-bordered table-striped table-sm">
                <thead>
                  <tr>
                    <th>Id</th>
                    <th>Попала в CRM</th>
                    <th>Сумма</th>
                    <th>Валюта</th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((item) => (
                    <tr key={`payment-${item.id}`}>
                      <td>{item.id}</td>
                      <td>{moment(item.created).format('DD.MM.YY HH:mm')}</td>
                      <td>{item.value}</td>
                      <td>{item.currency.name}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
              <Paginator total={count} onPageChange={handlePageChange} />
            </CardBody>
          </Card>
        </Col>
      </Row>
      <BackButton />
    </div>
  );
}

export default Invoice;
