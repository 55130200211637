import React from 'react';

import ListTouristsAll from './ListTouristsAll';
import ListTouristsTags from './ListTouristsTags';
import ListTouristsPersonal from './ListTouristsPersonal';
import ListCabinet from './ListCabinet';

export default class FilterTourists extends React.PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      recipent: 'none',
    };
  }
  handleFilter = (filter) => {
    this.props.onFilter(filter);
  };
  recipientSwitch = (e) => this.setState({ recipent: e.target.value });

  render() {
    const { isDisabled } = this.props;
    return (
      <div className="card">
        <div className="card-header col-md-12 templates__radio-container">
          <label className="templates__option-container">
            <input
              type="radio"
              name="recipient"
              value="all"
              onChange={this.recipientSwitch}
              disabled={isDisabled}
            />
            <span className="templates__option-title">
              <i className="fa fa-users mr-2 text-info"></i>Все туристы
            </span>
          </label>
          <label className="templates__option-container">
            <input
              type="radio"
              name="recipient"
              value="tags"
              onChange={this.recipientSwitch}
              disabled={isDisabled}
            />
            <span className="templates__option-title">
              <i className="fa fa-tags mr-2 text-success"></i>По меткам
            </span>
          </label>
          <label className="templates__option-container">
            <input
              type="radio"
              name="recipient"
              value="cabinet"
              onChange={this.recipientSwitch}
              disabled={isDisabled}
            />
            <span className="templates__option-title">
              <i className="fa fa-users mr-2 text-success"></i>Кабинет туриста
            </span>
          </label>
          <label className="templates__option-container">
            <input
              type="radio"
              name="recipient"
              value="personal"
              onChange={this.recipientSwitch}
            />
            <span className="templates__option-title">
              <i className="fa fa-user mr-2 text-warning"></i>Выбрать туриста
            </span>
          </label>
        </div>
        <div className="card-body p-4">
          {this.state.recipent === 'all' && (
            <ListTouristsAll onFilter={this.handleFilter} />
          )}
          {this.state.recipent === 'tags' && (
            <ListTouristsTags
              onFilter={this.handleFilter}
              isDisabled={isDisabled}
            />
          )}
          {this.state.recipent === 'cabinet' && (
            <ListCabinet onFilter={this.handleFilter} isDisabled={isDisabled} />
          )}
          {this.state.recipent === 'none' && (
            <h4 className="text-success">Получатели не выбраны</h4>
          )}
          {this.state.recipent === 'personal' && (
            <ListTouristsPersonal onFilter={this.handleFilter} />
          )}
        </div>
      </div>
    );
  }
}
