import React from 'react'
import Paginator from '../components/Paginator/Paginator';
function paginate(Component) {
  class PaginateWrap extends React.Component {
    constructor(props) {
      super(props);
      this.handlePageChange = this.handlePageChange.bind(this);
      this.state = {
        currentPage: 1,
      }
    }
    handlePageChange(data) {
      let page = data.selected + 1;
      this.setState({
        currentPage: page,
        isShow: false,
      }, () => this.props.onPageChange(page));
    }
    render() {
      return (
        <div>
          <Component {...this.props} />
          <Paginator total={this.props.total} {...this.props} />
        </div>
      )
    }
  }
  PaginateWrap.displayName = `PaginateWrap(${Component.displayName || Component.Name || 'Component'})`;
  return PaginateWrap;
}

export default paginate;
