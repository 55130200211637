import React, { Component } from 'react';

import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import './style.css';
import ru from 'react-phone-input-2/lang/ru.json';

const EditInputPhone = ({ phones, onChange, errors }) => (
  <>
    {phones ? (
      <div className="col-md-10">
        {phones.map((phone, idx) => (
          <div className="row mb-2" key={idx}>
            <div className="col-md-8 col-sm-12">
              <PhoneInput
                className="mb-2"
                country={'ru'}
                localization={ru}
                onlyCountries={['ru', 'kz', 'by']}
                countryCodeEditable={false}
                value={phone.value}
                onChange={(value) => {
                  const newPhones = [...phones];
                  newPhones[idx].value = '+' + value;
                  onChange(newPhones);
                }}
              />
            </div>
            <div className="col-md-2">
              <button
                onClick={() => {
                  const newPhones = [...phones];
                  let i = newPhones.indexOf(phone);
                  newPhones.splice(i, 1);
                  onChange(newPhones);
                }}
                className="btn btn-primary"
              >
                <i className="fa fa-remove"></i>
              </button>
            </div>
            {Array.isArray(errors) && errors[idx] && !!errors[idx].value ? (
              <p>{errors[idx].value}</p>
            ) : null}
          </div>
        ))}
        <button
          className="btn btn-success mt-2"
          onClick={() => onChange([...phones, { value: '' }])}
        >
          добавить телефон
        </button>
      </div>
    ) : null}
  </>
);
export default EditInputPhone;
