import React, { Component, useEffect, useState } from 'react';
import BackButton from '../../components/BackButton';
import LeadsTable from '../../views/LeadsTable';
import { PersonAPI, IssuesAPI, LeadAPI } from '../../api/Client';
import { Card, CardHeader, CardBody, CardTitle } from 'reactstrap';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import Issues from '../Issues/Issues';
import { Link, useNavigate, useParams } from 'react-router-dom';
import Mergers from '../Mergers/Mergers';

function Person() {
  const { personId } = useParams();
  const [personInfo, setPersonInfo] = useState({});
  const [items, setItems] = useState([]);
  const [count, setCount] = useState(0);
  const [issues, setIssues] = useState([]);
  const [activeTab, setActiveTab] = useState('1');
  const [pageNumber, setPageNumber] = useState(1);

  const navigate = useNavigate();

  useEffect(() => {
    PersonAPI.getInfo(personId).then((result) => {
      setPersonInfo(result);
    });
    fetchItems();
    getIssues();
  }, []);

  // Клиент

  function fetchItems(page = 1) {
    // Заявки клиента
    LeadAPI.fetchList(page, { author: personId })
      .then((r) => r.json())
      .then((result) => {
        const { results = [], count = 0 } = result;
        setItems(results);
        setCount(count);
      });
  }

  function getIssues(page = 1) {
    IssuesAPI.getList(page, { person: personId }).then((result) => {
      setIssues(result.results);
    });
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function handlePageChange(data) {
    let page = data.selected + 1;
    setPageNumber(page);
    fetchItems(page);
  }

  function updateIssues() {
    getIssues();
  }

  function onChangeActivity() {
    return navigate('/issues', { replace: true });
  }

  const { last_name, name, phone, middle_name, email, born, tag } = personInfo;
  return (
    <div className="animated fadeIn filter filter--green">
      <Card>
        <CardHeader>
          <CardTitle tag="h4">
            {last_name} {name} {middle_name}
          </CardTitle>
        </CardHeader>
        <CardBody>
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <p>
                Телефоны:{' '}
                <b>
                  {Array.isArray(phone) &&
                    phone.map((i, idx) => (
                      <Link key={idx} to={`tel://${i.value}`}>
                        {i.value}
                      </Link>
                    ))}
                </b>
              </p>

              <p>
                Email:{' '}
                <b>
                  {Array.isArray(email) && email.length
                    ? email
                        .filter((x) => x.value)
                        .map((x) => x.value)
                        .join(', ')
                    : null}
                </b>
              </p>

              <p>
                Дата рождения: <b>{born}</b>
              </p>
              <p>
                Метки:
                {Array.isArray(tag) &&
                  tag.map((t, idx) => (
                    <span
                      className="badge badge-pill badge-success ml-2"
                      key={idx}
                    >
                      {t.name}
                    </span>
                  ))}
              </p>
              <Link to={`/persons/${personId}/edit/`}>Отредактировать</Link>
            </div>
          </div>
          <Nav tabs>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '1',
                })}
                onClick={() => {
                  toggleTab('1');
                }}
              >
                <i className="fa fa-list"></i> Запросы
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '2',
                })}
                onClick={() => {
                  toggleTab('2');
                }}
              >
                <i className="fa fa-plane"></i> Заявки
              </NavLink>
            </NavItem>
            <NavItem>
              <NavLink
                className={classnames({
                  active: activeTab === '3',
                })}
                onClick={() => {
                  toggleTab('3');
                }}
              >
                <i className="fa fa-tasks"></i> Слияния
              </NavLink>
            </NavItem>
          </Nav>
          <TabContent className="" activeTab={activeTab}>
            <TabPane tabId="1">
              {personInfo ? (
                <Issues
                  tourist={personId}
                  type="person"
                  info={personInfo}
                  issues={issues || []}
                  onCreate={updateIssues}
                  onChangeActivity={onChangeActivity}
                />
              ) : null}
            </TabPane>
            <TabPane tabId="2">
              <LeadsTable
                count={count}
                items={items}
                handleRefresh={fetchItems}
                handlePageChange={handlePageChange}
                isShowActions={false}
                filters={null}
                handleDeleteLeads={() => ''}
                toggleItem={() => ''}
                errors={null}
                isSuccess={false}
                selected={[]}
                initialPage={pageNumber - 1}
                forcePage={pageNumber - 1}
              />
            </TabPane>
            <TabPane tabId="3">
              <Mergers client_id={personId} client={'person'} />
            </TabPane>
          </TabContent>
          <BackButton />
        </CardBody>
      </Card>
    </div>
  );
}

export default Person;
