import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import { Breadcrumb, BreadcrumbItem } from 'reactstrap';
import routes from '../../../src/__routes.js';

const findRouteName = (url) => routes[url];

const getPaths = (pathname) => {
  const paths = ['/'];

  if (pathname === '/') return paths;

  pathname.split('/').reduce((prev, curr, index) => {
    const currPath = `${prev}/${curr}`;
    paths.push(currPath);
    return currPath;
  });
  return paths;
};

const BreadcrumbItems = ({ path }) => {
  const routeName = findRouteName(path);
  if (routeName) {
    return path.isExact ? (
      <BreadcrumbItem active>{routeName}</BreadcrumbItem>
    ) : (
      <BreadcrumbItem>
        <Link to={path || ''}>{routeName}</Link>
      </BreadcrumbItem>
    );
  }
  return null;
};

const Breadcrumbs = () => {
  const { pathname } = useLocation();
  const paths = getPaths(pathname);
  const items = paths.map((path, i) => (
    <BreadcrumbItems path={path} key={i++} />
  ));
  return <Breadcrumb>{items}</Breadcrumb>;
};

export default Breadcrumbs;
