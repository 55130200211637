import React, { useEffect, useState } from 'react';
import { Alert, Card, CardBody, CardHeader } from 'reactstrap';
import { Action } from './Action';
import { APPLICANT_ACTION_STRUCTURE } from './Applicant';
import { CITY_ACTION_STRUCTURE } from './City';
import { OFFICE_ACTION_STRUCTURE } from './Office';
import { COMMENT_ACTION_STRUCTURE } from './Comment';
import {
  AgenciesAPI,
  FranchiseApplicationAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';
import BackButton from '../../components/BackButton';
import OfficeSelectContainer from '../../components/OfficeSelectContainer';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';
import Select from 'react-select';
import { useParams } from 'react-router-dom';
import {
  FRANCHISE_MANAGER_OPTIONS,
  FRANCHISE_SOURCE_OPTIONS,
} from './constants';
import ProgressBar from '../../components/ProgressBar';

function FranchiseApplicationAction({ onSuccess = null, isCreation = false }) {
  const { applicationId } = useParams();

  const [applicationInfo, setApplicationInfo] = useState({
    applicant: {
      additional_information: '',
      age: 0,
      bonuses: '',
      business_experience: '',
      communicator: '',
      contract: '',
      contract_date: '',
      countries: '',
      country: {},
      email: '',
      fp_registration: '',
      have_tourism_experience: null,
      legal_entity: null,
      name: '',
      phone: '',
      rating: '',
      site_publication: '',
      training: '',
    },
    office: {
      address: 'не задан',
      affiliation: null,
      is_separate_entrance: false,
      is_possibility_of_placing_signage: false,
      area: null,
      city: {
        name: 'не задан',
        population: null,
      },
    },
    agency: null,
    agency_office: {},
    source: '',
    manager: '',
    comment: { comment: '', id: '' },
  });
  const [agencies, setAgencies] = useState([]);
  const [errors, setErrors] = useState({});
  const [success, setSuccess] = useState({});

  const [isLoading, setIsLoading] = useState(false);
  const [isAgenciesLoading, setAgenciesLoading] = useState(false);

  useEffect(() => {
    if (!isCreation) {
      fetchAgencies();
      getApplicationInfo();
    }
  }, []);

  function getApplicationInfo() {
    setIsLoading(true);
    let resStatus;
    FranchiseApplicationAPI.fetchJSON(`${applicationId}/`)
      .then((r) => {
        resStatus = r.status;
        return r.json();
      })
      .then((r) => {
        if (resStatus === POSITIVE_ACTION_STATUSES.retrieve) {
          setApplicationInfo(r);
          setIsLoading(false);
        }
      });
  }

  function fetchAgencies() {
    setAgenciesLoading(true);
    let status;
    AgenciesAPI.fetchList(1, {}, '/full_list')
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          setAgencies(r);
          setAgenciesLoading(false);
        }
      });
  }

  function handleData() {
    let data = { applicant: {}, office: {} };
    const {
      applicant = {},
      source,
      manager,
      office,
      agency_office,
      comment,
    } = applicationInfo;

    if (applicant.additional_information !== '') {
      data.applicant.additional_information = applicant.additional_information;
    }
    if (agency_office.id) {
      data.agency_office = agency_office.id;
    }
    if (applicant.age !== 0) {
      data.applicant.age = applicant.age;
    }
    if (applicant.bonuses !== '') {
      data.applicant.bonuses = applicant.bonuses;
    }
    if (applicant.business_experience !== '') {
      data.applicant.business_experience = applicant.business_experience;
    }
    if (applicant.communicator !== '') {
      data.applicant.communicator = applicant.communicator;
    }
    if (applicant.contract !== '') {
      data.applicant.contract = applicant.contract;
    }
    if (applicant.contract_date) {
      data.applicant.contract_date = applicant.contract_date;
    }
    if (applicant.countries !== '') {
      data.applicant.countries = applicant.countries;
    }
    if (applicant.country !== '') {
      data.applicant.country = applicant.country.value;
    }
    if (applicant.email !== '') {
      data.applicant.email = applicant.email;
    }
    if (applicant.fp_registration !== '') {
      data.applicant.fp_registration = applicant.fp_registration;
    }
    if (applicant.have_tourism_experience !== null) {
      data.applicant.have_tourism_experience =
        applicant.have_tourism_experience.value;
    }
    if (applicant.legal_entity !== null) {
      data.applicant.legal_entity = applicant.legal_entity.value;
    }
    if (applicant.name !== '') {
      data.applicant.name = applicant.name;
    }
    if (applicant.phone !== '') {
      data.applicant.phone = applicant.phone;
    }
    if (applicant.rating !== '') {
      data.applicant.rating = applicant.rating;
    }
    if (applicant.site_publication !== '') {
      data.applicant.site_publication = applicant.site_publication;
    }
    if (applicant.training !== '') {
      data.applicant.training = applicant.training;
    }
    if (manager) {
      data.manager = manager.value;
    }
    if (office.address !== 'не задан') {
      data.office.address = office.address;
    }
    if (office.affiliation !== null) {
      data.office.affiliation = office.affiliation.value;
    }
    if (office.area !== null) {
      data.office.area = office.area;
    }
    if (office.is_possibility_of_placing_signage !== false) {
      data.office.is_possibility_of_placing_signage =
        office.is_possibility_of_placing_signage.value;
    }
    if (office.is_separate_entrance !== false) {
      data.office.is_separate_entrance = office.is_separate_entrance.value;
    }
    if (office.city.name !== 'не задан') {
      data.office.city = {};
      data.office.city.name = office.city.name;
    } else {
      data.office.city = {};
      data.office.city.name = 'не задан';
    }
    if (office.city?.population !== null) {
      data.office.city.population = office.city.population.value;
    }
    if (source !== '') {
      data.source = source.value;
    }
    if (comment.comment !== '') {
      data.comment = comment.comment;
    }

    return data;
  }

  function createFranchiseApplication() {
    let status;
    const data = handleData();
    const { applicant, source, manager, office, comment } = data;
    FranchiseApplicationAPI.create({
      applicant,
      source,
      manager,
      office,
      comment,
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.create) {
          setApplicationInfo(r);
          setErrors({});
          if (typeof onSuccess === 'function') {
            onSuccess();
          }
        } else {
          setErrors({
            applicant: r.applicant || {},
            city: r.office ? r.office.city : {},
            office: r.office || {},
            source: r.source || {},
          });
        }
      });
  }

  function updateItem(name, data) {
    let status;
    FranchiseApplicationAPI.update(
      `${FranchiseApplicationAPI.resource_url}${data.id}/${name}`,
      data
    )
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          setApplicationInfo((prevState) => ({
            ...prevState,
            [name]: data,
          }));
          setErrors({});
          setSuccess((prevState) => ({
            prevState,
            [name]: true,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: r,
          }));
          setSuccess({});
        }
      });
  }

  function updateOfficeAgency(name, data) {
    let status;
    FranchiseApplicationAPI.update(
      `${FranchiseApplicationAPI.resource_url}${applicationId}`,
      data
    )
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          setApplicationInfo(r);
          setErrors({});
          setSuccess((prevState) => ({
            prevState,
            [name]: true,
          }));
        } else {
          setErrors((prevState) => ({
            ...prevState,
            [name]: r,
          }));
          setSuccess({});
        }
      });
  }

  function onChange(name, data) {
    setApplicationInfo((prevState) => ({
      ...prevState,
      [name]: data,
    }));
  }
  const {
    applicant,
    office,
    agency,
    agency_office,
    source,
    manager,
    comment,
    agency_office_city,
  } = applicationInfo;

  return isLoading ? (
    <ProgressBar />
  ) : (
    <div className="animated fadeIn">
      {!isCreation ? (
        <>
          <Card>
            <CardHeader color="info">Агентство из ФСУ</CardHeader>
            <CardBody>
              <div className="form-group row">
                <label
                  className="col-md-3 col-form-label"
                  htmlFor="input-agency"
                >
                  Агентство
                </label>
                <div className="col-md-9">
                  <Select
                    id="input-agency"
                    onChange={(data) => onChange('agency', data)}
                    options={agencies}
                    name="agency"
                    value={agency}
                    placeholder="ИП Иванов"
                    getOptionValue={(option) => option.id}
                    getOptionLabel={(option) =>
                      `${option.public_name} (ФСУ id ${option.fp_pk})`
                    }
                    isLoading={isAgenciesLoading}
                    isClearable={true}
                  />
                  <FieldErrorViewer errorMessages={errors} field="agency" />
                </div>
              </div>
              <button
                className="btn btn-success mb-2"
                type="button"
                onClick={() => updateOfficeAgency('agency', { agency })}
              >
                Обновить
              </button>
              {!!success.agency ? <Alert color="success">Успешно</Alert> : null}
            </CardBody>
          </Card>
          <Card>
            <CardHeader color="info">Офис с сайта</CardHeader>
            <CardBody>
              <div className="form-group row">
                <label className="col-md-3 col-form-label">Офис</label>
                <div className="col-md-9">
                  <OfficeSelectContainer
                    selected_city={agency_office_city}
                    selected_office={agency_office}
                    changeCity={(data) => onChange('agency_office_city', data)}
                    changeOffice={(data) => onChange('agency_office', data)}
                    multi_office={false}
                  />
                  <FieldErrorViewer
                    errorMessages={errors}
                    field="agency_office"
                  />
                </div>
              </div>
              <button
                className="btn btn-success mb-2"
                type="button"
                onClick={() =>
                  updateOfficeAgency(
                    'agency_office',
                    {
                      agency_office:
                        agency_office !== null ? agency_office.id : null,
                    },
                    true
                  )
                }
              >
                Обновить
              </button>
              {!!success.agency_office ? (
                <Alert color="success mb-2">Успешно</Alert>
              ) : null}
            </CardBody>
          </Card>
        </>
      ) : null}

      {[
        {
          name: 'applicant',
          data: applicant,
          structure: APPLICANT_ACTION_STRUCTURE,
          lang: 'Заявитель',
        },
        {
          name: 'city',
          data: office.city,
          structure: CITY_ACTION_STRUCTURE,
          lang: 'Город',
        },
        {
          name: 'comment',
          data: comment,
          structure: COMMENT_ACTION_STRUCTURE,
          lang: 'Примечание',
        },
        {
          name: 'office',
          data: office,
          structure: OFFICE_ACTION_STRUCTURE,
          lang: 'Офис',
        },
      ].map((item, idx) => (
        <Card key={idx}>
          <CardHeader color="info">{item.lang}</CardHeader>
          {Array.isArray(errors[item.name]) ? (
            <div className="invalid-feedback">{errors[item.name][0]}</div>
          ) : (
            ''
          )}
          <CardBody>
            <Action
              data={item.data}
              structureType={item.name}
              errors={errors[item.name]}
              isSuccess={success[item.name]}
              isCreation={isCreation}
              id={`form_action_${item.name}`}
              franchiseApplicationId={applicationId}
              structure={item.structure}
              actions={{
                onChangeSelect: (newData) => onChange(item.name, newData),
                onChangeInput: (newData) => onChange(item.name, newData),
                onUpdateData: (data) => updateItem(item.name, data),
              }}
            />
          </CardBody>
        </Card>
      ))}
      <Card>
        <CardHeader color="info">Источник</CardHeader>
        <CardBody>
          <Select
            id="source"
            isMulti={false}
            onChange={(val) => onChange('source', val)}
            options={FRANCHISE_SOURCE_OPTIONS}
            placeholder="Источник"
            name="source"
            value={source}
            menuPortalTarget={document.body}
          />
          <FieldErrorViewer errorMessages={errors} field="source" />
        </CardBody>
      </Card>
      <Card>
        <CardHeader color="info">Менеджер</CardHeader>
        <CardBody>
          <Select
            id="manager"
            isMulti={false}
            onChange={(val) => onChange('manager', val)}
            options={FRANCHISE_MANAGER_OPTIONS}
            placeholder="Менеджер"
            name="manager"
            getOptionLabel={(o) =>
              o.last_name ? `${o.last_name} ${o.first_name}` : o.label
            }
            getOptionValue={(o) => (o.id ? o.id : o.value)}
            value={manager}
            className="mb-5"
          />
        </CardBody>
      </Card>

      {!isCreation ? (
        <div className="m-4">
          <BackButton />
        </div>
      ) : (
        <div className="row">
          <button
            className="btn btn-success m-4"
            onClick={createFranchiseApplication}
            type="submit"
          >
            Создать
          </button>
        </div>
      )}
    </div>
  );
}

export default FranchiseApplicationAction;
