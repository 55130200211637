import React from 'react';
import { useNavigate } from 'react-router-dom';

const BackButton = () => {
  const navigate = useNavigate();

  return (
    <button
      type="button"
      className="btn btn-outline-secondary"
      onClick={() => navigate(-1)}
    >
      <i className="icon-arrow-left"></i>
      &nbsp;Назад
    </button>
  );
};

export default BackButton;
