import 'moment/locale/ru';
import moment from 'moment';
import React from 'react';
import { Link } from 'react-router-dom';

import { Table, Row, Col, Card, CardHeader, CardBody } from 'reactstrap';
import Paginator from '../../../components/Paginator';
import LinkToClient from '../../Person/components/LinkToClient';

export default class QuotesTable extends React.PureComponent {
  render() {
    const { items, count, offices_count, forcePage, handlePageChange } =
      this.props;
    return (
      <Row>
        <Col lg="12">
          <Card>
            <CardHeader>
              <i className="fa fa-align-justify hidden-mobile">{''}</i>{' '}
              Всего&nbsp;
              <span className="badge badge-info">{count}</span>
              {offices_count != null && (
                <span>
                  &nbsp;&nbsp;&nbsp;Офисов&nbsp;
                  <span className="badge badge-info">{offices_count}</span>
                </span>
              )}
            </CardHeader>
            <CardBody className="card-body_responsive">
              <Table
                striped
                bordered
                size="sm"
                className="font-xs table--orange"
              >
                <thead>
                  <tr>
                    <th className="text-center tableItem">Название</th>
                    <th className="text-center tableItem">Дата</th>
                    <th className="text-center tableItem">Клиент</th>
                    <th className="text-center tableItem">Просмотры</th>
                    <th className="text-center tableItem">Клики</th>
                    <th className="text-center tableItem">Лайки</th>
                    <th className="text-center tableItem">Менеджер</th>
                    <th className="text-center tableItem">Офис</th>
                    <th className="text-center tableItem" colSpan="2">
                      Количество туров
                    </th>
                    <th className="text-center tableItem" colSpan="2">
                      Ссылка
                    </th>
                    <th className="text-center tableItem" colSpan="2">
                      Скрыта на сайте
                    </th>
                    <th className="text-center tableItem" colSpan="2">
                      Скрыть на сайте через 2 дня
                    </th>
                    <th className="text-center tableItem" colSpan="2">
                      Телеграм-уведомления
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {items.map((quote) => (
                    <tr key={quote.quote_id}>
                      <td className="text-center">
                        <Link to={`/quotes/${quote.quote_id}`}>
                          {quote.title} {quote.title ? <br></br> : null}{' '}
                          {quote.quote_id}
                        </Link>
                      </td>
                      <td className="text-center">
                        {moment(quote.created_at).format('DD.MM.YY')}
                        <br />
                        {moment(quote.created_at).format('HH:mm')}
                      </td>
                      <td className="text-center">
                        {quote.persons.length
                          ? quote.persons.map((person) => (
                              <span key={person.id}>
                                <LinkToClient
                                  name={person.fio}
                                  id={person.id}
                                />
                                <br />
                              </span>
                            ))
                          : null}
                        {quote.touristsfp.length
                          ? quote.touristsfp.map((tourist) => (
                              <span key={tourist.id}>
                                <LinkToClient
                                  name={tourist.full_name}
                                  id={tourist.id}
                                />
                                <br />
                              </span>
                            ))
                          : null}
                        {!quote.persons.length && !quote.touristsfp.length
                          ? '-'
                          : null}
                      </td>
                      <td className="text-center">{quote.stat_views_count}</td>
                      <td className="text-center">{quote.stat_clicks_count}</td>
                      <td className="text-center">{quote.stat_likes_count}</td>
                      <td className="text-center">
                        {quote.author.first_name} {quote.author.last_name}
                        {quote.author_type == 'franch' && (
                          <span>
                            <br />
                            (франчайзи)
                          </span>
                        )}
                        {quote.author_type == 'freelancer' && (
                          <span>
                            <br />
                            (фрилансер)
                          </span>
                        )}
                        {quote.author_type == 'subagent' && (
                          <span>
                            <br />
                            (субагент)
                          </span>
                        )}
                      </td>
                      <td className="text-center">
                        {quote.office ? (
                          <Link to={`/agencies/${quote.office.agency_id}`}>
                            {quote.office.title}
                          </Link>
                        ) : null}
                      </td>
                      <td className="text-center" colSpan="2">
                        <b>{quote.tours_count}</b>
                      </td>
                      <td className="text-center" colSpan="2">
                        <a
                          href={`https://www.geograftour.com/geoclient/${quote.quote_id}/`}
                          target="_blank"
                          rel="noopener noreferrer"
                        >
                          перейти на сайт
                        </a>
                      </td>
                      <td className="text-center" colSpan="2">
                        {quote.is_active_on_site ? 'Нет' : 'Да'}
                      </td>
                      <td className="text-center" colSpan="2">
                        {quote.hide_after_days_2
                          ? moment(quote.hide_after_days_2).format('DD.MM.YY')
                          : 'Нет'}
                      </td>
                      <td className="text-center" colSpan="2">
                        {quote.notify_author_by_telegram ? 'Да' : 'Нет'}
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Table>
              <Paginator
                total={count}
                onPageChange={handlePageChange}
                forcePage={forcePage}
              />
            </CardBody>
          </Card>
        </Col>
      </Row>
    );
  }
}
