import 'moment/locale/ru';
import moment from 'moment';
import React, { useEffect, useRef, useState } from 'react';
import { Alert } from 'reactstrap';
import { Link, useLocation, useParams } from 'react-router-dom';

import BackButton from '../../../../components/BackButton';
import Paginator from '../../../../components/Paginator';
import { DJANGO_BASE_DATE_FORMAT } from '../Calendar';
import { EventsAPI, POSITIVE_ACTION_STATUSES } from '../../../../api/Client';
import { getUserInfo } from '../../../../connect/auth';
import { ISSUE_STATUS_ICONS } from '../../../Issues/IssuesPage';
import { ISSUE_STATUSES_LANG } from '../../../Issues/components/IssueItem';
import {
  ARRIVED_FROM_REST,
  BIRTHDAY,
  DEPARTURE_TOMORROW,
  PASSPORT_EXPIRES,
  PAY_EXPIRES,
  VISA_APPLICATION_DEADLINE,
} from '../../../Notify/components/NotifyType';

import { Notifications } from './Notifications';

const PER_PAGE = 5;
const TIMEOUT = 2000;

const CalendarDetail = (props) => {
  const { day } = useParams();
  const { hash } = useLocation();
  const dateLabel = moment(day).format('DD MMMM YYYY');
  const [events, setEvents] = useState([]);
  const [count, setCount] = useState(0);
  const [page, setPage] = useState(1);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);

  useEffect(() => {
    fetchItems();
  }, []);

  function fetchItems(page = 1) {
    let status;

    EventsAPI.fetchList(page, {
      day: moment(day).format(DJANGO_BASE_DATE_FORMAT),
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          setPage(page);
          setEvents(r.results);
          scroll();
          setCount(r.count);
          setError(false);
          setSuccess(false);
        }
      })
      .catch((err) => {
        console.log(err);
        setError(true);
      });
  }

  function handleEventRemove(eventId) {
    let status;

    EventsAPI.delete(eventId)
      .then((r) => {
        status = r.status;
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          return {};
        }
        return r.json();
      })
      .then((r) => {
        setError(status !== POSITIVE_ACTION_STATUSES.destroy);
        setSuccess(status === POSITIVE_ACTION_STATUSES.destroy);

        setTimeout(() => {
          if (status === POSITIVE_ACTION_STATUSES.destroy) {
            fetchItems(1);
          }
        }, TIMEOUT);
      });
  }

  function handleEventFinish(eventId) {
    let status;

    EventsAPI.update(`${EventsAPI.resource_url}${eventId}`, { done: true })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          setError(false);
          setSuccess(true);

          setTimeout(() => {
            setSuccess(false);
            fetchItems(1);
          }, TIMEOUT);
        }
      });
  }

  function handlePageChange(data) {
    this.fetchItems(data.selected + 1);
  }

  function scroll() {
    const event = document.getElementById(hash);

    if (event) {
      event.scrollIntoView({ behavior: 'smooth', block: 'center' });
      event.style.background = '#b9e6f1';
      setTimeout(function () {
        event.style.removeProperty('background-color');
      }, 2000);
    }
  }

  const userId = getUserInfo().user_id;

  return (
    <div className="container">
      <div id="calendar-table">
        <h3>{dateLabel}</h3>

        <ul className="event__list">
          {events.map((event) => {
            let { start_time, end_time, notes, issue, done, comment_type } =
              event;
            let client = null;
            let link = null;
            if (issue) {
              if (issue.tourist) {
                client = issue.tourist;
                link = `tourists`;
              } else if (issue.person) {
                client = issue.person;
                link = `persons`;
              }
            }
            [start_time, end_time] = [
              ...[start_time, end_time].map((item) => {
                if (typeof item === 'string') {
                  return item.split(':').slice(0, 2).join(':');
                }
                return item;
              }),
            ];

            return (
              <li
                className={'event ' + done}
                id={`#${event.id}`}
                key={event.id}
              >
                <p className="event__start">
                  {start_time} - {end_time}
                </p>
                <p className="event__notes">{notes}</p>

                {comment_type &&
                  ISSUE_STATUS_ICONS[comment_type] &&
                  ISSUE_STATUSES_LANG[comment_type] && (
                    <p>
                      <span className="event__status-badge badge badge-success badge-pill">
                        <i
                          className={`fa fa-2x ${ISSUE_STATUS_ICONS[comment_type]}`}
                        >
                          {''}
                        </i>{' '}
                        {ISSUE_STATUSES_LANG[comment_type]}
                      </span>
                    </p>
                  )}

                {userId != event.user.id && (
                  <p>Назначена: {event.user.full_name}</p>
                )}

                {client && (
                  <p>
                    Клиент:{' '}
                    <Link to={`/${link}/${client.id}`}>
                      {client.full_name || `ID:<${client.id}>`}
                    </Link>
                  </p>
                )}
                <div className="event__button-container">
                  {!done && (
                    <button
                      className="btn btn-sm btn-primary"
                      title="Решена"
                      onClick={() => {
                        handleEventFinish(event.id);
                      }}
                      type="button"
                    >
                      Решена
                    </button>
                  )}

                  <button
                    className="btn btn-sm btn-danger"
                    title="Удалить"
                    onClick={() => {
                      handleEventRemove(event.id);
                    }}
                    type="button"
                  >
                    Удалить
                  </button>
                </div>
              </li>
            );
          })}
          <BackButton />
          {events.length > count && (
            <Paginator
              total={count}
              onPageChange={handlePageChange}
              forcePage={page - 1}
              perPage={PER_PAGE}
            />
          )}
        </ul>
        {isError && <Alert color="warning">Ошибка</Alert>}
        {isSuccess && <Alert>Успешно</Alert>}
      </div>
      <Notifications day={day} type={BIRTHDAY} />
      <Notifications day={day} type={ARRIVED_FROM_REST} />
      <Notifications day={day} type={DEPARTURE_TOMORROW} />
      <Notifications day={day} type={PASSPORT_EXPIRES} />
      <Notifications day={day} type={VISA_APPLICATION_DEADLINE} />
      <Notifications day={day} type={PAY_EXPIRES} />
    </div>
  );
};

export default CalendarDetail;
