import 'moment/locale/ru';
import moment from 'moment';
import React, { Component } from 'react';

import { EventsAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';

import './style.css';
import CalendarAddEvent from './Components/CalendarAddEvent.js';
import CalendarTable from './Components/CalendarTable.js';
import CalendarToolbar from './Components/CalendarToolbar.js';

export const DJANGO_BASE_DATE_FORMAT = 'YYYY-MM-DD';

class Calendar extends Component {
  constructor(props) {
    super(props);
    this.submit = this.submit.bind(this);
    this.cancel = this.cancel.bind(this);
    this.today = this.today.bind(this);
    this.remove = this.remove.bind(this);
    this.fillDataForm = this.fillDataForm.bind(this);
    this.nextMonth = this.nextMonth.bind(this);
    this.prevMonth = this.prevMonth.bind(this);
    this.toggleMonth = this.toggleMonth.bind(this);
    this.getStartDay = this.getStartDay.bind(this);
    this.getEndDay = this.getEndDay.bind(this);
    this.userChange = this.userChange.bind(this);

    this.state = {
      events: [],
      isHideForm: true,
      dateEvent: '',
      month: moment().startOf('month'),
      notifications: {},
      user: { label: 'События всех сотрудников', value: 'all' },
    };
  }

  fetchItems(user = null) {
    const self = this,
      { month } = this.state;
    let status,
      filter = {
        day_range_after: self
          .getStartDay(month)
          .format(DJANGO_BASE_DATE_FORMAT),
        day_range_before: self.getEndDay(month).format(DJANGO_BASE_DATE_FORMAT),
      };

    if (user && user.value !== 'all') {
      filter['user'] = user.value;
    }

    EventsAPI.fetchList(1, filter, `full_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          self.setState({
            events: r || [],
            isHideForm: true,
          });
        }
      });
  }

  fillDataForm(date) {
    this.setState({
      isHideForm: false,
      dateEvent: date,
    });
  }

  toggleMonth(action) {
    const self = this;
    self.setState(
      (prevState) => ({
        month: prevState.month[action](1, 'month'),
        isHideForm: true,
      }),
      () => self.fetchItems(this.state.user)
    );
  }

  nextMonth() {
    this.toggleMonth('add');
  }

  prevMonth() {
    this.toggleMonth('subtract');
  }

  today() {
    this.setState(
      {
        month: moment().startOf('month'),
        isHideForm: true,
      },
      () => this.fetchItems(this.state.user)
    );
  }

  componentDidMount() {
    this.fetchItems();
  }

  submit(r) {
    const self = this;
    self.setState((prevState) => ({
      events: [...prevState.events, r],
      isHideForm: true,
    }));
  }

  cancel() {
    this.setState({
      isHideForm: true,
    });
  }

  remove(id) {
    const self = this;
    let status;

    EventsAPI.delete(id)
      .then((r) => {
        status = r.status;
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          return {};
        }
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          self.setState((prevState) => ({
            events: prevState.events.filter((e) => e.id !== id),
            isHideForm: true,
          }));
        }
      });
  }

  getStartDay(month) {
    let currentDay = moment(month, 'MM').startOf('month');
    if (currentDay.day() === 0) {
      currentDay.subtract(7 - 1, 'day');
    } else if (currentDay.day() !== 1) {
      currentDay.subtract(currentDay.day() - 1, 'day');
    }
    return currentDay;
  }

  getEndDay(month) {
    return this.getStartDay(month).add(7 * 5 - 1, 'day');
  }

  userChange(user) {
    const _user = user ? user : 'all';
    this.setState({ user: _user });
    this.fetchItems(_user);
  }

  render() {
    const {
      events = [],
      month,
      isHideForm,
      dateEvent,
      notifications,
    } = this.state;

    const normalizeData = () => {
      let data = [];
      let currentDay = this.getStartDay(month);
      for (let row = 0; row < 5; row++) {
        let week = [];
        for (let col = 0; col < 7; col++) {
          let day = {};
          day.header = !!row ? 0 : 1;
          day.date = currentDay.format('DD');
          day.dateId = currentDay.format(DJANGO_BASE_DATE_FORMAT);
          day.day = currentDay.day();
          day.name = 'событие';
          day.events = events.length
            ? events.filter(
                (e) => e.day === currentDay.format(DJANGO_BASE_DATE_FORMAT)
              )
            : [];
          day.style =
            currentDay.day() === 6 || currentDay.day() === 0 ? 'red' : '';
          day.style += !!day.events.length ? ' green' : '';
          day.style +=
            currentDay.format(DJANGO_BASE_DATE_FORMAT) ===
            moment().format(DJANGO_BASE_DATE_FORMAT)
              ? ' today'
              : '';
          day.style +=
            currentDay.format(DJANGO_BASE_DATE_FORMAT) <
            moment().format(DJANGO_BASE_DATE_FORMAT)
              ? ' past'
              : '';
          currentDay.add(1, 'day');
          day.notifications = notifications.hasOwnProperty(day.dateId)
            ? notifications[day.dateId]
            : { count: 0 };
          week.push(day);
        }
        data.push(week);
      }
      return data;
    };
    const data = normalizeData();

    const settings = {
      DAY: 86400000,
      rowTotal: 5,
      isHide: true,
      local: {
        week: [
          'Воскресенье',
          'Понедельник',
          'Вторник',
          'Среда',
          'Четверг',
          'Пятница',
          'Суббота',
        ],
        day: 'день',
        month: [
          'Январь',
          'Февраль',
          'Март',
          'Апрель',
          'Май',
          'Июнь',
          'Июль',
          'Август',
          'Сентябрь',
          'Октябрь',
          'Ноябрь',
          'Декабрь',
        ],
        monthList: [
          'января',
          'февраля',
          'марта',
          'апреля',
          'мая',
          'июня',
          'июля',
          'августа',
          'сентября',
          'октября',
          'ноября',
          'декабря',
        ],
        year: 'год',
      },
      currentDate: {
        month: new Date().getMonth(),
        year: new Date().getFullYear(),
      },
      dialog: false,
      data: data,
    };

    return (
      <div className="eventCalendar">
        <h3>Календарь менеджера по туризму</h3>
        <CalendarToolbar
          isHideFilter={!isHideForm}
          onNext={this.nextMonth}
          onPrev={this.prevMonth}
          onDefault={this.today}
          currentMonth={month.format('MMMM YYYY')}
          userChange={this.userChange}
          userValue={this.state.user}
        />
        {isHideForm ? (
          <CalendarTable
            onRemove={this.remove}
            onDayId={this.fillDataForm}
            settings={settings}
          />
        ) : (
          <CalendarAddEvent
            date={dateEvent}
            onCancel={this.cancel}
            onSubmit={this.submit}
          />
        )}
      </div>
    );
  }
}

export default Calendar;
