import React, { Component, useEffect, useState } from 'react';
import { Row } from 'reactstrap';

import BackButton from '../../components/BackButton';
import { LeadAPI, OfficeAPI } from '../../api/Client';
import { useParams } from 'react-router-dom';
import LeadsTable from '../LeadsTable/LeadsTable';

function Office() {
  const { officeId } = useParams();

  const [officeInfo, setOfficeInfo] = useState({});
  const [officeLeads, setOfficeLeads] = useState([]);
  const [officeTemplates, setOfficeTemplates] = useState([]);
  const [isSuccess, setSuccess] = useState(false);
  const [errors, setErrors] = useState([]);
  const [selected, setSelected] = useState([]);
  const [pageNumber, setPageNumber] = useState(1);

  useEffect(() => {
    getOfficeInfo();
    getOfficeTemplates();
    getIssues();
  }, []);

  function getOfficeInfo() {
    OfficeAPI.getInfo(officeId).then((r) => setOfficeInfo(r));
  }

  function getOfficeTemplates() {
    OfficeAPI.getTemplateInfo(officeId).then((r) => setOfficeTemplates(r));
  }

  function getIssues(page = 1) {
    // OfficeAPI.getList(page, {}, `${officeId}/leads/`).then((r) => {
    //   setOfficeIssues(r.results);
    // });
    // LeadAPI.fetchList(page, { office_id__in: officeId })
    //   .then((r) => r.json())
    //   .then((r) => setOfficeLeads(r));
  }

  function handlePageChange(data) {
    const page = data.selected + 1;
    setPageNumber(page);
    getIssues(page);
    setSuccess(false);
  }

  const { state, address, email, name } = officeInfo;

  return (
    <div className="animated fadeIn">
      <Row>
        <div className="col-md-12">
          <div className="card card-accent-primary">
            <div className="card-header">
              <h3>{name}</h3>
            </div>
            <div className="card-body">
              <div className="row">
                <label className="text-right col-md-2">Область:</label>
                <p className="col-md-8">{state}</p>
              </div>
              <div className="row">
                <label className="text-right col-md-2">Адрес:</label>
                <p className="col-md-8">{address}</p>
              </div>
              <div className="row">
                <label className="text-right col-md-2">Email:</label>
                <p className="col-md-8">{email}</p>
              </div>
              <BackButton />
            </div>
          </div>
          {/* {officeLeads.results.length > 0 ? (
            <LeadsTable
              isShowActions={[]}
              count={officeLeads.count || 0}
              items={officeLeads.results}
              filters={[]}
              handleRefresh={getIssues}
              handlePageChange={handlePageChange}
              handleDeleteLeads={() => console.log('should delete')}
              toggleItem={() => console.log('should toggle')}
              errors={errors}
              isSuccess={isSuccess}
              selected={selected || []}
              initialPage={pageNumber - 1}
              forcePage={pageNumber - 1}
            />
          ) : null} */}
        </div>
      </Row>
    </div>
  );
}

export default Office;
