import React, { Component } from 'react';
import Select from 'react-select';

export default class UserRoles extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { userRoles, userRole, handleUserRole } = this.props;

    const role = userRole.id
      ? userRoles.find((item) => item.value == userRole.id)
      : userRole;
    return (
      <Select
        id="employeeType"
        name="userRole"
        placeholder="Выберите тип сотрудника из спиcка..."
        closeOnSelect={true}
        value={role}
        simpleValue={true}
        onChange={handleUserRole}
        options={userRoles}
      />
    );
  }
}
