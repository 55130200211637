import React, { Component, useEffect, useState } from 'react';
import {
  CardHeader,
  CardFooter,
  CardBody,
  Row,
  Col,
  Button,
  Alert,
} from 'reactstrap';
import {
  OrderFpAPI,
  POSITIVE_ACTION_STATUSES,
  UsersAPI,
} from '../../api/Client';
import BackButton from '../../components/BackButton';
import moment from 'moment';
import 'moment/locale/ru';
import 'react-datepicker/dist/react-datepicker.css';
import Select from 'react-select';
import { ADMIN, getUserRole, SUPER_ADMIN } from '../../connect/auth';
import { getCurrencyIcon } from '../OrdersFpTable/CurrencySelector';
import { Link, useParams } from 'react-router-dom';

function checkServerResponse(r) {
  if (r.ok) {
    return r.json();
  } else return Promise.reject(r);
}

function OrderFp() {
  const { orderId } = useParams();

  const [orderInfo, setOrderInfo] = useState({});
  const [selectedManagers, setSelectedManagers] = useState('');
  const [managerList, setManagerList] = useState([]);
  const [errorMessages, setErrorMessages] = useState({});
  const [isDisabled, setDisabled] = useState(true);
  const [isError, setError] = useState(false);
  const [isSuccess, setSuccess] = useState(false);
  const [isFormChanged, setFormChanged] = useState(false);

  useEffect(() => {
    getInfo();
    getManagersList();
  }, []);

  function getInfo() {
    OrderFpAPI.fetchJSON(`${orderId}/`)
      .then(checkServerResponse)
      .then((r) => {
        setOrderInfo(r);
        setSelectedManagers(r.manager.length ? r.manager : ``);
      })
      .catch((err) => console.log(err));
  }

  function getManagersList() {
    UsersAPI.fetchJSON(`full_list/`)
      .then(checkServerResponse)
      .then((r) => {
        setManagerList(r);
        setDisabled(false);
      })
      .catch((err) => console.log(err));
  }

  function handleOnSave() {
    OrderFpAPI.update(`${OrderFpAPI.resource_url}${orderId}/order_managers`, {
      manager: selectedManagers ? selectedManagers.map((i) => i.id) : [],
    })
      .then(checkServerResponse)
      .then((result) => {
        setSelectedManagers(
          result.manager.length ? result.manager.join(`,`) : ``
        );
        handleSaveSuccess();
      })
      .catch(handleSaveError);
  }

  function handleOnChange(v) {
    setSelectedManagers(v);
    setError(false);
    setSuccess(false);
    setFormChanged(true);
    setErrorMessages({});
  }

  function handleSaveSuccess() {
    setError(false);
    setSuccess(true);
    setFormChanged(false);
    setErrorMessages({});
  }

  function handleSaveError(err) {
    setError(true);
    setSuccess(false);
    setErrorMessages(err);
  }

  const {
    fp_pk,
    office,
    status,
    tourists_fp,
    manager_fp,
    create_date,
    begin_date,
    end_date,
    depart_city,
    destination_country,
    summ,
    currency,
    customer,
  } = orderInfo;

  const userRole = getUserRole();
  const isAdmin = userRole === ADMIN;
  const isSuperAdmin = userRole === SUPER_ADMIN;

  return (
    <div className="animated fadeIn">
      <Row>
        <Col md="12">
          <div className="card card-accent-primary">
            <CardHeader>
              <i className="icon-paper-plane">{''}</i>
              Заявка &#x02116; {fp_pk} / создана менеджером из ФСУ -{' '}
              {!!manager_fp ? manager_fp.name : ``}
              <span className="badge badge-info float-right">
                {moment(create_date).format('DD.MM.YYYY, HH:mm')}
              </span>
            </CardHeader>
            <CardBody>
              <Row>
                <label className="text-right col-md-2">Офис:</label>
                <p className="col-md-8">{!!office ? office.title : ``}</p>
              </Row>
              <Row>
                <label className="text-right col-md-2">Статус:</label>
                <p className="col-md-8">{!!status ? status.name : ``}</p>
              </Row>
              <Row>
                <label className="text-right col-md-2">Инфо:</label>
                <p className="col-md-8">
                  {depart_city} - {destination_country} с{' '}
                  {moment(begin_date).format('DD.MM.YY')} по{' '}
                  {moment(end_date).format('DD.MM.YY')} на{' '}
                  {moment
                    .duration(moment(end_date).diff(moment(begin_date)))
                    .asDays()}{' '}
                  ночей за {summ} {!!currency && getCurrencyIcon(currency.name)}
                </p>
              </Row>
              {!!customer && (
                <Row>
                  <label className="text-right col-md-2">Заказчик:</label>
                  <p className="col-md-8">{customer.full_name}</p>
                </Row>
              )}
              <h5>Список туристов:</h5>
              {!!tourists_fp && (
                <ul>
                  {tourists_fp.map((t) => (
                    <li key={t.id}>
                      <Link to={`/tourists/${t.id}`}>{t.full_name}</Link>
                    </li>
                  ))}
                </ul>
              )}
              {!!manager_fp && (
                <Row>
                  <label className="text-right col-md-2">Менеджер ФП:</label>
                  <p className="col-md-8">{`${manager_fp.fp_pk} (${manager_fp.name})`}</p>
                </Row>
              )}
              <Row>
                <div className="col-md-6">
                  <div className="input-group">
                    <span
                      className="input-group-addon"
                      title="Наличие телефонов"
                    >
                      Менеджеры заявки
                    </span>
                    <Select
                      id="manager-selector"
                      placeholder="Менеджеры заявки"
                      name="hasPhones"
                      isMulti={true}
                      value={selectedManagers}
                      onChange={handleOnChange}
                      options={managerList}
                      getOptionValue={(m) => m.id}
                      getOptionLabel={(m) =>
                        m.last_name
                          ? `${m.id} (${m.last_name} ${m.first_name})`
                          : `${m.id} (${m.username})`
                      }
                      className="form-control"
                      isDisabled={!(isSuperAdmin || isAdmin) || isDisabled}
                    />
                  </div>
                </div>
              </Row>
              {isSuccess && (
                <Row>
                  <div className="col-md-6">
                    <label className="text-right col-md-2"> </label>
                    <Alert>Успешно</Alert>
                  </div>
                </Row>
              )}
              {isError &&
                !!errorMessages &&
                !!errorMessages.manager &&
                errorMessages.manager.map((item, idx) => (
                  <Row key={idx}>
                    <div className="col-md-6">
                      <label className="text-right col-md-2"> </label>
                      <Alert className="bg-danger">{item}</Alert>
                    </div>
                  </Row>
                ))}
            </CardBody>
            <CardFooter>
              <BackButton />
              {(isSuperAdmin || isAdmin) && (
                <Button
                  color={isFormChanged ? 'success' : 'secondary'}
                  onClick={handleOnSave}
                  disabled={isDisabled || !isFormChanged}
                >
                  Сохранить
                </Button>
              )}
            </CardFooter>
          </div>
        </Col>
      </Row>
    </div>
  );
}

export default OrderFp;
