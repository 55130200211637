export const FRANCHISE_MANAGER_OPTIONS = [
  { label: 'Бородина Оксана', value: '59' },
  { label: 'Горкуша Алексей', value: '398' },
  { label: 'Малофеева Алёна', value: '487' },
  { label: 'Оборин Максим', value: '546' },
  { label: 'Петрищева Ирина', value: '633' },
  { label: 'Талипова Анжелика', value: '709' },
  { label: 'Уймина Алена', value: '22' },
  { label: 'Яшпаева Нина Михайловна ', value: '400' },
];

export const FRANCHISE_STATUS_OPTIONS = [
  { label: "Новая", value: "new" },
  { label: "Фрачзайзинг", value: "franchasing" },
  { label: "Субагент", value: "subagent" },
  { label: "Мы отказали", value: "refuse" },
  { label: "Звонок 3 дня", value: "call_three_days" },
  { label: "Звонок неделя", value: "call_week" },
  { label: "Звонок месяц", value: "call_month" },
  { label: "Звонок 3 месяца", value: "call_three_months" },
  { label: "Звонок полгода", value: "call_half_year" },
  { label: "Выбрали конкурента", value: "choose_competitor" },
  { label: "Отказ от бизнеса", value: "cancel_business" },
  { label: "Турагент онлайн", value: "touragent_online" },
  { label: "Не дозвонились", value: "no_call_answered" },
  { label: "Потенциал", value: "potential" },
  { label: "Фрилансер", value: "freelancer" },
  { label: "Для рассылки", value: "for_mailing" },
  { label: " ", value: " " },
];

export const FRANCHISE_SOURCE_OPTIONS = [
  { label: "одноклассники", value: "odnoklassniki" },
  { label: "заявка с сайта", value: "lead_from_site" },
  { label: "в контакте", value: "VK" },
  { label: "рассылка", value: "mailing" },
  { label: "инстаграм", value: "instagram" },
  { label: "фейсбук", value: "facebook" },
  { label: "контекстная Гугл", value: "google" },
  { label: "контекстная Яндекс", value: "yandex" },
  { label: "Head Hunter", value: "headhunter" },
  { label: "Туршкола", value: "geo_site_tourschool" },
];

export const FRANCHISE_EXPERIENCE_OPTIONS = [
  { label: "да", value: true },
  { label: "нет", value: false },
];

export const FRANCHISE_COUNTRY_OPTIONS = [
  { label: "Россия", value: "russia" },
  { label: "Казахстан", value: "kazakhstan" },
  { label: "Беларусь", value: "belorussia" },
];

export const FRANCHISE_STATUS = {
  deciding: 'новая',
  franchasing: 'франчайзинг',
  subagent: 'субагент',
  refuse: 'мы отказали',
  new: 'новая',
  call_three_days: 'звонок 3 дня',
  call_week: 'звонок неделя',
  call_month: 'звонок месяц',
  call_three_months: 'звонок 3 месяца',
  call_half_year: 'звонок полгода',
  choose_competitor: 'выбрали конкурента',
  cancel_business: 'отказ от бизнеса',
  touragent_online: 'турагент онлайн',
  no_call_answered: 'не дозвонились',
  potential: 'потенциал',
  freelancer: 'фрилансер',
  for_mailing: 'для рассылки',
};

export const FRANCHISE_STATUS_CLASS = {
  new: 'badge-danger',
  deciding: 'badge-danger',
  franchasing: 'badge-success',
  subagent: 'badge-primary',
  refuse: 'badge-brown',
  call_three_days: 'badge-purple',
  call_week: 'badge-purple',
  call_month: 'badge-purple',
  call_three_months: 'badge-purple',
  call_half_year: 'badge-purple',
  choose_competitor: 'badge-brown',
  cancel_business: 'badge-red',
  touragent_online: 'badge-primary',
  no_call_answered: 'badge-gray',
  potential: 'badge-pink',
  freelancer: 'badge-orange',
  for_mailing: 'badge-gray',
};