import React, { Component } from 'react';
import Paginator from '../../components/Paginator';
import { Link } from 'react-router-dom';
import { POSITIVE_ACTION_STATUSES, UsersAPI } from '../../api/Client';
import EmployeesFilter from './components/EmployeesFilter';
import Toggle from '../Notify/components/Toggle';

export default class Employees extends Component {
  constructor(props) {
    super(props);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.filterItems = this.filterItems.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.toggleFormVisibility = this.toggleFormVisibility.bind(this);
    this.fetchRoles = this.fetchRoles.bind(this);
    this.toggleActive = this.toggleActive.bind(this);

    this.state = {
      items: [],
      pageNumber: 1,
      count: 0,
      filters: [],
      userRoles: [],
      isShowForm: false,
    };
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.fetchItems(page);
  }

  filterItems(st) {
    let result = {};
    if (st.userData) {
      result.search = st.userData;
    }
    if (st.userRole) {
      result.role = st.userRole;
    }
    if (st.offices) {
      result.offices =
        Array.isArray(st.offices) && st.offices.length
          ? st.offices.map((i) => i.id)
          : [];
    }
    if (result) {
      this.fetchItems(1, result);
    }
  }

  fetchItems(page = 1, filters = {}) {
    let status;
    UsersAPI.fetchList(page, filters)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            items: r.results || [],
            count: r.count || 0,
            pageNumber: page,
          });
        }
      });
  }

  fetchRoles() {
    let status;
    UsersAPI.fetchJSON(`roles/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            userRoles: r || {},
          });
        }
      });
  }

  onCreate() {
    this.fetchItems();
  }

  toggleFormVisibility() {
    this.setState((prevState) => ({
      isShowForm: !prevState.isShowForm,
    }));
  }

  componentDidMount() {
    this.fetchItems();
    this.fetchRoles();
  }

  toggleActive(index) {
    const currentUser = this.state.items[index];
    let isActive = !currentUser.is_active;
    let status;

    UsersAPI.update(`${UsersAPI.resource_url}${currentUser.id}/activity`, {
      is_active: isActive,
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.update) {
          this.setState((prevState) => {
            const newItems = [...prevState.items];
            newItems[index].is_active = isActive;
            return {
              items: newItems,
            };
          });
        }
      });
  }

  render() {
    const { items, count, userRoles, isShowForm } = this.state;
    return (
      <div className="animated fadeIn filter-container">
        <div className="filter">
          <EmployeesFilter
            userRoles={userRoles}
            onFilter={this.filterItems}
            isShowForm={isShowForm}
            toggleFormVisibility={this.toggleFormVisibility}
            onCreate={this.onCreate}
          />
        </div>
        <div className="row">
          <div className="col-lg-12">
            <div className="card">
              <div className="card-header">
                <i className="fa fa-align-justify">{''}</i> Всего&nbsp;
                <span className="badge badge-info">{count}</span>
              </div>
              <div className="card-body card-body_responsive">
                <table className="table table-bordered table-striped table-sm">
                  <thead>
                    <tr>
                      <th>ID</th>
                      <th></th>
                      <th>Логин</th>
                      <th>ФИО</th>
                      <th>Должность</th>
                      <th>Email</th>
                      <th>Офис</th>
                      <th>Активен</th>
                    </tr>
                  </thead>
                  <tbody>
                    {items
                      ? items.map((item, index) => (
                          <tr key={`employee-${item.id}`}>
                            <td>
                              <Link to={`/employees/${item.id}`}>
                                {item.id}
                              </Link>
                            </td>
                            <td>
                              {!!item.info.avatar ? (
                                <img
                                  width="32"
                                  height="32"
                                  style={{
                                    borderRadius: '100%',
                                    background: '#c7c7c7',
                                  }}
                                  src={item.info.avatar}
                                />
                              ) : (
                                <img
                                  width="32"
                                  height="32"
                                  src="/img/avatars/tourist-avatar.png"
                                />
                              )}
                            </td>
                            <td>{item.username}</td>
                            <td>
                              <Link
                                to={`/employees/${item.id}`}
                              >{`${item.last_name} ${item.first_name}`}</Link>
                            </td>
                            <td>
                              {!item.profile ? null : item.profile.role.name}
                            </td>
                            <td>{item.email}</td>
                            <td>
                              {Array.isArray(item.offices) &&
                              item.offices.length
                                ? item.offices.map((i) => i.name).join(', ')
                                : null}
                            </td>
                            <td>
                              <Toggle
                                id={item.id}
                                lang=""
                                checked={item.is_active}
                                onChange={() => this.toggleActive(index)}
                              />
                            </td>
                          </tr>
                        ))
                      : null}
                  </tbody>
                </table>
                <Paginator total={count} onPageChange={this.handlePageChange} />
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
