import React, { useEffect, useState } from 'react';
import ClientAPI from '../../api/Client';
import BackButton from '../../components/BackButton';
import { useParams } from 'react-router-dom';
import ProgressBar from '../../components/ProgressBar';

function Issue() {
  const { issueId } = useParams();

  const [issue, setIssue] = useState({});
  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    getIssue();
  }, []);

  function getIssue() {
    setLoading(true);
    ClientAPI.getJSON(`/issues/${issueId}`).then((response) => {
      setIssue(response);
      setLoading(false);
    });
  }

  return isLoading ? (
    <ProgressBar />
  ) : (
    <div className="issues">
      <h2>Запрос # {issue.id}</h2>
      <p>источник: {issue.source}</p>
      <p>статус: {issue.status}</p>
      <p>название: {issue.title}</p>
      <p>текст запроса: {issue.text}</p>
      <p>клиент: {issue.type}</p>
      <p>создан: {issue.created}</p>
      <p>выполнен: {issue.resolved}</p>
      <BackButton />
    </div>
  );
}

export default Issue;
