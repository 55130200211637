import React, { PureComponent } from 'react';
import { Modal, ModalBody, ModalHeader } from 'reactstrap';
import TabUsers from '../../../TabUsers/TabUsers';
import './UserTypeModal.css';

export default class UserTypeModal extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const {personsForView, touristfpsForView, toggleLikesListModal, isOpen, modalTitle} = this.props
    if (
      (personsForView &&
        personsForView.length === 0 &&
        touristfpsForView &&
        touristfpsForView.length === 0) ||
      (!personsForView && !touristfpsForView)
    ) {
      return (
        <Modal
          className="modal-table-warning"
          centered={true}
          toggle={toggleLikesListModal}
          isOpen={isOpen}
        >
          <ModalHeader
            className="modal-table-header-warning"
            toggle={toggleLikesListModal}
          >
            Нет пользователей для отображения
          </ModalHeader>
        </Modal>
      );
    }

    return (
      <Modal
        toggle={toggleLikesListModal}
        isOpen={isOpen}
        className="modal-table"
      >
        <div className="modal-container">
          <ModalHeader
            className="modal-table-header"
            toggle={toggleLikesListModal}
          >
            {`${modalTitle} пользователи`}
          </ModalHeader>
          <ModalBody className="modal-table-content">
            <TabUsers
              persons={personsForView}
              touristfps={touristfpsForView}
            />
          </ModalBody>
        </div>
      </Modal>
    );
  }
}
