import { useState, useEffect } from 'react';
import { POSITIVE_ACTION_STATUSES, UsersAPI } from '../../api/Client';
import Select from 'react-select';
import {
  checkTokenExpirationDate,
  deauthenticateUser,
  getCurrentOfficeId,
  getUserInfo,
  pushCurrentOffice,
} from '../../connect/auth';
import './style.css';
import { Navigate, useNavigate } from 'react-router-dom';

const Officein = () => {
  const [office, setOffice] = useState(getCurrentOfficeId());
  const [offices, setOffices] = useState([]);
  const [showErrorMessage, setShowErrorMessage] = useState(false);
  const navigate = useNavigate();

  function handleChange(value) {
    setOffice(value);
  }

  function handleSubmit(e) {
    e.preventDefault();

    const officeData = offices.find((i) => i.id === office.value);
    if (!!officeData) {
      pushCurrentOffice(officeData);
      navigate('/');
    } else {
      setShowErrorMessage(true);
    }
  }

  useEffect(() => {
    const handleOptionLoad = async () => {
      let status;
      try {
        const response = await UsersAPI.fetchOffices();
        status = response.status;
        const result = await response.json();
        if (status === POSITIVE_ACTION_STATUSES.retrieve) {
          let offices = result || [];
          setOffices(offices);
          if (offices.length == 1) {
            pushCurrentOffice(offices[0]);
            navigate('/');
          }
        }
      } catch (err) {
        console.log('Failed to load offices', err);
      }
    };
    handleOptionLoad();
  }, [navigate]);

  const userInfo = getUserInfo();

  if (!userInfo || !checkTokenExpirationDate(userInfo.exp)) {
    deauthenticateUser();
    return <Navigate to="/login" />;
  }

  const OPTIONS = offices.length
    ? offices.map((office) => {
        const name = office.address ? office.address : office.name;
        return { value: office.id, label: `${name} (${office.id})` };
      })
    : [];

  return (
    <div className="app flex-row align-items-center auth--bg-img">
      <div className="container">
        <div className="row justify-content-center">
          <div className="col-md-4">
            <img height="64" src="../img/logo.client.png" />
            <div className="hidden-mobile">
              <p className="">
                Геоклиент - система управления эффективной коммуникацией с
                клиентами в офисах туристической сети "География". Внедрение
                программы позволит вам оперативно и сегментированно работать с
                клиентсткой базой, автоматизировать отправку типовых и
                обязательных обращений клиентам.{' '}
              </p>
              <p>
                Сохранить в копилке агентства каждое обращение и не потерять ни
                одного контакта потенциального клиента.{' '}
              </p>
            </div>
          </div>
          <div className="col-md-4">
            <div className="card-group mb-0">
              <div className="card p-4">
                <form action="" onSubmit={handleSubmit}>
                  <div className="card-body">
                    <h1>Выберите офис</h1>
                    <div className="row">
                      <div
                        className="col-md-12"
                        style={{
                          marginTop: 20,
                          marginBottom: 20,
                        }}
                      >
                        <Select
                          name="office"
                          placeholder="Выбор из списка ..."
                          onChange={handleChange}
                          value={office}
                          searchPromptText="Введите символ для поиска..."
                          loadingPlaceholder="Загрузка..."
                          noResultsText="Записей не найдено..."
                          options={OPTIONS}
                        />
                      </div>
                    </div>

                    <div className="row">
                      <div className="col-6">
                        <button
                          type="submit"
                          disabled={!office}
                          className="btn btn-primary px-4"
                        >
                          Войти
                        </button>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="row justify-content-center">
          <div className="col-md-4">
            {showErrorMessage && (
              <div className="alert alert-danger fade show" role="alert">
                <strong>Ошибка!</strong> Не удалось войти
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Officein;
