import 'moment/locale/ru';
import moment from 'moment';
import React, { useCallback, useEffect, useState } from 'react';
import Swal from 'sweetalert';

import APIClient, { QuotesAPI } from '../../api/Client';
import BackButton from '../../components/BackButton';
import QuoteTourists from './components/QuoteTourists';
import ToursList from '../Tours/components/ToursList';
import { getUserRole } from '../../connect/auth';
import UserTypeModal from '../Tours/components/UserTypeModal/UserTypeModal';
import formatNumber from '../../Utils/FormatNumber';
import { useNavigate, useParams } from 'react-router-dom';

const Quote = () => {
  const { quoteId} = useParams();
  const navigate = useNavigate();

  const [isOpenModal, setIsOpenModal] = useState(false);
  const [tours, setTours] = useState([]);
  const [saveDisabled, setSaveDisabled] = useState(true);
  const [quoteDetails, setQuoteDetails] = useState({
    id: null,
    title: '',
    description: '',
    bottom_text: '',
    created_at: null,
    updated_at: null,
    actualization_status: 0,
    provider: '',
    stat: {},
    persons: [],
    author: { first_name: '', last_name: '' },
    is_buffer: false,
    is_buffer_with_text: false,
    isDisabledActualizeButton: false,
    hide_after_days_2: null,
    is_active_on_site: true,
    notify_author_by_telegram: true,
  });

  const fetchItems = useCallback(() => {
    APIClient.getJSON(`/quotes/${quoteId}/`).then((res) => {
      setQuoteDetails({
        ...quoteDetails,
        id: res.id,
        hide_after_days_2: res.hide_after_days_2,
        is_active_on_site: res.is_active_on_site,
        notify_author_by_telegram: res.notify_author_by_telegram,
        tours: res.tours,
        title: res.title,
        description: res.description,
        bottom_text: res.bottom_text,
        quote_id: res.quote_id,
        created_at: res.created_at,
        provider: res.provider,
        author: res.author,
        persons: res.persons,
        touristsFp: res.touristsfp,
        stat_likes_count: res.stat_likes_count || 0,
        stat_clicks_count: res.stat_clicks_count || 0,
        stat_views_count: res.stat_views_count || 0,
        stat: res.stat && res.stat.common ? res.stat.common[0] : {},
        stat_views_persons: res.stat_views_persons,
        stat_views_touristfps: res.stat_views_touristfps,
        actualization_status: res.tours[0].actualization_status,
        updated_at: res.tours[0].dt_actualization,
      });
      setTours(res.tours);
    });
  }, [quoteId]);

  useEffect(() => {
    fetchItems();
  }, [fetchItems]);

  const handleSaveTitle = () => {
    setSaveDisabled(true);
    APIClient.update(`/quotes/${quoteId}`, {
      title: quoteDetails.title,
      description: quoteDetails.description,
      bottom_text: quoteDetails.bottom_text,
    }).then(() => {
      console.log('save title', quoteDetails.title);
    });
  };

  const handleChange = (e, field) => {
    console.log('working', e.target.value, field);
    setQuoteDetails((prevState) => ({
      ...prevState,
      [field]: e.target.value,
    }));
    setSaveDisabled(false);
  };

  const handleCopyMessageToBuffer = (withText = false) => {
    console.log(withText);
    const text = withText
      ? `${
          quoteDetails.title
        }\nhttps://www.geograftour.com/geoclient/${quoteId}/\n${quoteDetails.tours
          .map(
            (tour) =>
              `${moment(tour.checkin, 'YYYY-MM-DD').format('DD.MM.YYYY')}, ${
                tour.nights
              }н, ${tour.name}, ${tour.board}, ${
                tour.manager_data?.initial_price
                  ? formatNumber(parseInt(tour.manager_data.initial_price))
                  : formatNumber(parseInt(tour.initial_price))
              } ${tour.currency}`
          )
          .join('\n')}`
      : `${quoteDetails.title}\nhttps://www.geograftour.com/geoclient/${quoteId}/`;

    navigator.clipboard.writeText(text);
    setQuoteDetails((prevState) => ({
      ...prevState,
      [withText ? 'is_buffer_with_text' : 'is_buffer']: true,
    }));

    setTimeout(() => {
      setQuoteDetails((prevState) => ({
        ...prevState,
        [withText ? 'is_buffer_with_text' : 'is_buffer']: false,
      }));
    }, 5000);
  };

  function handleSharedVK() {
    let url = `https://geograftour.com/geoclient/${quoteId}/`;
    let image = `https://geograftour.com/static/index/img/quote_vk.jpg`;
    let title = quoteDetails.title
      ? quoteDetails.title
      : `Геоподборка ${quoteId}`;

    handleCopyMessageToBuffer(true);
    window.open(
      `https://vk.com/share.php?url=${url}&title=${encodeURIComponent(
        title
      )}&description=descriptionofquote&image=${image}&noparse=true`
    );
  }

  const handleActualize = () => {
    setQuoteDetails((prevState) => ({
      ...prevState,
      isDisabledActualizeButton: true,
    }));

    QuotesAPI.actualizeQuote(quoteId)
      .then((r) => r.json())
      .then((response) => {
        Swal(
          response.id
            ? 'Цены на туры в подборке успешно обновлены!'
            : 'Не удалось обновить цены. Повторите попытку позже'
        );
        setQuoteDetails((prevState) => ({
          ...prevState,
          isDisabledActualizeButton: false,
        }));
        fetchItems();
      });
  };

  function toggleHideAfterDays2() {
    QuotesAPI.toggleQuoteHideAfterDays2(quoteId)
      .then((r) => r.json())
      .then((response) => {
        if (response.hide_after_days_2) {
          Swal(`Подборка будет скрыта на сайте ${response.hide_after_days_2}`);
        } else {
          Swal('Автоматическое скрытие подборки выключено');
        }
        setQuoteDetails((prevState) => ({
          ...prevState,
          hide_after_days_2: response.hide_after_days_2,
        }));
      });
  }

  function toggleActiveOnSite() {
    QuotesAPI.toggleQuoteIsActiveOnSite(quoteId)
      .then((r) => r.json())
      .then((response) => {
        if (response.is_active_on_site) {
          Swal('Подборка доступна клиентам!');
        } else {
          Swal('Подборка скрыта от клиентов!');
        }
        setQuoteDetails((prevState) => ({
          ...prevState,
          is_active_on_site: response.is_active_on_site,
        }));
      });
  }

  function toggleNotifyAuthorByTelegram() {
    QuotesAPI.toggleQuoteNotifyAuthorByTelegram(quoteId)
      .then((r) => r.json())
      .then((response) => {
        if (response.notify_author_by_telegram) {
          Swal('Телеграм-уведомления включены!');
        } else {
          Swal('Телеграм-уведомления выключены!');
        }
        setQuoteDetails((prevState) => ({
          ...prevState,
          notify_author_by_telegram: response.notify_author_by_telegram,
        }));
      });
  }

  const handleQuoteDelete = () => {
    Swal({
      title: `Вы уверены?`,
      text: `Это необратимая операция`,
      icon: 'warning',
      buttons: true,
      dangerMode: true,
    }).then((willDelete) => {
      if (willDelete) {
        QuotesAPI.deleteQuote(quoteId).then((r) => {
          if (r.ok) {
            Swal(`Геоподборка ${quoteId} удалена`);
            navigate('/quotes');
          } else {
            Swal({
              text: `Что-то пошло не так`,
              dangerMode: true,
            });
          }
        });
      }
    });
  };

  function handleTourDelete() {
    APIClient.getJSON(`/quotes/${quoteId}/`).then((res) => {
      setQuoteDetails((prevState) => ({
        ...prevState,
        id: res.id,
        tours: res.tours,
        title: res.title,
        description: res.description,
        bottom_text: res.bottom_text,
        quote_id: res.quote_id,
        created_at: res.created_at,
        provider: res.provider,
        author: res.author,
        persons: res.persons,
        stat_likes_count: res.stat_likes_count || 0,
        stat_views_count: res.stat_views_count || 0,
        stat: res.stat && res.stat.common ? res.stat.common[0] : {},
      }));
    });
  }

  const handleTourManagerDataSave = (tour_id, manager_data) => {
    setTours((prevTours) =>
      prevTours.map((tour) =>
        tour.id === tour_id ? { ...tour, manager_data } : tour
      )
    );
  };

  const {
    id,
    hide_after_days_2,
    is_active_on_site,
    title,
    description,
    bottom_text,
    created_at,
    provider,
    author,
    stat_likes_count,
    stat_views_count,
    stat_clicks_count,
    persons,
    touristsFp,
    isDisabledActualizeButton,
    notify_author_by_telegram,
    updated_at,
    actualization_status,
    is_buffer,
    is_buffer_with_text,
  } = quoteDetails;

  const toursUpdatedAt = moment(updated_at).format('DD.MM.YY в H:mm');
  const userRole = getUserRole();
  const views_customers_exists =
    quoteDetails.stat_views_persons?.length > 0 ||
    quoteDetails.stat_views_touristfps?.length > 0;
  let text = `${title} https://www.geograftour.com/geoclient/${quoteId}/`;

  return (
    <>
      <UserTypeModal
        toggleLikesListModal={() => setIsOpenModal(!isOpenModal)}
        isOpen={isOpenModal}
        modalTitle={'Открывшие подборку'}
        personsForView={quoteDetails.stat_views_persons}
        touristfpsForView={quoteDetails.stat_views_touristfps}
      />

      <h3>
        {` ${title ? title : 'Геоподборка'}`} {quoteId}
      </h3>
      <div className="geopick__btn-container">
        <a
          href={`https://www.geograftour.com/geoclient/${quoteId}/`}
          className="btn btn-primary"
          target="_blank"
          rel="noopener noreferrer"
        >
          Просмотр
        </a>
        <button
          className="btn btn-warning"
          onClick={handleActualize}
          disabled={isDisabledActualizeButton}
        >
          Обновить цены
        </button>
        <button className="btn btn-secondary" onClick={toggleActiveOnSite}>
          {is_active_on_site ? 'Скрыть на сайте' : 'Показать на сайте'}
        </button>
        <button
          className="btn btn-secondary"
          onClick={toggleHideAfterDays2}
          disabled={!is_active_on_site}
        >
          {hide_after_days_2
            ? `Не скрывать на сайте ${moment(hide_after_days_2).format(
                'DD.MM.YY'
              )}`
            : 'Скрыть на сайте через 2 дня'}
        </button>
        <button className="btn btn-custom" onClick={handleQuoteDelete}>
          Удалить подборку
        </button>
        <button
          className={`btn ${
            notify_author_by_telegram ? 'btn-danger' : 'btn-success'
          }`}
          onClick={toggleNotifyAuthorByTelegram}
        >
          {notify_author_by_telegram ? 'Отключить' : 'Включить'}{' '}
          телеграм-уведомления
        </button>
      </div>

      {quoteDetails && (
        <>
          <div className="p-2">
            <p>
              <span>Создана: </span>
              <b>{moment(created_at).format('DD.MM.YY HH:mm')}</b>
            </p>
            <p>
              <span>Источник: </span>
              <b>{provider}</b>
            </p>
            <p>
              <span>Менеджер: </span>
              <b>{author && `${author.first_name} ${author.last_name}`}</b>
            </p>
            <p>
              <span>Туры обновлены: </span>
              <b>{actualization_status ? toursUpdatedAt : 'Не обновлялись'}</b>
            </p>
          </div>
          <div className="report__list">
            <div className="report__item">
              <div className="delivered">
                <b>{stat_views_count}</b>
                <br />
                <span>Просмотров</span>
                {views_customers_exists && (
                  <span
                    style={{ display: 'inline-flex', fontSize: '14px' }}
                    title="показать просмотревших пользователей"
                    onClick={toggleShowModal}
                  >
                    <i className="eye fa fa-eye mr-1"></i>
                  </span>
                )}
              </div>
            </div>
            <div className="report__item">
              <div className="read">
                <b>{stat_clicks_count}</b>
                <br />
                <span>Кликов</span>
              </div>
            </div>
            <div className="report__item">
              <div className="error">
                <b>{stat_likes_count}</b>
                <br />
                <span>Лайков</span>
              </div>
            </div>
          </div>
        </>
      )}
      <div className="geopick__input-container mt-2">
        <div className="geopick__input" style={{ position: 'relative' }}>
          <input
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e, 'title')}
            title="Название подборки"
            placeholder="Название подборки"
            value={title}
          />
        </div>
        <div className="geopick__input">
          <textarea
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e, 'description')}
            title="Описание подборки"
            placeholder="Описание подборки"
            value={description}
          ></textarea>
        </div>
        <div className="geopick__input">
          <textarea
            type="text"
            className="form-control"
            onChange={(e) => handleChange(e, 'bottom_text')}
            title="Нижний текст"
            placeholder="Нижний текст"
            value={bottom_text}
          ></textarea>
        </div>
        {!saveDisabled ? (
          <button
            className={`btn btn-success geopick__save-btn${
              saveDisabled ? '_hidden' : ''
            }`}
            onClick={handleSaveTitle}
          >
            Cохранить
          </button>
        ) : null}
      </div>
      <QuoteTourists
        personList={persons}
        touristsFpList={touristsFp}
        title={title}
        quote_id={quoteId}
      />
      {tours ? (
        <ToursList
          tours={tours}
          handleTourManagerDataSave={handleTourManagerDataSave}
          userRole={userRole}
          quote_id={quoteId}
          onDelete={handleTourDelete}
        />
      ) : null}
      <div className="geopick__btn-container">
        <button
          className="btn btn-success"
          onClick={() => handleCopyMessageToBuffer(false)}
          disabled={is_buffer}
        >
          {is_buffer ? 'Скопировано' : 'Скопировать ссылку'}
        </button>
        <button
          className="btn btn-warning"
          onClick={() => handleCopyMessageToBuffer(true)}
        >
          {is_buffer_with_text ? `Скопировано` : `Ссылка с текстом`}
        </button>
        <button onClick={handleSharedVK} className="btn btn-primary">
          <i className="fa fa-vk pr-2"></i> Поделиться Вконтакте
        </button>
        <a
          className="btn btn-success"
          href={`whatsapp://send?text=${text}&phone=&abid=`}
        >
          <i className="fa fa-whatsapp pr-2"></i> Отправить в whatsapp
        </a>
      </div>
      <div className="mt-5 mb-5">
        <BackButton />
      </div>
    </>
  );
};

export default Quote;
