import 'moment/locale/ru';
import moment from 'moment';

import React, { Component, useEffect, useState } from 'react';
import Select from 'react-select';
import { Card, CardBody, CardHeader } from 'reactstrap';
import { CITY_POPULATION } from './City';
import {
  FranchiseApplicationAPI,
  POSITIVE_ACTION_STATUSES,
  UsersAPI,
} from '../../api/Client';
import { LEGAL_ENTITIES } from './Applicant';
import { Link, useParams } from 'react-router-dom';
import { OFFICE_AFFILIATION } from './Office';
import BackButton from '../../components/BackButton/BackButton';
import {
  FRANCHISE_MANAGER_OPTIONS,
  FRANCHISE_SOURCE_OPTIONS,
  FRANCHISE_STATUS_OPTIONS,
} from './constants';

function FranchiseApplication() {
  const { applicationId } = useParams();

  const [applicationInfo, setApplicationInfo] = useState({});
  const [status, setStatus] = useState('');
  const [source, setSource] = useState('');
  const [manager, setManager] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    setIsLoading(true);
    getInfo();
  }, []);

  function getInfo() {
    let resStatus;
    FranchiseApplicationAPI.fetchJSON(`${applicationId}/`)
      .then((r) => {
        resStatus = r.status;
        return r.json();
      })
      .then((r) => {
        if (resStatus === POSITIVE_ACTION_STATUSES.retrieve) {
          setApplicationInfo(r);
          setStatus(r.status);
          setSource(r.source);
          setManager(r.manager ? r.manager.id : '');
          setIsLoading(false);
        }
      });
  }

  function updateStatus(status) {
    let resStatus;
    setStatus(status);
    FranchiseApplicationAPI.update(
      `${FranchiseApplicationAPI.resource_url}${applicationId}`,
      { status: status.value }
    )
      .then((r) => {
        resStatus = r.status;
        return r.json();
      })
      .then((r) => {
        if (resStatus === POSITIVE_ACTION_STATUSES.partial_update) {
          console.log('статус обновлен');
        } else {
          console.log('статус не обновлен');
        }
      });
  }

  function updateSource(source) {
    let resStatus;
    setSource(source);
    FranchiseApplicationAPI.update(
      `${FranchiseApplicationAPI.resource_url}${applicationId}`,
      { source: source.value }
    )
      .then((r) => {
        resStatus = r.status;
        return r.json();
      })
      .then((r) => {
        if (resStatus === POSITIVE_ACTION_STATUSES.partial_update) {
          console.log('источник обновлен');
        } else {
          console.log('источник не обновлен');
        }
      });
  }

  function handleManagersOnChange(manager) {
    let resStatus;
    setManager(manager);
    FranchiseApplicationAPI.update(
      `${FranchiseApplicationAPI.resource_url}${applicationId}`,
      { manager: manager.value }
    )
      .then((r) => {
        resStatus = r.status;
        return r.json();
      })
      .then((r) => {
        if (resStatus === POSITIVE_ACTION_STATUSES.partial_update) {
          console.log('менеджер обновлен');
        } else {
          console.log('менеджер не обновлен');
        }
      });
  }

  const { id, applicant, utm_labels, office, comment } = applicationInfo;
  console.log(FRANCHISE_MANAGER_OPTIONS.find((item) => item.value*1 === manager*1))

  return isLoading ? null : (
    <div className="animated fadeIn filter filter--green">
      <Card>
        <CardHeader>
          <h4>Заявка № {id}</h4>
        </CardHeader>
        <CardBody className="card-body_responsive">
          <div className="row">
            <div className="col-md-6 col-lg-6 mb-4">
              <h5>Заявитель</h5>
              <p>
                ФИО: <b>{applicant.name}</b>
              </p>
              <p>
                Телефон: <b>{applicant.phone}</b>
              </p>
              <p>
                E-mail: <b>{applicant.email}</b>
              </p>
              <p>
                Возраст (лет): <b>{applicant.age}</b>
              </p>
              <p>
                Имеет опыт работы в туризме:{' '}
                {applicant.have_tourism_experience ? 'Да' : 'Нет'}
              </p>
              <p>Посещенные страны: {applicant.countries}</p>
              <p>Опыт ведения бизнеса: {applicant.business_experience}</p>
              <p>
                Тип юридического лица: {LEGAL_ENTITIES[applicant.legal_entity]}
              </p>
              <p>Дополнительные сведения: {applicant.additional_information}</p>
              <p>Регистрация в ФСУ: {applicant.fp_registration}</p>
              <p>Договор: {applicant.contract}</p>
              <p>
                Дата договора:{' '}
                {applicant.contract_date
                  ? moment(applicant.contract_date).format('DD.MM.YY')
                  : null}
              </p>
              <p>Обучение: {applicant.training}</p>
              <p>Публикация на сайте: {applicant.site_publication}</p>
              <p>Коммуникатор: {applicant.communicator}</p>
              <p>Бонусы: {applicant.bonuses}</p>
              <p>Рейтинг в сети: {applicant.rating}</p>
              <p>UTM-метки: {utm_labels && JSON.stringify(utm_labels)}</p>
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <h5>Офис</h5>
              <p>Адрес офиса: {office.address}</p>
              <p>Условия по офису: {OFFICE_AFFILIATION[office.affiliation]}</p>
              <p>
                Есть ли отдельный вход:{' '}
                {office.is_separate_entrance ? 'Да' : 'Нет'}
              </p>
              <p>
                Возможность размещения вывески:{' '}
                {office.is_possibility_of_placing_signage ? 'Да' : 'Нет'}
              </p>
              <p>Город: {office.city.name}</p>
              <p>
                Численность населения: {CITY_POPULATION[office.city.population]}
              </p>
              <div className="mb-2">
                Статус:
                <Select
                  id="franchaise_status"
                  name="status"
                  style={{ width: '400px' }}
                  placeholder="Статус"
                  simpleValue={true}
                  closeOnSelect={true}
                  value={FRANCHISE_STATUS_OPTIONS.find((item) => item.value === status)}
                  onChange={updateStatus}
                  options={FRANCHISE_STATUS_OPTIONS}
                />
              </div>
              <div>
                Источник:
                <Select
                  id="franchaise_source"
                  name="source"
                  style={{ width: '400px' }}
                  placeholder="Источник"
                  simpleValue={true}
                  closeOnSelect={true}
                  value={FRANCHISE_SOURCE_OPTIONS.find((item) => item.value === source)}
                  onChange={updateSource}
                  options={FRANCHISE_SOURCE_OPTIONS}
                />
              </div>
              <div>
                {' '}
                Менеджер:
                <Select
                  style={{ width: '400px' }}
                  id="manager"
                  placeholder="Выберите менеджера из спиcка..."
                  name="manager"
                  value={FRANCHISE_MANAGER_OPTIONS.find((item) => item.value*1 === manager*1)}
                  simpleValue={true}
                  onChange={handleManagersOnChange}
                  options={FRANCHISE_MANAGER_OPTIONS}
                />
              </div>
              {comment ? (
                <>
                  <h5 className="py-2">Примечание</h5>
                  <p>
                    Текст: <b>{comment}</b>
                  </p>
                </>
              ) : null}
            </div>
            <div className="col-md-6 col-lg-6 mb-4">
              <Link to={`/franchising/${id}/edit/`}>Редактировать</Link>
            </div>
          </div>
          <BackButton />
        </CardBody>
      </Card>
    </div>
  );
}

export default FranchiseApplication;
