import React, { Component } from 'react';
import { connect } from 'react-redux';
import Select from 'react-select';
import { POSITIVE_ACTION_STATUSES, UsersAPI } from '../../api/Client';
import { requestManagersByStateLoaded } from '../../actions/actions';

class ManagersSelect extends Component {
  constructor(props) {
    super(props);
    this.handleManagersOnChange = this.handleManagersOnChange.bind(this);
  }

  handleManagersOnChange(v) {
    let selectedManager
    if (Array.isArray(v)) {
      selectedManager = v.map((manager) => {
        if (manager.hasOwnProperty('value')) {
          return manager;
        } else {
          return manager;
        }
      });
    } else {
      selectedManager = v
    }
    this.props.changeManager(selectedManager);
  }

  componentDidMount() {
    let self = this;
    let status;
    UsersAPI.fetchJSON(`full_list/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        let data = status === POSITIVE_ACTION_STATUSES.list ? r : [],
          managers_data = [];
        let managers_opt = [];
        data.forEach(function (manager) {
          managers_opt.push({
            value: manager.id,
            label:
              !manager.last_name && !manager.first_name
                ? manager.username
                : [manager.last_name, manager.first_name].join(' '),
          });
        });

        managers_data.push({
          options: managers_opt,
        });
        self.props.requestManagersByStateLoaded(managers_data);
      });

    if (this.props.manager_selected) {
      this.handleManagersOnChange(this.props.manager_selected);
    }
  }

  render() {
    return (
      <div>
        <Select
          isMulti={this.props.isMulti}
          disabled={!!this.props.disabledSelect}
          onChange={this.handleManagersOnChange}
          options={this.props.managers.data}
          placeholder="Менеджер"
          isLoading={!this.props.managers.isFetching}
          value={this.props.manager_selected}
          isClearable
        />
      </div>
    );
  }
}

ManagersSelect.displayName = 'ManagersSelect';

const mapStateToProps = function (store) {
  return {
    managers: store.managers,
  };
};

const mapDispatchToProps = {
  requestManagersByStateLoaded,
};

export default connect(mapStateToProps, mapDispatchToProps)(ManagersSelect);
