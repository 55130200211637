import React, { Component } from 'react';
import IssueMerger from './components/IssueMerger';
import ProgressBar from '../../components/ProgressBar';

class IssuesMergers extends Component {
  constructor(props) {
    super(props);
  }

  render() {
    const { items = [], handleSkipIssue } = this.props;
    return (
      <ul id="dashboard__orders" className="list-group">
        {this.props.isLoading ? (
          <ProgressBar />
        ) : (
          items.map((item, idx) => (
            <IssueMerger
              issue={item}
              key={idx}
              handleSkipIssue={handleSkipIssue}
            />
          ))
        )}
      </ul>
    );
  }
}

export default IssuesMergers;
