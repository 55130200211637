import React, {Component} from 'react';
import {MergersAPI, PersonMergersAPI, TouristMergersAPI} from '../../api/Client';
import Paginator from '../../components/Paginator/Paginator';
import Client from './components/Client';
import {Alert} from 'reactstrap';

class Mergers extends Component {
  constructor(props) {
    super(props);

    this.fetchMergers = this.fetchMergers.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handleClientAction = this.handleClientAction.bind(this);

    this.state = {
      mergers: [],
      count: 0,
      page: 1,
      hasNext: false,
      hasPrev: false,
      actionMessages: [],
      isError: false,
    };
  }

  componentDidMount() {
    this.fetchMergers(1);
  }

  fetchMergers(page = 1) {
    const {client, client_id} = this.props;
    const responseHandler = r => r.json();
    const resultHandler = result => {
      this.setState({
        ...this.state,
        page: page,
        mergers: result.results || [],
        count: result.count || 0,
        hasNext: result.next != null,
        hasPrev: result.previous != null,
      });
    };

    if (client === 'person_tourist') {
      MergersAPI.
          fetchList(page, {tourist: client_id}).
          then(responseHandler).
          then(resultHandler);
    } else if (client === 'person') {
      PersonMergersAPI.
          fetchList(page, {person: client_id}).
          then(responseHandler).
          then(resultHandler);
    } else if (client === 'tourist') {
      TouristMergersAPI.
          fetchList(page, {tourist: client_id}).
          then(responseHandler).
          then(resultHandler);
    }
  };

  handlePageChange(data) {
    let page = data.selected + 1;
    this.fetchMergers(page);
  }

  handleClientAction(obj) {
    if (!obj.isError) {
      this.state.mergers.map(item => {
        if (obj.result.id === item.id) {
          item.is_cancelled = obj.result.is_cancelled;
          item.cancelled = obj.result.cancelled;
          item.canceler = obj.result.canceler;
        }
      });
    }
    this.setState(obj.isError ? {
          ...this.state,
          actionMessages: obj.messages,
          isError: obj.isError,
        } : {
          ...this.state,
          actionMessages: obj.messages,
          isError: obj.isError,
        },
    );
  }

  render() {
    const {actionMessages, count, mergers, isError, page} = this.state;
    const {client} = this.props;
    const clientName = client === `tourist` ? `Туристов` : `Потенциальных клиентов`;
    const clientTable = client === `tourist` ? `blue` : `green`;
    return (
        <div className="mergers">
          <div className="animated fadeIn">
            <div className="row">
              <div className="col-lg-12">
                <div className="card">
                  <div className="card-header">
                    <i className="fa fa-align-justify"></i> {clientName} &nbsp;
                    <span className="badge badge-info">{count}</span>
                    {actionMessages.length ? (
                        <div className="row">{actionMessages.map((item, index) => (
                            <div className="col-md-12" key={index}>
                              <Alert className={isError ? `bg-danger` : ``}>{item}</Alert>
                            </div>
                        ))}</div>
                    ) : ``}
                  </div>
                  <div className="card-body card-body_responsive">
                    <table className={`table table--${clientTable} table-bordered table-striped table-sm`}>
                      <thead>
                      <tr>
                        <th>Id</th>
                        <th>ФИО</th>
                        <th>Email</th>
                        <th>Телефон</th>
                        <th>Автор</th>
                        <th>Дата создания</th>
                        <th>Отменивший</th>
                        <th>Дата отмены</th>
                        <th>Статус</th>
                        <th>Обработано</th>
                      </tr>
                      </thead>
                      <tbody>{!!mergers ? mergers.map((item, idx) => (
                          <Client key={idx} merger={item} client={client} clientAction={this.handleClientAction}/>
                      )) : ''}</tbody>
                    </table>
                    <Paginator total={count} onPageChange={this.handlePageChange} forcePage={page - 1}/>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
    );
  }
}

export default Mergers;
