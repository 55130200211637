import React, { PureComponent } from 'react';
import Select from 'react-select';

import { IssuesAPI } from '../../api/Client';

import IssuesReportFilter from './components/IssuesReportFilter';
import IssuesSourceReportTable from './components/IssuesSourceReportTable';
import IssuesManagerReportTable from './components/IssuesManagerReportTable';
import IssuesManagerSourceReportTable from './components/IssuesManagerSourceReportTable';

export default class IssuesReport extends PureComponent {
  constructor(props) {
    super(props);
    this.handlerSource = this.handlerSource.bind(this);
    this.handlerFilter = this.handlerFilter.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.handlerSort = this.handlerSort.bind(this);

    this.state = {
      type: 'source',
      filter: '',
      order: '',
      orderPrev: '',
      items: [],
      total: {
        new_total: 0,
        active_total: 0,
        meet_total: 0,
        cancel_total: 0,
        deffered_total: 0,
        tours_total: 0,
        call_total: 0,
        order_total: 0,
        we_cancel_total: 0,
        client_cancel_total: 0,
      },
      sortToggle: true,
      pageNumber: 1,
    };
  }

  sourcesList = [
    { label: 'По источникам', value: 'source' },
    { label: 'По менеджерам', value: 'manager' },
    { label: 'По источникам менеджера', value: 'manager_source' },
  ];

  handlerFilter(filter) {
    this.setState(
      {
        filter: filter,
      },
      () => this.fetchItems(1, filter)
    );
  }
  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () =>
      this.fetchItems(page, this.state.filter)
    );
  }
  handlerSort(param) {
    this.setState(
      {
        order: param,
        orderPrev: this.state.order,
        sortToggle: this.state.order != param ? true : !this.state.sortToggle,
      },
      () => this.fetchItems(1, this.state.filter)
    );
  }
  handlerSource({ value: type }) {
    this.setState({ type }, () => this.fetchItems(1, this.state.filter));
  }

  _sortedItems(arr) {
    let newArr = arr;
    return newArr;
  }

  fetchItems(page = 1, filters = {}) {
    let filter = filters;
    if (this.state.order) {
      filter = {
        ...filter,
        order_by: `${this.state.sortToggle ? '-' : ''}${this.state.order}`,
      };
    }
    if (this.state.type == 'source') {
      IssuesAPI.fetchSourceReport(page, filter)
        .then((r) => r.json())
        .then((res) => {
          let items = this._sortedItems(res.table);
          this.setState({
            items: items || [],
            total: res.table_total,
          });
        });
    } else if (this.state.type == 'manager_source') {
      IssuesAPI.fetchManagerSourceReport(page, filter)
        .then((r) => r.json())
        .then((res) => {
          let items = this._sortedItems(res.table);
          this.setState({
            items: items || [],
            total: res.table_total,
          });
        });
    } else {
      IssuesAPI.fetchManagersReport(page, filter)
        .then((r) => r.json())
        .then((res) => {
          this.setState({
            items: res.table || [],
            total: res.table_total,
          });
        });
    }
  }
  componentDidMount() {
    this.fetchItems();
  }
  render() {
    const { items, total, filter, type, source } = this.state;
    return (
      <div className="issues-report filter-container">
        <div className="filter filter--green">
          <div className="card">
            <div className="card-header">
              <strong>Отчет по источникам запросов и по менеджерам</strong>
            </div>
            <div className="card-body">
              <div className="filter-row">
                <div className="filter-field ">
                  <Select
                    delimiter={true}
                    onChange={this.handlerSource}
                    options={this.sourcesList}
                    onREset
                    placeholder="Отфильтровать"
                    value={source}
                  />
                </div>
              </div>
              <IssuesReportFilter
                initialFilter={filter}
                onFilter={this.handlerFilter}
              />
            </div>
          </div>
        </div>
        <div className="issues-report-table__container">
          {type == 'manager_source' && (
            <IssuesManagerSourceReportTable
              items={items}
              total={total}
              filter={filter}
              onSort={this.handlerSort}
            />
          )}
          {type == 'source' && (
            <IssuesSourceReportTable
              items={items}
              total={total}
              filter={filter}
              onSort={this.handlerSort}
            />
          )}
          {type == 'manager' && (
            <IssuesManagerReportTable
              items={items}
              total={total}
              filter={filter}
              onSort={this.handlerSort}
            />
          )}
        </div>
      </div>
    );
  }
}
