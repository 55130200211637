import React, { Component } from 'react';
import moment from 'moment';
import 'moment/locale/ru';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import IssueCommentForm from './IssueCommentForm';
import IssueSendSmsForm from './IssueSendSmsForm';
import IssueSendEmailForm from './IssueSendEmailForm';
import IssueHandlingObjections from './IssueHandlingObjections';
import IssueFinally from './IssueFinally';
import IssueSaleRules from './IssueSaleRules';
import IssueApproach from './IssueApproach';
import IssuePresentation from './IssuePresentation';
import IssueNeeds from './IssueNeeds';
import CommentsList from './CommentsList';
import IssueCompleteForm from './IssueCompleteForm';
import IssueManagerForm from './IssueManagerForm';
import { ADMIN, getUserRole, SUPER_ADMIN } from '../../../connect/auth';
import IssueMergerForm from './IssueMergerForm';
import IssueDeleteForm from './IssueDeleteForm';
import IssueQuoteAttach from './IssueQuoteAttach';

export const ISSUE_STATUSES = {
  new: 'new',
  call: 'call',
  tours: 'tours',
  meet: 'meet',
  client_cancel: 'client_cancel',
  we_cancel: 'we_cancel',
  active: 'active',
  order: 'order',
  cancel: 'cancel',
  deffered: 'deffered',
};

export const STATUS_DESCRIPTION_MAP = {
  new: 'Новая',
  call: 'Звонок',
  tours: 'Отправлена подборка',
  meet: 'Назначено событие в календаре',
  client_cancel: 'Турист отказался',
  we_cancel: 'Мы отказали',
  work: 'Открыт',
  order: 'Покупка',
  cancelled: 'Аннулирована',
  refused: 'Отклонен',
  deffered: 'Отложить до',
  quotes_from_crm: 'Подборка из CRM',
};

export const ISSUE_STATUSES_LANG = {
  [ISSUE_STATUSES.new]: 'Новый',
  [ISSUE_STATUSES.active]: 'Открыт',
  [ISSUE_STATUSES.meet]: 'Событие в календаре',
  [ISSUE_STATUSES.deffered]: 'Отложен',
  [ISSUE_STATUSES.tours]: 'Отправлена подборка',
  [ISSUE_STATUSES.cancel]: 'Аннулирована',
  [ISSUE_STATUSES.order]: 'Покупка',
  [ISSUE_STATUSES.call]: 'Звонок',
  [ISSUE_STATUSES.we_cancel]: 'Мы отказали',
  [ISSUE_STATUSES.client_cancel]: 'Турист отказался',
};

export const ISSUE_SOURCE_MAP = {
  call_to_office: {
    name: 'Звонок в офис',
    icon: 'fa-phone',
  },
  call_to_phone: {
    name: 'Звонок на сотовый',
    icon: 'fa-mobile',
  },
  visitor: {
    name: 'Посетитель',
    icon: 'fa-user',
  },
  geo_site: {
    name: 'Сайт',
    icon: 'fa-star',
  },
  geo_site_chat: {
    name: 'Сайт (чат)',
    icon: 'fa-star',
  },
  geo_site_form_hot: {
    name: 'Сайт (горящая заявка)',
    icon: 'fa-star',
  },
  geo_site_quote: {
    name: 'Геоподборка',
    icon: 'fa-star',
  },
  agency_tour: {
    name: 'Сайт (Агентский поисковик)',
    icon: 'fa-star',
  },
  cabinet: {
    name: 'ЛК (офис)',
    icon: 'fa-star-half-o',
  },
  cabinet_online: {
    name: 'ЛК (покупка онлайн)',
    icon: 'fa-star-half-o',
  },
  cabinet_tourist: {
    name: 'Кабинет туриста',
    icon: 'fa-star-half-o',
  },
  excel: {
    name: 'Выгрузка excel',
    icon: 'fa-file',
  },
  recommendation: {
    name: 'Рекомендация',
    icon: 'fa-hand-peace-o',
  },
  permanent: {
    name: 'Постоянный клиент',
    icon: 'fa-star',
  },
  instagram: {
    name: 'Инстаграм',
    icon: 'fa-instagram',
  },
  vkontakte: {
    name: 'ВКонтакте',
    icon: 'fa-vk',
  },
  facebook: {
    name: 'Фейсбук',
    icon: 'fa-facebook',
  },
  odnoklasniki: {
    name: 'Однокласники',
    icon: 'fa-odnoklassniki',
  },
  socials: {
    name: 'Соцсети',
    icon: 'fa-share-alt',
  },
  mailing: {
    name: 'Рассылки',
    icon: 'fa-paper-plane-o',
  },
  mailing_whatsapp: {
    name: 'Рассылка whatsapp',
    icon: 'fa-whatsapp',
  },
  viber: {
    name: 'Вайбер',
    icon: 'fa-weixin',
  },
  telegram: {
    name: 'Телеграм',
    icon: 'fa-send',
  },
  whatsapp: {
    name: 'whatsapp',
    icon: 'fa-whatsapp',
  },
  quotes_from_crm: {
    name: 'Геоподборка',
    icon: 'fa-star',
  },
  yandex: {
    name: 'Яндекс',
    icon: 'fa-star',
  },
  google: {
    name: 'Гугл',
    icon: 'fa-star',
  },
  geo_site_subscribe: {
    name: 'Сайт (подписка Тусич)',
    icon: 'fa-star',
  },
  tourvisor: {
    name: 'Турвизор',
    icon: 'fa-star',
  },
};

export default class IssueItem extends Component {
  constructor(props) {
    super(props);

    this.toggle = this.toggle.bind(this);
    this.onCreate = this.onCreate.bind(this);
    this.onValueChange = this.onValueChange.bind(this);
    this.onMessageTypeChange = this.onMessageTypeChange.bind(this);

    this.state = {
      is_show: false,
      activeTab: '0',
      selectedOption: 'order',
      selectedMessageType: 'email',
    };
  }

  onValueChange(e) {
    this.setState({
      selectedOption: e.target.value,
    });
  }

  onMessageTypeChange(e) {
    this.setState({
      selectedMessageType: e.target.value,
    });
  }

  toggleTab(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  toggle() {
    this.setState({
      ...this.state,
      is_show: !this.state.is_show,
    });
  }

  onCreate() {
    this.setState({
      is_show: false,
    });
    this.props.onCreate.bind(this)();
  }

  render() {
    const { item } = this.props;
    const city = item.city ? item.city : 'не указан';
    const { activeTab } = this.state;
    const userRole = getUserRole();
    const IS_LOCK = item.status === 'order';

    function parseDescriptionIssue(text) {
      let Tour = {};
      try {
        text
          .split(',')
          .map((f) =>
            console.log((Tour[f.split(':')[0].trim()] = f.split(':')[1].trim()))
          );
      } catch (e) {
        return text;
      }
      return (
        <div>
          <p>
            <span>{Tour.country}</span>, <span>{Tour.town}</span>
          </p>
          <p>
            Отель:{' '}
            <span>
              {Tour.hotel} {Tour.star_name}*
            </span>
          </p>
          <p>
            Тип номера: <span>{Tour.room}</span>
          </p>
          <p>
            Питание: <span>{Tour.meal}</span>
          </p>
          <p>
            Туроператор: <span>{Tour.oper}</span>
          </p>
          <p>
            Даты вылета:{' '}
            <span>
              {Tour.dt1} - {Tour.dt2}
            </span>
          </p>
          <p>
            Взрослых: <span>{Tour.adult}</span>
          </p>
          {!!parseInt(Tour.child) && (
            <p>
              <span>Детей:</span>
              <span>{Tour.child}</span>
            </p>
          )}
          <p>
            Город вылета: <span>{Tour.depart_name}</span>
          </p>
          {Tour.hasOwnProperty('amount') && (
            <p>
              <span>Стоимость:</span>
              <span>{Tour.amount}</span>
            </p>
          )}
          <p>
            Валюта: <span>{Tour.currency}</span>
          </p>
        </div>
      );
    }

    function parseTitleIssue(text) {
      let Tour = {};
      try {
        text
          .split(',')
          .map((f) =>
            console.log((Tour[f.split(':')[0].trim()] = f.split(':')[1].trim()))
          );
      } catch (e) {
        return text;
      }
      return (
        <span>
          {Tour.country}, {Tour.town} {Tour.dt1} - {Tour.dt2}
        </span>
      );
    }

    return (
      <div className="card card-accent-success">
        <div className="card-header">
          <span className="badge badge-primary badge-pill"> №{item.id} </span>
          <span> {parseTitleIssue(item.title)} </span>
          <small>от {moment(item.created).format('DD.MM.YY HH:mm')}</small>
          <span className="badge badge-success badge-pill float-right">
            {ISSUE_STATUSES_LANG[item.status]}
          </span>
        </div>
        <div className="card-body">
          <div className="issues-tab-list">
            <Nav tabs>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '7' })}
                    onClick={() => {
                      this.toggleTab('7');
                    }}
                  >
                    <i className="fa fa-user">{''}</i> Выбрать менеджера
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => {
                    this.toggleTab('1');
                  }}
                >
                  <i className="fa fa-comment">{''}</i> Комментарий
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '10' })}
                  onClick={() => {
                    this.toggleTab('10');
                  }}
                >
                  <i className="fa fa-phone">{''}</i> Назначить звонок
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => {
                    this.toggleTab('2');
                  }}
                >
                  <i className="fa fa-calendar">{''}</i> Добавить событие в
                  календарь
                </NavLink>
              </NavItem>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '25' })}
                    onClick={() => {
                      this.toggleTab('25');
                    }}
                  >
                    <i className="fa fa-at">{''}</i> Отправить SMS
                  </NavLink>
                </NavItem>
              )}
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '9' })}
                  onClick={() => {
                    this.toggleTab('9');
                  }}
                >
                  <i className="fa fa-trash">{''}</i> Удалить
                </NavLink>
              </NavItem>
            </Nav>
            <Nav>
              <NavItem>
                <NavLink
                  className={classnames({
                    active: activeTab === '22',
                    'nav-link--orange': 'nav-link--orange',
                  })}
                  style={{ background: '#f0b504' }}
                  onClick={() => {
                    this.toggleTab('22');
                  }}
                >
                  <i className="fa fa-check-square-o">{''}</i> Выявление
                  потребностей
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  style={{ background: '#f0b504' }}
                  onClick={() => {
                    this.toggleTab('5');
                  }}
                >
                  <i className="fa fa-tasks">{''}</i> Подборка туров
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '18' })}
                  style={{ background: '#f0b504' }}
                  onClick={() => {
                    this.toggleTab('18');
                  }}
                >
                  <i className="fa fa-star">{''}</i> Презентация
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '20' })}
                  style={{ background: '#f0b504' }}
                  onClick={() => {
                    this.toggleTab('20');
                  }}
                >
                  <i className="fa fa-hourglass-half">{''}</i> Работа с
                  возраженьями
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  style={{ background: '#f0b504' }}
                  onClick={() => {
                    this.toggleTab('3');
                  }}
                >
                  <i className="fa fa-circle">{''}</i> Покупка/Отложить/Отказ
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '21' })}
                  style={{ background: '#f0b504' }}
                  onClick={() => {
                    this.toggleTab('21');
                  }}
                >
                  <i className="fa fa-history">{''}</i> Финальное письмо
                </NavLink>
              </NavItem>
              {item.order && item.person ? (
                <NavItem>
                  <NavLink
                    className={classnames({ active: activeTab === '8' })}
                    onClick={() => {
                      this.toggleTab('8');
                    }}
                  >
                    <i className="fa fa-tasks">{''}</i> Слияние
                  </NavLink>
                </NavItem>
              ) : (
                ''
              )}
            </Nav>
            <TabContent className="col-md-12" activeTab={activeTab}>
              <TabPane tabId="1">
                <IssueCommentForm
                  id={item.id}
                  type={activeTab}
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="2">
                <IssueCommentForm
                  id={item.id}
                  type={activeTab}
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="10">
                <IssueCommentForm
                  id={item.id}
                  type={activeTab}
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="3">
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="order"
                      checked={this.state.selectedOption === 'order'}
                      onChange={this.onValueChange}
                    />
                    Покупка тура
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="4"
                      checked={this.state.selectedOption === '4'}
                      onChange={this.onValueChange}
                    />
                    Отложить до
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="we_cancel"
                      checked={this.state.selectedOption === 'we_cancel'}
                      onChange={this.onValueChange}
                    />
                    Мы отказали
                  </label>
                </div>
                <div className="radio">
                  <label>
                    <input
                      type="radio"
                      value="client_cancel"
                      checked={this.state.selectedOption === 'client_cancel'}
                      onChange={this.onValueChange}
                    />
                    Клиент отказался
                  </label>
                </div>
                {this.state.selectedOption === 'order' ? (
                  <IssueCompleteForm
                    id={item.id}
                    type={activeTab}
                    fp_number={item.fp_number}
                    isLock={IS_LOCK}
                  />
                ) : (
                  <IssueCommentForm
                    id={item.id}
                    type={this.state.selectedOption}
                    onCreate={this.onCreate}
                    isLock={IS_LOCK}
                  />
                )}
              </TabPane>
              <TabPane tabId="4">
                <IssueCommentForm
                  id={item.id}
                  type={activeTab}
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="5">
                <IssueQuoteAttach
                  id={item.id}
                  info={this.props.info}
                  onCreate={this.onCreate}
                  isLock={IS_LOCK}
                />
              </TabPane>
              <TabPane tabId="6">
                <IssueCompleteForm
                  id={item.id}
                  type={activeTab}
                  fp_number={item.fp_number}
                  isLock={IS_LOCK}
                />
              </TabPane>
              {(userRole === SUPER_ADMIN || userRole === ADMIN) && (
                <TabPane tabId="7">
                  <IssueManagerForm
                    id={item.id}
                    isLock={IS_LOCK}
                    manager={item.manager}
                    type={activeTab}
                    onCreate={this.onCreate}
                  />
                </TabPane>
              )}
              {item.order && item.person && (
                <TabPane tabId="8">
                  <IssueMergerForm
                    issue={item}
                    type={activeTab}
                    onCreate={this.onCreate}
                  />
                </TabPane>
              )}
              <TabPane tabId="9">
                <IssueDeleteForm
                  issueId={item.id}
                  isLock={IS_LOCK}
                  onChangeActivity={this.props.onChangeActivity}
                />
              </TabPane>
              <TabPane tabId="20">
                <IssueHandlingObjections />
              </TabPane>
              <TabPane tabId="19">
                <IssueSaleRules />
              </TabPane>
              <TabPane tabId="18">
                <IssuePresentation />
              </TabPane>
              <TabPane tabId="15">
                <IssueApproach />
              </TabPane>
              <TabPane tabId="21">
                <IssueFinally />
              </TabPane>
              <TabPane tabId="22">
                <IssueNeeds id={item.id} need={item.need} />
              </TabPane>
              <TabPane tabId="25">
                <h4>Сообщение туристу</h4>
                <div className="radio form-check">
                  <label className="form-check-label">
                    <input
                      type="radio"
                      className="form-check-input"
                      value="sms"
                      checked={this.state.selectedMessageType === 'sms'}
                      onChange={this.onMessageTypeChange}
                    />
                    Смс
                  </label>
                </div>
                {this.state.selectedMessageType === 'sms' && (
                  <IssueSendSmsForm
                    id={item.id}
                    item={item}
                    onCreate={this.onCreate}
                    isLock={true}
                  />
                )}
              </TabPane>
            </TabContent>
          </div>

          <div>
            <div className="p-3 mb-3 bg-info text-dark">
              {parseDescriptionIssue(item.text)}
              {item.text.includes('depart_name:')
                ? ''
                : ' - город вылета: ' + city}
            </div>
            <CommentsList comments={item.answers} />
          </div>
        </div>
      </div>
    );
  }
}
