import React, {Component} from 'react';
import {Alert} from 'reactstrap';
import BackButton from '../../components/BackButton';

import {MailigenAPI, OfficeAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import OfficeSelectContainer from '../../components/OfficeSelectContainer';
import FieldErrorViewer from '../Passports/components/FieldErrorViewer';

const TIMEOUT = 2000;

export default class MailigenSetting extends Component {
  constructor(props) {
    super(props);

    this.fetchData = this.fetchData.bind(this);
    this.onSaveData = this.onSaveData.bind(this);
    this.onChangeOffice = this.onChangeOffice.bind(this);
    this.onHandleChecked = this.onHandleChecked.bind(this);
    this.onHandleInput = this.onHandleInput.bind(this);
    this.onDeleteData = this.onDeleteData.bind(this);

    this.state = {
      id: 0,
      key: '',
      permission_reminder: 'Вы подписались на нашу рассылку на www.geograftour.com',
      notify_to: '',
      subscription_notify: true,
      unsubscription_notify: true,
      has_email_type_option: false,
      public_title: 'Турагентство',
      sms_sender_name: '',
      alternate_mobile_number: '',
      concatenate: true,
      clicks_tracking: true,
      sender_name: '',
      sending_email: '',
      company_name: 'Турагентство География',
      company_address: '',
      html_clicks_tracking: false,
      opens_tracking: true,
      google_analytics_key: '',
      officeName: '',
      officeId: '0',

      errorMessages: {},
      isSuccess: false,
      isError: false,
      isDisabledButtons: false,
    };
  }

  fetchData() {
    let status;
    OfficeAPI.
        fetchJSON(`${this.props.match.params.number}/mailigen/`).
        then((r) => {
          status = r.status;
          return r.json();
        }).
        then((result) => {
          if (status === POSITIVE_ACTION_STATUSES.retrieve) {
            if (!!result.mailigen_mailigensetting) {
              this.setState({
                id: result.mailigen_mailigensetting.id,
                key: result.mailigen_mailigensetting.key,
                permission_reminder: result.mailigen_mailigensetting.permission_reminder,
                notify_to: result.mailigen_mailigensetting.notify_to,
                subscription_notify: result.mailigen_mailigensetting.subscription_notify,
                unsubscription_notify: result.mailigen_mailigensetting.unsubscription_notify,
                has_email_type_option: result.mailigen_mailigensetting.has_email_type_option,
                public_title: result.mailigen_mailigensetting.public_title,
                sms_sender_name: result.mailigen_mailigensetting.sms_sender_name,
                alternate_mobile_number: result.mailigen_mailigensetting.alternate_mobile_number,
                concatenate: result.mailigen_mailigensetting.concatenate,
                clicks_tracking: result.mailigen_mailigensetting.clicks_tracking,
                sender_name: result.mailigen_mailigensetting.sender_name,
                sending_email: result.mailigen_mailigensetting.sending_email,
                company_name: result.mailigen_mailigensetting.company_name,
                company_address: result.mailigen_mailigensetting.company_address,
                html_clicks_tracking: result.mailigen_mailigensetting.html_clicks_tracking,
                opens_tracking: result.mailigen_mailigensetting.opens_tracking,
                google_analytics_key: result.mailigen_mailigensetting.google_analytics_key,
                officeName: result.name,
                officeId: result.id.toString(),

                errorMessages: {},
                successMessage: '',
                isSuccess: false,
                isError: false,
              });
            } else {
              this.setState({
                officeName: result.name,
                officeId: result.id.toString(),

                errorMessages: {},
                successMessage: '',
                isSuccess: false,
                isError: false,
              });
            }
          }
        });
  }

  onSaveData() {
    let status = 200;
    const responseHandler = (r) => {
      status = r.status;
      return r.json();
    };
    let action;
    const resultHandler = (r) => {
      if (POSITIVE_ACTION_STATUSES[action] === status) {
        this.setState({
          errorMessages: {},
          successMessage: action === 'create'
              ? 'Настройки созданы. Вы будете перенаправлены.'
              : 'Настройки обновлены.',
          isSuccess: true,
          isError: false,
          isDisabledButtons: action === 'create',
        }, () => {
          if (action === 'create') {
            setTimeout(() => {
              this.props.history.push(`/options/`);
            }, TIMEOUT);
          }
          setTimeout(() => {
            this.fetchData();
          }, TIMEOUT);
        });
      } else {
        this.setState({
          errorMessages: r,
          successMessage: '',
          isDisabledButtons: false,
          isSuccess: false,
          isError: true,
        });
      }
    };
    const data = {
      key: this.state.key,
      permission_reminder: this.state.permission_reminder,
      notify_to: this.state.notify_to,
      subscription_notify: this.state.subscription_notify,
      unsubscription_notify: this.state.unsubscription_notify,
      has_email_type_option: this.state.has_email_type_option,
      public_title: this.state.public_title,
      sms_sender_name: this.state.sms_sender_name,
      alternate_mobile_number: this.state.alternate_mobile_number,
      concatenate: this.state.concatenate,
      clicks_tracking: this.state.clicks_tracking,
      sender_name: this.state.sender_name,
      sending_email: this.state.sending_email,
      company_name: this.state.company_name,
      company_address: this.state.company_address,
      html_clicks_tracking: this.state.html_clicks_tracking,
      opens_tracking: this.state.opens_tracking,
      google_analytics_key: this.state.google_analytics_key,
    };

    if (this.state.id) {
      action = `update`;
      this.setState({
        isDisabledButtons: true,
      }, () => {
        MailigenAPI.
            update(this.state.id, data).
            then(responseHandler).
            then(resultHandler);
      });
    } else if (!this.state.id) {
      action = `create`;
      data.office = this.props.match.params.number;
      this.setState({
        isDisabledButtons: true,
      }, () => {
        MailigenAPI.
            create(data).
            then(responseHandler).
            then(resultHandler);
      });
    }
  }

  onDeleteData() {
    let status;
    this.setState({
      isDisabledButtons: true,
    }, () => {
      MailigenAPI.
          delete(this.state.id).
          then((r) => {
            status = r.status;
            if (POSITIVE_ACTION_STATUSES.destroy !== status) {
              return r.json();
            }
            return {};
          }).
          then((r) => {
            if (POSITIVE_ACTION_STATUSES.destroy === status) {
              this.setState({
                errorMessages: {},
                successMessage: 'Удаление произведено успешно. Вы будете перенаправлены.',
                isSuccess: true,
                isError: false,
              }, () => {
                setTimeout(() => {
                  this.props.history.push(`/options/`);
                }, TIMEOUT);
              });
            } else {
              this.setState({
                errorMessages: r,
                successMessage: '',
                isSuccess: false,
                isError: true,
              });
            }
          });
    });
  }

  onChangeOffice(v) {
  }

  onHandleChecked(e) {
    this.setState({
      [e.target.name]: e.target.checked,
      isSuccess: false,
      isError: false,
    });
  }

  onHandleInput(e) {
    this.setState({
      [e.target.name]: e.target.value,
      isSuccess: false,
      isError: false,
    });
  }

  componentDidMount() {
    this.fetchData();
  }

  render() {
    const {officeName, officeId} = this.state;
    return (
        <div className="card">
          <div className="card-body">
            <div className="form-group row">
              <label className="text-right col-md-2 col-form-label">Офис:</label>
              <div className="col-md-6">
                <OfficeSelectContainer
                    disabledSelect={true}
                    selected_office={{label: officeName, value: officeId}}
                    changeOffice={this.onChangeOffice}
                />
                <FieldErrorViewer errorMessages={this.state.errorMessages} field="office"/>
              </div>
            </div>
            {
              [
                {
                  name: 'key',
                  type: 'text',
                  lang: 'Ключ API',
                  isField: true,
                },
                {
                  name: 'line_one',
                  lang: 'Настройки для списков mailigen',
                  isField: false,
                },
                {
                  name: 'permission_reminder',
                  type: 'text',
                  lang: 'Напоминание для пользователей о том, как они попали в список',
                  isField: true,
                },
                {
                  name: 'notify_to',
                  type: 'text',
                  lang: 'Адрес электронной почты, на который будут отправляться уведомления',
                  isField: true,
                },
                {
                  name: 'subscription_notify',
                  type: 'checkbox',
                  lang: 'Отправлять уведомление о подписке нового пользователя',
                  defaultChecked: true,
                  isField: true,
                },
                {
                  name: 'unsubscription_notify',
                  type: 'checkbox',
                  lang: 'Отправлять уведомление о подписке нового пользователя',
                  defaultChecked: true,
                  isField: true,
                },
                {
                  name: 'has_email_type_option',
                  type: 'checkbox',
                  lang: 'Может ли пользователь выбрать формат электронной почты',
                  defaultChecked: false,
                  isField: true,
                },
                {
                  name: 'public_title',
                  type: 'text',
                  lang: 'Имя, которое будет отображаться в веб-формах регистрации и электронных письмах с подтверждением регистрации',
                  isField: true,
                },
                {
                  name: 'line_two',
                  type: undefined,
                  lang: 'Настройки для СМС рассылок',
                  isField: false,
                },
                {
                  name: 'sms_sender_name',
                  type: 'text',
                  lang: 'Наименование смс отправителя',
                  isField: true,
                },
                {
                  name: 'alternate_mobile_number',
                  type: 'text',
                  lang: 'Альтернативный номер',
                  isField: true,
                },
                {
                  name: 'concatenate',
                  type: 'checkbox',
                  lang: 'Отправлять ли несколько SMS-сообщений, если текст слишком длинный',
                  defaultChecked: true,
                  isField: true,
                },
                {
                  name: 'clicks_tracking',
                  type: 'checkbox',
                  lang: 'Отслеживание кликов',
                  defaultChecked: true,
                  isField: true,
                },
                {
                  name: 'line_three',
                  lang: 'Настройки для Email рассылок',
                  isField: false,
                },
                {
                  name: 'sender_name',
                  type: 'text',
                  lang: 'Наименование отправителя',
                  isField: true,
                },
                {
                  name: 'sending_email',
                  type: 'text',
                  lang: 'Email отправитель',
                  isField: true,
                },
                {
                  name: 'company_name',
                  type: 'text',
                  lang: 'Наименование организации',
                  isField: true,
                },
                {
                  name: 'company_address',
                  type: 'text',
                  lang: 'Адрес организации',
                  isField: true,
                },
                {
                  name: 'html_clicks_tracking',
                  type: 'checkbox',
                  lang: 'Отслеживание кликов при html рассылке',
                  defaultChecked: true,
                  isField: true,
                },
                {
                  name: 'opens_tracking',
                  type: 'checkbox',
                  lang: 'Отслеживание открытий писем',
                  defaultChecked: true,
                  isField: true,
                },
                {
                  name: 'google_analytics_key',
                  type: 'text',
                  lang: 'Ключ google analytics',
                  isField: true,
                },
              ].map((item, idx) => {
                if (item.isField) {
                  if (item.type === 'checkbox') {
                    return (
                        <div className="form-group form-check row" key={idx}>
                          <div className="ml-4 mb-4 col-md-6">
                            <input
                                type={item.type}
                                id={`input-${item.name}-${idx}`}
                                name={item.name}
                                // defaultChecked={item.defaultChecked}
                                // value={this.state[item.name]}
                                checked={this.state[item.name]}
                                onChange={this.onHandleChecked}
                                className="form-check-input"
                            />
                            <label className="form-check-label" htmlFor={`input-${item.name}-${idx}`}>
                              {item.lang}
                            </label>
                            <FieldErrorViewer errorMessages={this.state.errorMessages} field={item.name}/>
                          </div>
                        </div>
                    );
                  }

                  return (
                      <div className="form-group row" key={idx}>
                        <label htmlFor={`input-${item.name}-${idx}`} className="text-right col-md-2 col-form-label">
                          {item.lang}:
                        </label>
                        <div className="col-md-6">
                          <input
                              type={item.type}
                              id={`input-${item.name}-${idx}`}
                              name={item.name}
                              value={this.state[item.name]}
                              onChange={this.onHandleInput}
                              className="form-control"
                          />
                          <FieldErrorViewer errorMessages={this.state.errorMessages} field={item.name}/>
                        </div>
                      </div>
                  );
                }

                return <Alert color="info" key={idx}>{item.lang}</Alert>;
              })
            }
            {this.state.isSuccess && <Alert color="success">{this.state.successMessage}</Alert>}
            {this.state.isError && !!this.state.errorMessages.non_field_errors
                ? this.state.errorMessages.non_field_errors.map((item, idx) => {
                  return <Alert color="warning" key={idx}>{item}</Alert>;
                }) : ``}
            {this.state.isError && !!this.state.errorMessages.detail
                ? <Alert color="warning">Ошибка запроса: {this.state.errorMessages.detail}</Alert>
                : ``}
          </div>
          <div className="card-footer">
            <BackButton />
            <button
                className="btn btn-success"
                onClick={this.onSaveData}
                disabled={this.state.isDisabledButtons}
            >
              Применить
            </button>
            {this.state.id
                ? <button
                    className="btn btn-danger float-right"
                    onClick={this.onDeleteData}
                    disabled={this.state.isDisabledButtons}
                >Удалить</button>
                : ``}
          </div>
        </div>
    );
  }
}
