const routes = {
  '/': 'Главная',
  '/leads': 'Заявки с сайта',
  '/sellbook': 'Книга продаж',
  '/touristcabinets': 'Кабинет туриста',
  '/persons': 'Потенциальные клиенты',
  '/offices': 'Офисы',
  '/employees': 'Сотрудники',
  '/ordersfp': 'Мои заявки',
  '/touristsfp': 'Мои клиенты',
  '/calendar': 'Мой календарь',
  '/notify': 'Уведомления',
  '/dashboard': 'Рабочий стол менеджера',
  '/instructions': 'Инструкция по CRM',
  '/templates': 'СМС и Email',
  '/quotes': 'Геоподборка',
  '/tourists': 'Клиентская база',
  '/tags': 'Создать метку',
  '/mailbox': 'Отправленные рассылки',
  '/notifications': 'Отправленные уведомления',
  '/cashaccounts': 'Оплата рассылок',
  '/agencies': 'Франчи и субы, контроль продаж',
  '/summaries': 'Франчи и субы, работа в CRM',
  '/franchising': 'Заявки на франчайзинг',
  '/subscription': 'E-mail подписчики',
  '/abandonedcustomers': 'Клиенты закрывшихся агентов',
  '/issues': 'Запросы на туры',
  '/issues-report': 'Отчет по запросам',

};
export default routes;
