import React from 'react';
import PropTypes from 'prop-types';
import paginate from '../../../decorators/paginator';

const TagList = ({ tags = [], onDelete }) => (
  <ul className="list-group">
    {tags.map((tag, idx) => (
      <li className="justify-content-between list-group-item" key={idx}>
        <span>
          {tag.name}
          {tag.is_system ? ' (cистемная)' : ''}
        </span>
        <span className="float-right">
          <button className="btn btn-danger" onClick={onDelete} title={tag.id}>
            <i className="fa fa-close"></i>удалить
          </button>
        </span>
      </li>
    ))}
  </ul>
);

TagList.propTypes = {
  tags: PropTypes.array,
};

export default paginate(TagList);
