import React from 'react';
import { Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import classnames from 'classnames';
import './TabUsers.css';
import { Link } from 'react-router-dom';

export default class TabUsers extends React.Component {
  constructor(props) {
    super(props);
    this.toggle = this.toggle.bind(this);
    this.state = {
      activeTab: '1',
    };
  }

  toggle(tab) {
    if (this.state.activeTab !== tab) {
      this.setState({
        activeTab: tab,
      });
    }
  }

  render() {
    return (
      <div>
        <Nav tabs>
          <NavItem className="nav-item-table">
            <NavLink
              className={`nav-link-table ${classnames({
                'active-tab': this.state.activeTab === '1',
              })}`}
              onClick={() => {
                this.toggle('1');
              }}
            >
              Потенциальные клиенты
            </NavLink>
          </NavItem>
          <NavItem className="nav-item-table">
            <NavLink
              className={`nav-link-table ${classnames({
                'active-tab': this.state.activeTab === '2',
              })}`}
              onClick={() => {
                this.toggle('2');
              }}
            >
              Постоянные клиенты
            </NavLink>
          </NavItem>
        </Nav>
        <TabContent style={{ border: 'none' }} activeTab={this.state.activeTab}>
          <TabPane tabId="1">
            <div className="table-responsive table-container-modal">
              <table className="table">
                <thead className="thead-table-modal">
                  <tr>
                    <th>id</th>
                    <th>ФИО</th>
                    <th>Телефон</th>
                    <th>Емайл</th>
                  </tr>
                </thead>
                <tbody className="modal-table-tbody">
                  {this.props.persons.map((el) => (
                    <tr key={el.id}>
                      <td>
                        <Link target="_blank" to={`/persons/${el.id}`}>
                          {el.id}
                        </Link>
                      </td>
                      <td>{el.fio ? el.fio : ''}</td>
                      <td>{el.phone.length ? el.phone[0].value : ''}</td>
                      <td>{el.email.length ? el.email[0].value : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </TabPane>
          <TabPane tabId="2">
            <div className="table-responsive table-container-modal">
              <table className="table">
                <thead className="thead-table-modal">
                  <tr>
                    <th>id</th>
                    <th
                      style={{
                        fontSize: '10px',
                        padding: '15px 0 10px',
                        verticalAlign: 'top',
                      }}
                    >
                      {' '}
                      № заявки в сб
                    </th>
                    <th>Имя</th>
                    <th>Телефон</th>
                    <th>Емайл</th>
                  </tr>
                </thead>
                <tbody className="modal-table-tbody">
                  {this.props.touristfps.map((el) => (
                    <tr key={el.id}>
                      <td>
                        <Link target="_blank" to={`/tourists/${el.id}`}>
                          {el.id}
                        </Link>
                      </td>
                      <td>{el.fp_pk}</td>
                      <td>{el.full_name ? el.full_name : ''}</td>
                      <td>{el.phone.length > 0 ? el.phone[0].value : ''}</td>
                      <td>{el.email.length > 0 ? el.email[0].value : ''}</td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </TabPane>
        </TabContent>
      </div>
    );
  }
}
