import React, { Component } from 'react';
import { IssuesAPI } from '../../../api/Client';
import { CardHeader, Card, CardBody } from 'reactstrap';

export default class IssueNeeds extends Component {
  constructor(props) {
    super(props);

    this.handlerChange = this.handlerChange.bind(this);
    this.handlerSave = this.handlerSave.bind(this);

    this.state = {
      need: {
        destinationName: '',
        destinationChoose: '',
        destinationAlternative: '',
        destinationBefore: '',
        destinationVisited: '',
        destinationLike: '',
        durationDates: '',
        durationDatesImportant: '',
        durationBusy: '',
        durationDays: '',
        transferFlights: '',
        transferGroup: '',
        personsCount: '',
        personsVacation: '',
        personsChildAges: '',
        personsHobby: '',
        personsChildService: '',
        personsPassport: '',
        personsVisaIsActive: '',
        hotelWasBefofe: '',
        hotelOptions: '',
        hotelService: '',
        hotelRoom: '',
        hotelPlace: '',
        hotelMealType: '',
        hotelMeal: '',
        hotelServiceFirst: '',
        hotelAlternative: '',
        hotelAttention: '',
        travelPeriod: '',
        travelImportant: '',
        travelDetail: '',
        travelDreams: '',
        travelSolution: '',
        travelCompanyBefore: '',
        travelCompanyChange: '',
        travelCompanyFeature: '',
        travelSurprise: '',
        travelGroup: '',
        cashSumm: '',
        comment: '',
      },
      isSaveDisabled: false,
    };
  }

  handlerChange(e) {
    this.setState({
      need: {
        ...this.state.need,
        [e.target.name]: e.target.value,
      },
      isSaveDisabled: true,
    });
  }

  handlerSave() {
    this.setState(
      {
        isSaveDisabled: false,
      },
      () => IssuesAPI.modify(this.props.id, { need: this.state.need })
    );
    // this.props.onSave(this.state);
  }

  componentDidMount() {
    if (this.props.need) {
      this.setState({
        need: {
          ...this.props.need,
        },
      });
    }
  }

  render() {
    const need = this.state.need;
    return (
      <>
        <Card className="destination">
          <CardHeader>Страна</CardHeader>
          <CardBody>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Вы уже определились с направлением для путешествия/отдыха?
              </label>
              <div className="col-md-6">
                <input
                  name="destinationName"
                  className="form-control"
                  value={need.destinationName}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Между чем и чем вы выбираете?
              </label>
              <div className="col-md-6">
                <input
                  name="destinationChoose"
                  className="form-control"
                  value={need.destinationChoose}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Рассматриваете ли вы еще какие-то альтернативные варианты
                отдыха?
              </label>
              <div className="col-md-6">
                <input
                  name="destinationAlternative"
                  className="form-control"
                  value={need.destinationAlternative}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Почему вы остановились именно на этой стране? Вы посещали эту
                страну ранее?
              </label>
              <div className="col-md-6">
                <input
                  name="destinationBefore"
                  className="form-control"
                  value={need.destinationbefore}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                В каких странах вы уже были?
              </label>
              <div className="col-md-6">
                <input
                  name="destinationVisited"
                  className="form-control"
                  value={need.destinationVisited}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Что вам наиболее понравилось, запомнилось? Почему?
              </label>
              <div className="col-md-6">
                <input
                  name="destinationLike"
                  className="form-control"
                  value={need.destinationLike}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="duration">
          <CardHeader color="warning">Продолжительность</CardHeader>
          <CardBody>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Планируемые даты поездки?
              </label>
              <div className="col-md-6">
                <input
                  name="durationDates"
                  className="form-control"
                  value={need.durationDates}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Насколько важно, чтоб это были именно такие даты?
              </label>
              <div className="col-md-6">
                <input
                  name="durationDatesImportant"
                  className="form-control"
                  value={need.durationDatesImportant}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Позволяет ли ваша занятость сдвигать даты?
              </label>
              <div className="col-md-6">
                <input
                  name="durationBusy"
                  className="form-control"
                  value={need.durationBusy}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Сколько дней планируете путешествовать?
              </label>
              <div className="col-md-6">
                <input
                  name="durationDays"
                  className="form-control"
                  value={need.durationDays}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader color="info">Транспорт</CardHeader>
          <CardBody>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Есть ли особые требования к авиаперелету? (авиакомпания,
                бизнес-класс, стыковки, ночные перелеты, места и т.п…..)
              </label>
              <div className="col-md-6">
                <input
                  name="transferFlights"
                  className="form-control"
                  value={need.transferFlights}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Какой вам необходим трансфер (групповой или индивидуальный)
              </label>
              <div className="col-md-6">
                <input
                  name="transferGroup"
                  className="form-control"
                  value={need.transferGroup}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="persons">
          <CardHeader>Состав поездки</CardHeader>
          <CardBody>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                В каком составе планируете путешествовать?
              </label>
              <div className="col-md-6">
                <input
                  name="personsCount"
                  className="form-control"
                  value={need.personsCount}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                С кем вы обычно отдыхаете?
              </label>
              <div className="col-md-6">
                <input
                  name="personsVacation"
                  className="form-control"
                  value={need.personsVacation}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Возраст детей (если есть)
              </label>
              <div className="col-md-6">
                <input
                  name="personsChildAges"
                  className="form-control"
                  value={need.personsChildAges}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Какие увлечения у вас и вашей семьи есть и какие из них следует
                учесть при подготовке путешествия?
              </label>
              <div className="col-md-6">
                <input
                  name="personsHobby"
                  className="form-control"
                  value={need.personsHobby}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Какие услуги для детей нужно предусмотреть / забронировать?
              </label>
              <div className="col-md-6">
                <input
                  name="personsChildService"
                  className="form-control"
                  value={need.personsChildService}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                У всех уже есть биометрический паспорт?
              </label>
              <div className="col-md-6">
                <input
                  name="personsPassport"
                  className="form-control"
                  value={need.personsPassport}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Какие открытые визы есть сейчас в паспортах едущих?
              </label>
              <div className="col-md-6">
                <input
                  name="personsVisaIsActive"
                  className="form-control"
                  value={need.personsVisaIsActive}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card className="hotel">
          <CardHeader color="warning">Отель</CardHeader>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              В каких отелях отдыхали ранее?
            </label>
            <div className="col-md-6">
              <input
                name="hotelWasBefofe"
                className="form-control"
                value={need.hotelWasBefofe}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Что обязательно должно быть в вашем отеле?
            </label>
            <div className="col-md-6">
              <input
                name="hotelOptions"
                className="form-control"
                value={need.hotelOptions}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Какие услуги отель должен обязательно предоставить
              (русскоговорящий персонал, бассейн, анимация, детский клуб, ночные
              развлечения…)?
            </label>
            <div className="col-md-6">
              <input
                name="hotelService"
                className="form-control"
                value={need.hotelService}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Какие требования к номеру?
            </label>
            <div className="col-md-6">
              <input
                name="hotelRoom"
                className="form-control"
                value={need.hotelRoom}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Как вам будет удобнее размещаться с детьми?
            </label>
            <div className="col-md-6">
              <input
                name="hotelPlace"
                className="form-control"
                value={need.hotelPlace}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              К какому типу питания вы привыкли? Почему
            </label>
            <div className="col-md-6">
              <input
                name="hotelMealType"
                className="form-control"
                value={need.hotelMealType}
                onChange={this.handlerChange}
              />
            </div>
          </div>

          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Есть ли какие-то особые требования по питанию?
            </label>
            <div className="col-md-6">
              <input
                name="hotelMeal"
                className="form-control"
                value={need.hotelMeal}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Какие дополнительные услуги вы обычно бронируете заранее (няня,
              спа, экскурсии, и т.п.)
            </label>
            <div className="col-md-6">
              <input
                name="hotelServiceFirst"
                className="form-control"
                value={need.hotelServiceFirst}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              Вы уже рассматривали какие-то варианты отелей?
            </label>
            <div className="col-md-6">
              <input
                name="hotelAlternative"
                className="form-control"
                value={need.hotelAlternative}
                onChange={this.handlerChange}
              />
            </div>
          </div>
          <div className="form-group row">
            <label className="text-right col-md-4 col-form-label">
              На что я должна обращать внимание в первую очередь, чтобы
              подобрать отель который вам точно понравится?
            </label>
            <div className="col-md-6">
              <input
                name="hotelAttention"
                className="form-control"
                value={need.hotelAttention}
                onChange={this.handlerChange}
              />
            </div>
          </div>
        </Card>
        <Card>
          <CardHeader color="info">Общие</CardHeader>
          <CardBody className="travel">
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Как часто вы путешествуете?
              </label>
              <div className="col-md-6">
                <input
                  name="travelPeriod"
                  className="form-control"
                  value={need.travelPeriod}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Что для вас наиболее важно при организации вашего путешествия?
                Почему?
              </label>
              <div className="col-md-6">
                <input
                  name="travelImportant"
                  className="form-control"
                  value={need.travelImportant}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Что обязательно следует учесть при подготовке вашего
                путешествия?
              </label>
              <div className="col-md-6">
                <input
                  name="travelDetail"
                  className="form-control"
                  value={need.travelDetail}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Каким вы представляете отдых вашей мечты?
              </label>
              <div className="col-md-6">
                <input
                  name="travelDreams"
                  className="form-control"
                  value={need.travelDreams}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Как вы обычно принимаете решение: самостоятельно или вместе с
                семьей?
              </label>
              <div className="col-md-6">
                <input
                  name="travelSolution"
                  className="form-control"
                  value={need.travelSolution}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Услугами каких туристических компаний вы пользовались ранее?
              </label>
              <div className="col-md-6">
                <input
                  name="travelCompanyBefore"
                  className="form-control"
                  value={need.travelCompanyBefore}
                  onChange={this.handlerChange}
                />
              </div>
            </div>

            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Почему решили поменять компанию? Что вас не устраивало в их
                работе?
              </label>
              <div className="col-md-6">
                <input
                  name="travelCompanyChange"
                  className="form-control"
                  value={need.travelCompanyChange}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Есть ли у вас предложения от других компаний? Если да, то какие?
              </label>
              <div className="col-md-6">
                <input
                  name="travelCompanyFeature"
                  className="form-control"
                  value={need.travelCompanyFeature}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Могут ли возникнуть какие-то неожиданности, которые
                воспрепятствуют поездке?
              </label>
              <div className="col-md-6">
                <input
                  name="travelSurprise"
                  className="form-control"
                  value={need.travelSurprise}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Предпочитаете путешествовать в группе или индивидуально?
              </label>
              <div className="col-md-6">
                <input
                  name="travelGroup"
                  className="form-control"
                  value={need.travelGroup}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>
        <Card>
          <CardHeader>Бюджет</CardHeader>
          <CardBody className="cash">
            <div className="form-group row">
              <label className="text-right col-md-4 col-form-label">
                Если клиент ответил в каких отелях отдыхал ранее, то уже можно
                прикинуть на какую сумму рассчитывает, но лучше все же уточнить.
              </label>
              <div className="col-md-6">
                <input
                  name="cashSumm"
                  className="form-control"
                  value={need.cashSumm}
                  onChange={this.handlerChange}
                />
              </div>
            </div>
          </CardBody>
        </Card>

        <Card className="comment">
          <CardHeader color="danger">Комментарий</CardHeader>
          <CardBody>
            <div className="form-group row">
              <label className="text-left col-md-4 col-form-label">
                Вы можете ниже оставить ваш комментарий:
              </label>
              <textarea
                name="comment"
                className="form-control"
                rows="4"
                style={{ margin: '1em' }}
                onChange={this.handlerChange}
                value={need.comment}
              >
                {need.comment}
              </textarea>
            </div>
          </CardBody>
        </Card>
        <button
          onClick={this.handlerSave}
          disabled={!this.state.isSaveDisabled}
          className="btn btn-success mb-2"
        >
          Сохранить
        </button>
      </>
    );
  }
}
