import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import {
  EventsAPI,
  OrderFpAPI,
  IssuesMergersAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client/Client';
import OrdersWork from '../../views/OrdersFpWork';
import CalendarEventList from './components/CalendarEventsList';
import LeadStats from '../../views/Lead/components/LeadStats.js';
import IssuesList from '../../views/Issues/components/IssuesListDash';
import Paginator from '../../components/Paginator';
import IssuesMergers from '../Issues/IssuesMergers';
import moment from 'moment';
import 'moment/locale/ru';
import { ORDER_STATUS_CONFIRMED } from '../Components/FilterOrdersFp';
import { DJANGO_BASE_DATE_FORMAT } from '../Components/Calendar';
import { Alert } from 'reactstrap';
import {
  FaSquareFacebook,
  FaSquareInstagram,
  FaSquareOdnoklassniki,
  FaSquareWhatsapp,
  FaTelegram,
  FaViber,
  FaVk,
} from 'react-icons/fa6';

const ISSUES_TO_MERGE_PER_PAGE = 5;
const TIMEOUT = 1000;

export default class Dashboard extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.fetchIssuesMergers = this.fetchIssuesMergers.bind(this);
    this.handleIssuesMergersPageChange =
      this.handleIssuesMergersPageChange.bind(this);
    this.getStartDay = this.getStartDay.bind(this);
    this.getEndDay = this.getEndDay.bind(this);
    this.handleSkipIssue = this.handleSkipIssue.bind(this);

    this.state = {
      events: [],
      ordersfp: {
        items: [],
        pageNumber: 1,
        count: 0,
        filters: null,
      },
      orders_event: {},
      issues: {
        items: [],
        pageNumber: 1,
        count: 0,
      },
      isIssuesError: false,
      isIssuesSuccess: false,
      isOrdersLoading: false,
      isEventsLoading: false,
      isIssuesMergersLoading: false,
    };
  }

  fetchItems(page = 1) {
    const self = this;
    this.setState({ isOrdersLoading: true });
    const params = { status: ORDER_STATUS_CONFIRMED };
    params.page_size = 5;
    OrderFpAPI.getList(page, params).then((result) => {
      self.setState({
        ordersfp: {
          items: result.results || [],
          count: result.count,
        },
        isOrdersLoading: false,
      });
    });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.setState({ pageNumber: page }, () => this.fetchItems(page));
  }

  handleIssuesMergersPageChange(data) {
    this.fetchIssuesMergers(data.selected + 1);
  }

  getStartDay() {
    let currentDay = moment();
    currentDay.subtract(31, 'day');
    return currentDay.format(DJANGO_BASE_DATE_FORMAT);
  }

  getEndDay() {
    let currentDay = moment();
    currentDay.add(29, 'day');
    return currentDay.format(DJANGO_BASE_DATE_FORMAT);
  }

  handleSkipIssue(issue) {
    const self = this;
    let status;
    IssuesMergersAPI.update(`${IssuesMergersAPI.resource_url}${issue}`, {
      is_missed: true,
    })
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          self.setState(
            {
              isIssuesSuccess: true,
              isIssuesError: false,
            },
            () => setTimeout(() => self.fetchIssuesMergers(), TIMEOUT)
          );
        }
      });
  }

  fetchEventItems() {
    const self = this;
    let status;
    this.setState({ isEventsLoading: true });

    EventsAPI.fetchList(
      1,
      {
        day_range_after: self.getStartDay(),
        day_range_before: self.getEndDay(),
      },
      `full_list/`
    )
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          self.setState({
            events: r || [],
            isHideForm: true,
            isEventsLoading: false,
          });
        }
      });
  }

  fetchIssuesMergers(page = 1) {
    const self = this;
    this.setState({ isIssuesMergersLoading: true });
    let status;
    IssuesMergersAPI.fetchList(page)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          self.setState({
            issues: {
              items: r.results || [],
              count: r.count || 0,
              pageNumber: page,
            },
            isIssuesSuccess: false,
            isIssuesError: false,
            isIssuesMergersLoading: false,
          });
        } else {
          self.setState({
            issues: {
              items: [],
              count: 0,
              pageNumber: 1,
            },
            isIssuesSuccess: false,
            isIssuesError: true,
            isIssuesMergersLoading: false,
          });
        }
      });
  }

  componentDidMount() {
    const self = this;
    this.fetchEventItems();
    this.fetchItems();
    this.fetchIssuesMergers();
  }

  render() {
    const {
      events,
      ordersfp,
      issues,
      isIssuesError,
      isIssuesSuccess,
      isOrdersLoading,
      isEventsLoading,
      isIssuesMergersLoading,
    } = this.state;
    return (
      <div className="animated fadeIn dashboard">
        <h3>
          Рабочий стол менеджера
          <Link to={`/notify`}>
            <i className="fa fa-bell mr-2 ml-2">{''}</i>
          </Link>
        </h3>

        <div className="row">
          <div className="col-sm-6 col-lg-6">
            <div className="card">
              <div className="card-header">
                <Link to={`/calendar`}>
                  <i className="fa fa-align-justify mr-2">{''}</i>
                </Link>
                Напоминания из календаря (&#177; 30 дней)
                <span className="float-right  pink-bg badge badge-default badge-pill">
                  Всего {events.length}
                </span>
              </div>
              <CalendarEventList events={events} isLoading={isEventsLoading} />
            </div>
            <div className="card">
              <div className="card-header">
                <Link to={`/ordersfp`}>
                  <i className="fa fa-align-justify mr-2">{''}</i>
                </Link>
                <span>Мои Запросы</span>
              </div>
              <IssuesList />
            </div>
            <div className="card">
              <div className="card-header">
                <Link to={`/ordersfp`}>
                  <i className="fa fa-align-justify mr-2">{''}</i>
                </Link>
                <span>Заявки в работе в СБ</span>
                <span className="float-right  pink-bg badge badge-default badge-pill">
                  Всего {ordersfp.count}
                </span>
              </div>
              <OrdersWork
                count={ordersfp.count}
                items={ordersfp.items}
                filters={ordersfp.filters}
                handleRefresh={this.fetchItems}
                handlePageChange={this.handlePageChange}
                isLoading={isOrdersLoading}
              />
            </div>
          </div>

          <div className="col-sm-6 col-lg-6" style={{ marginTop: '-74px' }}>
            <div className="row dashboard__social">
              <Link
                to="https://api.whatsapp.com/send?phone=7909007080"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareWhatsapp />
              </Link>
              <Link
                to="https://viber.click/7909007080"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaViber />
              </Link>
              <Link
                to="http://m.me/geograftourrr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareFacebook />
              </Link>
              <Link
                to="https://vk.me/geograftur"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaVk />
              </Link>
              <Link to="https://t.me" target="_blank" rel="noopener noreferrer">
                <FaTelegram />
              </Link>
              <Link
                to="https://ok.ru/group/54806703243288"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareOdnoklassniki />
              </Link>
              <Link
                to="https://www.instagram.com/geograftur_rus/"
                target="_blank"
                rel="noopener noreferrer"
              >
                <FaSquareInstagram />
              </Link>
            </div>
            <>
              <LeadStats />
              <div className="card">
                <div className="card-header">
                  <Link to={`/issues`}>
                    <i className="fa fa-align-justify mr-2">{''}</i>
                  </Link>
                  Перевод клиента из потенциальных в постоянные.
                  <span className="float-right pink-bg badge badge-default badge-pill">
                    Всего {issues.count}
                  </span>
                </div>
                <div className="card-body">
                  <p>
                    В разделе покупка добавить номер заявки из системы
                    бронирования
                  </p>
                  <IssuesMergers
                    items={issues.items}
                    handleSkipIssue={this.handleSkipIssue}
                    isLoading={isIssuesMergersLoading}
                  />
                  <Paginator
                    perPage={ISSUES_TO_MERGE_PER_PAGE}
                    total={issues.count}
                    onPageChange={this.handleIssuesMergersPageChange}
                    forcePage={issues.pageNumber - 1}
                  />
                </div>
                {isIssuesSuccess && <Alert>Успешно</Alert>}
                {isIssuesError && <Alert color="warning">Ошибка</Alert>}
              </div>
            </>
          </div>
        </div>
      </div>
    );
  }
}
