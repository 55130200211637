import React, { PureComponent } from 'react';
import QuotesTable from './components/QuotesTable';
import FilterQuotes from './components/FilterQuotes';
import ProgressBar from '../../components/ProgressBar';
import { QuotesAPI, POSITIVE_ACTION_STATUSES } from '../../api/Client';
import moment from 'moment';
import 'moment/locale/ru';
import { connect } from 'react-redux';
import { switchCurrentQuotesPage } from '../../actions/actions';

class Tours extends PureComponent {
  constructor(props) {
    super(props);
    this.filterItems = this.filterItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.state = {
      is_simple: false,
      quotes: [],
      hasNext: null,
      hasPrev: null,
      count: 0,
      offices_count: null,
      filters: {},
      errors: null,
      isSuccess: false,
      pageNumber: this.props.pageNumber || 1,
      isLoading: false,
    };
  }

  filterItems(st) {
    let result = {};

    if (st.author_type !== null) {
      result.author_type = st.author_type.value;
    }
    if (st.startDate) {
      result.created_at__gte = moment(st.startDate).format('DD.MM.YYYY');
    }
    if (st.endDate) {
      result.created_at__lte = moment(st.endDate).format('DD.MM.YYYY');
    }
    if (st.offices) {
      result.office = st.offices.map((office) => office.id);
    }
    if (st.offices_city !== null) {
      result.office_city = st.offices_city.id;
    }
    if (st.offices_country !== null) {
      result.office_country = st.offices_country.id;
    }
    if (st.managers) {
      result.manager = st.managers.map((manager) => manager.value);
    }
    if (st.hide_after_days_2 !== null) {
      result.hide_after_days_2 = st.hide_after_days_2.value;
    }
    if (st.is_active_on_site !== null) {
      result.is_active_on_site = st.is_active_on_site.value;
    }
    if (st.notify_author_by_telegram !== null) {
      result.notify_author_by_telegram = st.notify_author_by_telegram.value;
    }
    if (result) {
      this.setState(
        {
          filters: result,
          errors: null,
          isSuccess: false,
        },
        () => this.fetchItems()
      );
    }
  }

  handlePageChange(data) {
    const page = data.selected + 1;
    this.props.switchCurrentQuotesPage(page);
    this.setState(
      {
        pageNumber: page,
        errors: null,
        isSuccess: false,
      },
      () => this.fetchItems(page)
    );
  }

  fetchItems(page) {
    this.setState({ isLoading: true });
    let pageToLoad = page ? page : this.props.pageNumber;
    let status;
    const { filters } = this.state;
    QuotesAPI.fetchList(pageToLoad, filters)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            quotes: result.results || [],
            hasNext: result.next != null,
            hasPrev: result.previous != null,
            count: result.count,
            offices_count: result.hasOwnProperty('offices_count')
              ? result.offices_count
              : null,
            isLoading: false,
          });
        }
      });
  }
  
  render() {
    const { quotes, count, offices_count, filters, isLoading } = this.state;
    return (
      <div className="animated fadeIn">
        <div className="filter filter--blue">
          <FilterQuotes
            onFilter={this.filterItems}
            handlePageChange={this.handlePageChange}
          />
        </div>
        {isLoading ? (
          <div className="row animated fadeIn">
            <div className="col-md-12 col-md-offset-6 text-center">
              <ProgressBar />
            </div>
          </div>
        ) : quotes.length ? (
          <QuotesTable
            isShowActions={filters}
            count={count}
            offices_count={offices_count}
            items={quotes}
            handleRefresh={this.fetchItems}
            handlePageChange={this.handlePageChange}
            forcePage={this.props.pageNumber - 1}
          />
        ) : (
          <div className="row animated fadeIn">
            <div className="col-md-12 col-md-offset-6 text-center">
              Ничего не нашлось, смените критерии поиска
            </div>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = function (store) {
  return {
    pageNumber: store.quotesReducer.pageNumber,
  };
};

const mapDispatchToProps = {
  switchCurrentQuotesPage,
};

export default connect(mapStateToProps, mapDispatchToProps)(Tours);
