import React, { PureComponent } from 'react';
import { IssuesAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import { Link } from 'react-router-dom';
import Paginator from '../../../components/Paginator/Paginator';
import moment from 'moment';
import 'moment/locale/ru';
import {
  initPageNumber,
  changePageNumber,
} from '../../../lib/url-search-params';
import { CardBody } from 'reactstrap';
import IssuesStatusPoint, { ISSUE_STATUS_COLOR } from './IssuesStatusPoint';
import ProgressBar from '../../../components/ProgressBar';

const PAGE_NAME = 'issue_page';

export default class IssuesListDash extends PureComponent {
  constructor(props) {
    super(props);

    this.fetchItems = this.fetchItems.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      items: [],
      previous: '',
      next: '',
      count: 0,
      page: initPageNumber(PAGE_NAME),
      isLoading: false,
    };
  }

  fetchItems(page = 1) {
    let status;
    this.setState({ isLoading: true });
    IssuesAPI.fetchList(page)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({
            items: r.results || [],
            previous: !!r.previous,
            next: !!r.next,
            count: r.count,
            page: page,
            isLoading: false,
          });
        }
      });
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    changePageNumber(PAGE_NAME, page);
    this.fetchItems(page);
  }

  componentDidMount() {
    const { page } = this.state;
    this.fetchItems(page);
  }

  render() {
    const { items, count, page } = this.state;

    const ISSUE_SOURCE_MAP = {
      socials: 'соцсети',
      call_to_office: 'звонок в офис',
      call_to_phone: 'звонок на сотовый',
      visitor: 'посетитель',
      recommendation: 'по рекомендации',
      excel: 'из файла excel',
    };

    return (
      <CardBody>
        <div style={{ display: 'flex' }}>
          <IssuesStatusPoint color={'new'} text={'Новый'} />
          <IssuesStatusPoint color={'active'} text={'В работе'} />
          <IssuesStatusPoint color={'order'} text={'Покупка'} />
          <IssuesStatusPoint color={'cancel'} text={'Отменен'} />
        </div>
        <ul id="dashboard__orders" className="list-group">
          {this.state.isLoading ? (
            <ProgressBar />
          ) : (
            items.map((item, idx) => {
              return (
                <li
                  className={`justify-content-between list-group-item item-header`}
                  key={idx}
                >
                  {item.person && (
                    <Link to={`/persons/${item.person.id}`}>
                      <i
                        className={`fa fa-circle fa-2 circle--${item.resolved}`}
                        style={{ color: ISSUE_STATUS_COLOR[item.status] }}
                        aria-hidden="true"
                      >
                        {''}
                      </i>
                      <b>{item.person.full_name}</b>
                      <span className="ml-2 badge badge-pill badge-default">
                        {ISSUE_SOURCE_MAP[item.source]}
                      </span>
                      <span className="float-right">
                        <i className="order__icon-info order__icon-info--arrive">
                          {''}
                        </i>
                        {moment(item.created).format('DD.MM.YY H:mm')}
                      </span>
                      <p></p>
                      <p className="short-text">{item.text}</p>
                    </Link>
                  )}
                  {item.tourist && (
                    <Link to={`/tourists/${item.tourist.id}`}>
                      <i
                        className={`fa fa-circle fa-2 circle--${item.resolved}`}
                        style={{ color: ISSUE_STATUS_COLOR[item.status] }}
                        aria-hidden="true"
                      >
                        {''}
                      </i>
                      <b>{item.tourist.full_name}</b>
                      <span className="ml-2 badge badge-pill badge-default">
                        {ISSUE_SOURCE_MAP[item.source]}
                      </span>
                      <span className="float-right">
                        <i className="order__icon-info order__icon-info--arrive">
                          {''}
                        </i>
                        {moment(item.created).format('DD.MM.YY H:mm')}
                      </span>
                      <p className="short-text">{item.text}</p>
                    </Link>
                  )}
                </li>
              );
            })
          )}
          <Paginator
            total={count}
            onPageChange={this.handlePageChange}
            initialPage={page - 1}
            forcePage={0}
          />
        </ul>
      </CardBody>
    );
  }
}
