import React, {PureComponent} from 'react';
import {Link} from 'react-router-dom';
import {CashAccountsAPI, POSITIVE_ACTION_STATUSES} from '../../api/Client';
import Paginator from '../../components/Paginator';
import {Card, CardBody, CardHeader, Col, Row} from 'reactstrap';
import moment from 'moment';
import 'moment/locale/ru';

export default class Spendings extends PureComponent {
  constructor(props) {
    super(props);

    this.handlePageChange = this.handlePageChange.bind(this);

    this.state = {
      items: [],
      count: 0,
      costs: {},
    };
  }

  handlePageChange(data) {
    let page = data.selected + 1;
    this.fetchItems(page);
  }

  fetchItems(page = 1) {
    let status;
    const {cashAccount} = this.props;
    CashAccountsAPI.
        fetchList(1, {}, `${cashAccount}/spendings/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              items: r.results || [],
              count: r.count || 0,
              // costs: r.cost,
              pageNumber: page,
            }, () => this.fetchCosts(page));
          }
        });
  }

  fetchCosts(page = 1) {
    let status;
    const {cashAccount} = this.props;
    CashAccountsAPI.
        fetchList(1, {}, `${cashAccount}/spendingcosts/`).
        then(r => {
          status = r.status;
          return r.json();
        }).
        then(r => {
          if (status === POSITIVE_ACTION_STATUSES.list) {
            this.setState({
              costs: r.results || [],
              // count: r.count || 0,
            });
          }
        });
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {
    const {items, count, costs} = this.state;
    return (
        <div className="animated fadeIn">
          <Row>
            <Col lg="12">
              <Card>
                <CardHeader>
                  <i className="fa fa-align-justify">{''}</i> Всего трат&nbsp;
                  <span className="badge badge-info">
                    {count}
                  </span>
                </CardHeader>
                <CardBody>
                  <table className="table table-bordered table-striped table-sm">
                    <thead>
                    <tr>
                      <th>Id</th>
                      <th>Создана</th>
                      <th>Рассылка</th>
                      <th>Стоимость</th>
                    </tr>
                    </thead>
                    <tbody>
                    {
                      items.map(item => (
                          <tr key={item.id}>
                            <td>
                              {item.id}
                            </td>
                            <td>
                              {moment(item.created).format('DD.MM.YY HH:mm')}
                            </td>
                            <td>
                              <Link to={`/mailbox/${item.campaign}`}>
                                Рассылка #{item.campaign}
                              </Link>
                            </td>
                            <td>
                              {
                                // costs.hasOwnProperty(item.id) && costs[item.id] ? (
                                    <span>{item.cost.value} {item.cost.currency}</span>
                                    // <span>{item.campaign.currency}</span>
                                // ) : null
                              }
                            </td>
                          </tr>
                      ))
                    }
                    </tbody>
                  </table>
                  <Paginator total={count} onPageChange={this.handlePageChange}/>
                </CardBody>
              </Card>
            </Col>
          </Row>
        </div>
    );
  }
}
