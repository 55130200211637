import React, { PureComponent } from 'react';
import swal from 'sweetalert';
import ClientAPI, { QuotesAPI } from '../../../../api/Client';
import moment from 'moment';
import UserTypeModal from '../UserTypeModal/UserTypeModal';
import TourPriceOnImage from './TourPriceOnImage';
import { Link } from 'react-router-dom';

export default class TourItem extends PureComponent {
  constructor(props) {
    super(props);
    this.handleManagerDataSave = this.handleManagerDataSave.bind(this);
    this.handleInitialPrice = this.handleInitialPrice.bind(this);
    this.handleComment = this.handleComment.bind(this);
    this.handleTourRemove = this.handleTourRemove.bind(this);
    this.toggleShowModal = this.toggleShowModal.bind(this);

    this.state = {
      personsForView: [],
      touristfpsForView: [],
      modalTitle: '',
      isOpenModal: false,
      manager_data: {
        initial_price: 0,
        comment: '',
      },
      isDisabledButton: true,
      isSaving: false,
      isSaved: false,
    };
  }

  handleComment(e) {
    this.setState({
      manager_data: {
        ...this.state.manager_data,
        comment: e.target.value,
      },
      isDisabledButton: false,
    });
  }

  toggleShowModal(arg) {
    if (arg === 'likes') {
      this.setState({
        isOpenLikesList: !this.state.isOpenLikesList,
        modalTitle: 'Лайкнувшие',
        personsForView: this.props.tour.stat_likes_persons,
        touristfpsForView: this.props.tour.stat_likes_touristfps,
      });
    }
    if (arg === 'clicks') {
      this.setState({
        isOpenLikesList: !this.state.isOpenLikesList,
        modalTitle: 'Кликнувшие',
        personsForView: this.props.tour.stat_clicks_persons,
        touristfpsForView: this.props.tour.stat_clicks_touristfps,
      });
    }

    this.setState({
      isOpenModal: !this.state.isOpenModal,
    });
  }

  handleTourRemove(e) {
    const tour_id = this.props.tour.id;
    const quote_id = this.props.qid;
    const title = e.target.title;
    const tourName = this.props.tour.name;
    swal({
      title: 'Вы уверены?',
      text: title,
      icon: 'error',
      buttons: true,
      dangerMode: true,
      confirmButtonText: 'Удалить',
      cancelButtonText: 'Нет',
    }).then((willDelete) => {
      if (willDelete) {
        QuotesAPI.deleteTour(quote_id, tour_id).then((res) => {
          if (res.status === 204) {
            this.props.onDelete();
            swal(`Тур в отель ${tourName} успешно удален`);
          }
        });
      }
    });
  }

  handleInitialPrice(e) {
    this.setState({
      manager_data: {
        ...this.state.manager_data,
        initial_price: e.target.value,
      },
      isDisabledButton: false,
    });
  }

  handleManagerDataSave(e) {
    e.preventDefault();
    const { manager_data } = this.props.tour;
    this.props.onManagerDataSave(this.props.tour.id, this.state.manager_data);
    this.setState({ isSaving: true, isDisabledButton: true });
    const filters = {
      comment: this.state.manager_data.comment,
      initial_price: this.state.manager_data.initial_price,
    };
    filters.tour = this.props.tour.id;
    filters.quote = this.props.quote;
    if (manager_data.id) {
      ClientAPI.update(`/tours/managers-data/${manager_data.id}`, filters).then(
        () => {
          this.setState({
            isSaving: false,
            isSaved: true,
            isDisabledButton: true,
          });
          setTimeout(() => {
            this.setState({ isSaved: false });
          }, 2000);
        }
      );
    } else {
      ClientAPI.create(`/tours/managers-data`, filters).then(() => {
        this.setState({
          isSaving: false,
          isSaved: true,
          isDisabledButton: true,
        });
        setTimeout(() => {
          this.setState({ isSaved: false });
        }, 2000);
      });
    }
  }

  componentDidMount() {
    this.setState({
      manager_data: this.props.tour.manager_data,
    });
  }

  render() {
    const { manager_data } = this.state;
    const manager_base_price = manager_data.base_price
      ? manager_data.base_price
      : this.props.tour.initial_price;
    const price_updated = this.props.tour.actualization_status
      ? this.props.tour.price
      : false;
    const { comment, initial_price } = manager_data;
    const {
      name,
      thumbnail,
      currency,
      country,
      checkin,
      nights,
      adults,
      city_from,
      children,
      room,
      board,
      operator,
      tour_id,
      stat_clicks_count,
      stat_likes_count,
      id,
      actualization_status,
    } = this.props.tour;

    return (
      <article className="tour tour-item">
        <UserTypeModal
          toggleLikesListModal={this.toggleShowModal}
          isOpen={this.state.isOpenModal}
          modalTitle={this.state.modalTitle}
          personsForView={this.state.personsForView}
          touristfpsForView={this.state.touristfpsForView}
        />

        <div className="tour__data-container">
          <div className="tour__info-container">
            <h2 className="tour__title">{name}</h2>
            <div className="tour__image-container">
              <div className="tour__image-wrap">
                <img
                  className="tour__image"
                  src={thumbnail}
                  width="180px"
                  alt=""
                />
                <div className="tour__price">
                  {price_updated &&
                    parseInt(price_updated, 10) <
                      parseInt(manager_base_price, 10) && (
                      <TourPriceOnImage
                        currency={currency}
                        price_updated={price_updated}
                        manager_base_price={manager_base_price}
                        color={'#9fe29f'}
                        simple={false}
                      />
                    )}

                  {price_updated &&
                    parseInt(price_updated, 10) > manager_base_price && (
                      <TourPriceOnImage
                        currency={currency}
                        price_updated={price_updated}
                        manager_base_price={manager_base_price}
                        color={'red'}
                        simple={false}
                      />
                    )}

                  {price_updated && price_updated === manager_base_price && (
                    <TourPriceOnImage
                      currency={currency}
                      price_updated={price_updated}
                      manager_base_price={manager_base_price}
                      color={'white'}
                      simple={true}
                    />
                  )}

                  {!price_updated && actualization_status >= 0 && (
                    <TourPriceOnImage
                      currency={currency}
                      price_updated={price_updated}
                      manager_base_price={manager_base_price}
                      color={'beige'}
                      simple={true}
                    />
                  )}
                  {!price_updated && actualization_status < 0 && (
                    <TourPriceOnImage
                      currency={currency}
                      price_updated={price_updated}
                      manager_base_price={manager_base_price}
                      color={'red'}
                      simple={true}
                      text={'Нет в наличии'}
                    />
                  )}
                </div>
              </div>
              <div className="tour-item__price">
                <input
                  className="form-control"
                  onChange={this.handleInitialPrice}
                  value={initial_price}
                  placeholder="Назначить свою цену"
                />
              </div>
            </div>
            <div className="tour__info">
              <div className="tour__info-item">
                <span className="tour__info-icon fa fa-plane"></span>
                {country} {city_from}
              </div>
              <div className="tour__info-item">
                <span className="tour__info-icon fa fa-calendar-o"></span>
                {moment(checkin).format('DD.MM.YYYY')} на {nights} ночей
              </div>
              <div className="tour__info-item">
                <span className="tour__info-icon fa fa-users"></span>
                {adults} + {children}
              </div>
              <div className="tour__info-item">
                <span className="tour__info-icon fa fa-hotel"></span>
                {room}
              </div>
              <div className="tour__info-item">
                <span className="tour__info-icon fa fa-cutlery"></span>
                {board}
              </div>
              <div className="tour__info-item">
                <span className="tour__info-icon fa fa-globe"></span>
                {operator}
              </div>
              <div className="tour__info-stats">
                <div className="tour__stats-item">
                  <span>
                    <i className="tour__stats-icon fa fa-heart"></i>{' '}
                    <b>{stat_likes_count}</b>
                  </span>
                  <span
                    title="показать лайкнувших"
                    onClick={() => this.toggleShowModal('likes')}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className=" fa fa-eye"></i>
                  </span>
                </div>
                <div className="tour__stats-item">
                  <span>
                    <i className="tour__stats-icon fa fa-hand-o-up"></i>{' '}
                    <b>{stat_clicks_count}</b>
                  </span>
                  <span
                    title="показать кликнувших"
                    onClick={() => this.toggleShowModal('clicks')}
                    style={{ cursor: 'pointer' }}
                  >
                    <i className=" fa fa-eye mr-1"></i>
                  </span>
                </div>
              </div>
            </div>
            <div className="tour-item__comment-container">
              <textarea
                className="form-control tour-item__comment"
                onChange={this.handleComment}
                value={comment}
                placeholder="Комментарий для туриста"
              />
            </div>
          </div>
          <div className="tour__footer">
            <div className="tour__btn-container">
              <Link
                to={`https://www.geograftour.com/tours/agency-tour-info/${tour_id}/`}
                target="_blank"
                rel="noopener noreferrer"
                className="btn btn-secondary tour__footer-btn"
              >
                Открыть тур
              </Link>
              <button
                className={`btn ${
                  this.state.isDisabledButton ? 'btn-secondary' : 'btn-success'
                } ${
                  this.state.isSaved
                    ? 'tour__save-notify tour__save-notify_visible'
                    : ''
                } tour__save-btn tour__footer-btn`}
                disabled={this.state.isDisabledButton}
                onClick={this.handleManagerDataSave}
              >
                {!this.state.isSaving
                  ? !this.state.isSaved
                    ? 'Сохранить'
                    : 'Сохранено'
                  : 'Сохранение'}
              </button>

              {this.props.qid && (
                <button
                  className="btn btn-danger tour__footer-btn"
                  title={`Удалить тур  из подборки в отель ${name}`}
                  alt={id}
                  onClick={this.handleTourRemove}
                >
                  Удалить
                </button>
              )}
            </div>
          </div>
        </div>
      </article>
    );
  }
}
