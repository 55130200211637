import React, { PureComponent } from 'react';
import 'moment/locale/ru';
import swal from 'sweetalert';
import { StagingsAPI } from '../../../api/Client/';
import TouristList from './TouristList';
import {
  AiOutlineSearch,
  AiOutlineCloseCircle,
  AiOutlineLoading,
} from 'react-icons/ai';

export default class ListTouristsPersonal extends PureComponent {
  constructor(props) {
    super(props);

    this.handleChange = this.handleChange.bind(this);
    this.handleSelect = this.handleSelect.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handleCheckboxChange = this.handleCheckboxChange.bind(this);
    this.reset = this.reset.bind(this);
    this.setListLoading = this.setListLoading.bind(this);
    this.fetchItems = this.fetchItems.bind(this);
    this.state = {
      filter: '',
      tourists: [],
      selected: [],
      selectedTourists: [],
      count: 0,
      onlyPerson: 0,
      hasPhone: 1,
      hasEmail: 1,
      isListLoading: false,
    };
  }

  handleChange(e) {
    const { name, value } = e.target;
    this.setState({ [name]: value }, this.handleFilter);
  }

  handleCheckboxChange(e) {
    const { name, checked } = e.target;
    this.setState({ [name]: checked ? 1 : 0 }, this.handleFilter);
  }

  fetchItems = (page = 1) => {
    this.setListLoading();
    const { onlyPerson, hasEmail, hasPhone, filter, selectedTourists } =
      this.state;

    const filters = filter ? { q: filter } : {};
    filters.has_emails = hasEmail;
    filters.has_phones = hasPhone;
    filters.only_person = onlyPerson;
    StagingsAPI.getList(page, filters).then((result) => {
      const { results, count } = result;
      const filteredTourists = results.filter(
        (tourist) =>
          !selectedTourists.find((selected) => selected.id === tourist.id)
      );
      this.setState({
        count,
        tourists: filteredTourists,
        isListLoading: false,
      });
    });
  };

  handleFilter() {
    const { onlyPerson, hasEmail, hasPhone, selected } = this.state;

    this.props.onFilter({
      type: 'personal',
      only_person: onlyPerson,
      id: selected,
      has_emails: hasEmail,
      has_phones: hasPhone,
    });
  }

  handleSelect(e, tourist) {
    const { value, checked } = e.target;
    const { selected, selectedTourists, tourists } = this.state;

    if (checked) {
      this.setState(
        {
          selected: [...selected, +value],
          selectedTourists: [...selectedTourists, tourist],
          tourists: tourists.filter((item) => item.id !== tourist.id),
        },
        this.handleFilter
      );
    } else {
      const index = selected.indexOf(+value);
      if (index !== -1) {
        this.setState(
          {
            selected: selected.filter((id) => id !== +value),
            selectedTourists: selectedTourists.filter((_, i) => i !== index),
            tourists: [...tourists, tourist],
          },
          this.handleFilter
        );
      }
    }
  }

  reset() {
    this.setState(
      {
        ...this.state,
        filter: '',
      },
      () => {
        this.fetchItems();
      }
    );
  }

  setListLoading() {
    this.setState({
      ...this.state,
      isListLoading: !this.state.isListLoading,
    });
  }

  componentDidMount() {
    this.props.onFilter({ type: 'personal', id: [] });
  }

  render() {
    const {
      tourists,
      count,
      onlyPerson,
      hasEmail,
      hasPhone,
      filter,
      selected,
      selectedTourists,
      isListLoading,
    } = this.state;

    return (
      <>
        <div className="form-group row">
          <div className="col-md-4 mb-2">
            <input
              type="checkbox"
              name="onlyPerson"
              id="onlyPerson"
              checked={onlyPerson}
              onChange={this.handleCheckboxChange}
            />{' '}
            <label htmlFor="onlyPerson">Потенциальные клиенты</label>
          </div>
          <div className="col-md-2 mb-2">
            <input
              type="checkbox"
              name="hasEmail"
              id="hasEmail"
              checked={hasEmail}
              onChange={this.handleCheckboxChange}
            />{' '}
            <label htmlFor="hasEmail">Есть почта</label>
          </div>
          <div className="col-md-4 mb-2">
            <input
              type="checkbox"
              name="hasPhone"
              id="hasPhone"
              checked={hasPhone}
              onChange={this.handleCheckboxChange}
            />{' '}
            <label htmlFor="hasPhone">Есть телефон</label>
          </div>
        </div>
        <form
          className="search"
          onSubmit={(e) => {
            e.preventDefault();
            this.fetchItems();
          }}
        >
          <input
            type="text"
            className="form-control search__input"
            id="last-name-input"
            name="filter"
            value={filter}
            onChange={this.handleChange}
            placeholder="Имя, email, телефон"
            title="ФИО, номер телефона или email"
            disabled={isListLoading}
            required
          />
          <button
            className="search__btn search__btn_submit"
            type="submit"
            disabled={isListLoading}
            title="Найти"
          >
            {isListLoading ? (
              <AiOutlineLoading className="search__icon search__icon-loading" />
            ) : (
              <AiOutlineSearch className="search__icon" />
            )}
          </button>
          <button
            className="search__btn search__btn_reset"
            type="button"
            disabled={isListLoading}
            title="Сбросить"
            onClick={this.reset}
          >
            <AiOutlineCloseCircle className="search__icon" />
          </button>
        </form>
        {selectedTourists.length ? (
          <TouristList
            tourists={selectedTourists}
            onSelect={this.handleSelect}
            selected={selected}
            type="selectedTourists"
            total={selectedTourists.length}
          />
        ) : null}

        <TouristList
          tourists={tourists}
          total={count}
          onSelect={this.handleSelect}
          selected={selected}
          type="touristToChoose"
        />
      </>
    );
  }
}
