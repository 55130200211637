import React, { useEffect, useState } from 'react';
import { Link, useNavigate, useParams } from 'react-router-dom';
import { TabContent, TabPane, Nav, NavItem, NavLink } from 'reactstrap';
import classnames from 'classnames';
import { Card, CardHeader, CardBody } from 'reactstrap';
import {
  TouristFpAPI,
  DocumentsAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../api/Client';
import OrdersFpTable from '../../views/OrdersFpTable';
import 'react-datepicker/dist/react-datepicker.css';
import Issues from '../Issues/Issues';
import Mergers from '../Mergers/Mergers';
import Passports from '../Passports';
import TouristFiles from './components/TouristFiles';
import ProgressBar from '../../components/ProgressBar';

function TouristFP() {
  const { touristId } = useParams();
  const [touristInfo, setTouristInfo] = useState({});
  const [issues, setIssues] = useState([]);
  const [orders, setOrders] = useState([]);
  const [tags, setTags] = useState([]);
  const [isNeedNewPassport, setNeedNewPassport] = useState(false);
  const [page, setPage] = useState(1);
  const [activeTab, setActiveTab] = useState('1');
  const [isOrdersLoading, setOrdersLoading] = useState(false);
  const [isInfoLoading, setInfoLoading] = useState(false);
  const [isIssuesLoading, setIssuesLoading] = useState(false);

  const navigate = useNavigate();

  useEffect(() => {
    getInfo();
    getIssues();
    getOrders();
  }, [touristId]);

  function getInfo() {
    setInfoLoading(true);
    TouristFpAPI.fetchJSON(`${touristId}/`)
      .then((r) => r.json())
      .then((r) => {
        if (POSITIVE_ACTION_STATUSES.retrieve) {
          setTouristInfo(r);
          setInfoLoading(false);
        }
      });
  }

  function getIssues(page = 1) {
    setIssuesLoading(true);
    TouristFpAPI.fetchList(page, {}, `${touristId}/issues/`)
      .then((r) => r.json())
      .then((r) => {
        if (POSITIVE_ACTION_STATUSES.list) {
          const { results } = r;
          setIssues(results);
          setIssuesLoading(false);
        }
      });
  }

  function getOrders(page = 1) {
    setOrdersLoading(true);
    TouristFpAPI.fetchList(page, {}, `${touristId}/orders/`)
      .then((r) => r.json())
      .then((r) => {
        if (POSITIVE_ACTION_STATUSES.list) {
          const { results } = r;
          setOrders(results);
          setOrdersLoading(false);
        }
      });
  }

  function toggleTab(tab) {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  }

  function fetchTags() {
    TouristFpAPI.fetchList(1, {}, `${touristId}/tourist_tags/`)
      .then((r) => r.json())
      .then((r) => {
        setTags(r);
      });
  }

  function updateIssues() {
    getIssues();
  }

  function onChangeActivity() {
    navigate('/issues/', { replace: true });
  }

  function checkPassport(passports) {
    if (Array.isArray(passports) && passports.length) {
      setNeedNewPassport(passports.some((passport) => passport.is_need_new));
    }
  }

  function handlePageChange(data) {
    let page = data.selected + 1;
    setPage(page);
    getIssues(page);
  }

  function downloadFile(e) {
    e.preventDefault();
    DocumentsAPI.get(e.target.title).then((res) => console.log(res));
  }

  function getCorrectDataFromArray(data) {
    return data
      .filter((x) => x.value)
      .map((x) => x.value)
      .join(', ');
  }

  function getIncorrectDataFromArray(data) {
    return (
      data
        .filter((x) => !x.value)
        .map((x) => x.origin)
        .join(', ') || '-'
    );
  }

  const {
    fp_pk: fpPk,
    full_name: fullName,
    full_name_eng: fullNameEng,
    born,
    phone,
    email,
    documents,
    comment,
  } = touristInfo;

  const noDataMessage = 'Не задано';

  return (
    <div className="filter filter--orange">
      <Card>
        <div>
          <CardHeader>
            {isInfoLoading ? (
              <ProgressBar />
            ) : (
              <h4>{fullName?.length > 1 ? fullName : fullNameEng}</h4>
            )}
          </CardHeader>
          <CardBody>
            <div className="row">
              <div className="col-md-6 col-lg-6">
                {isNeedNewPassport ? (
                  <p>
                    <span className="badge badge-danger">
                      ЗАПРОСИТЬ НОВЫЙ ЗАГРАНПАСПОРТ
                    </span>
                  </p>
                ) : null}
                <p>
                  Идентификатор из ФСУ: <b>{fpPk}</b>
                </p>
                <p>
                  Телефоны:{' '}
                  <b>
                    {Array.isArray(phone) && phone.length
                      ? getCorrectDataFromArray(phone)
                      : noDataMessage}
                  </b>
                </p>
                {Array.isArray(phone) && phone.length ? (
                  <p>
                    С ошибками: <b>{getIncorrectDataFromArray(phone)}</b>
                  </p>
                ) : null}
                <p>
                  Электронная почта:{' '}
                  <b>
                    {Array.isArray(email) && email.length
                      ? getCorrectDataFromArray(email)
                      : noDataMessage}
                  </b>
                </p>
                {Array.isArray(email) && email.length ? (
                  <p>
                    С ошибками: <b>{getIncorrectDataFromArray(email)}</b>
                  </p>
                ) : null}
                <p>
                  Дата рождения: <b>{born || noDataMessage}</b>
                </p>
                <p>
                  Метки:{' '}
                  {tags.length
                    ? tags.map((tag) => (
                        <span
                          className="badge badge-pill badge-success ml-2"
                          key={`tag-${tag.id}`}
                        >
                          {tag.name}
                        </span>
                      ))
                    : '-'}
                </p>
                <p>
                  Комментарий:{' '}
                  <b className="comment__client">{comment || '-'}</b>
                </p>
              </div>
              <Link
                to={`/tourists/${touristId}/edit/`}
                style={{ height: 'min-content' }}
              >
                <button className="btn btn-primary">
                  Редактировать данные, назначить метку
                </button>
              </Link>
            </div>
            <Nav tabs className={'mt-3'}>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '1' })}
                  onClick={() => toggleTab('1')}
                >
                  <i className="fa fa-plane">{''}</i> Куда летал
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '2' })}
                  onClick={() => toggleTab('2')}
                >
                  <i className="fa fa-list">{''}</i> Запросы
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '3' })}
                  onClick={() => toggleTab('3')}
                >
                  <i className="fa fa-list-alt">{''}</i> Паспорта
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '4' })}
                  onClick={() => toggleTab('4')}
                >
                  <i className="fa fa-file-text-o">{''}</i> Документы
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink
                  className={classnames({ active: activeTab === '5' })}
                  onClick={() => toggleTab('5')}
                >
                  <i className="fa fa-tasks">{''}</i> Слияния
                </NavLink>
              </NavItem>
              <NavItem>
                <NavLink href={`/ordersfp/${fpPk}`}>
                  <i className="fa fa-users">{''}</i> Сменить менеджера
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab}>
              <TabPane tabId="1">
                <OrdersFpTable
                  count={orders.length}
                  items={orders}
                  handlePageChange={handlePageChange}
                  isLoading={isOrdersLoading}
                />
              </TabPane>
              <TabPane tabId="2">
                <Issues
                  tourist={touristId}
                  type="tourist"
                  issues={issues || []}
                  info={touristInfo}
                  onCreate={updateIssues}
                  onChangeActivity={onChangeActivity}
                  isLoading={isIssuesLoading}
                />
              </TabPane>
              <TabPane tabId="3">
                <Passports tourist={touristId} checkPassport={checkPassport} />
              </TabPane>
              <TabPane tabId="4">
                <div className="doc">
                  <TouristFiles tourist={touristId} />
                </div>
              </TabPane>
              <TabPane tabId="5">
                <div>
                  <Mergers client_id={touristId} client={'person_tourist'} />
                </div>
                <div>
                  <Mergers client_id={touristId} client={'tourist'} />
                </div>
              </TabPane>
            </TabContent>
          </CardBody>
        </div>
      </Card>
    </div>
  );
}

export default TouristFP;
