import React, { Component } from 'react';
import { IssuesAPI } from '../../../api/Client/Client';

import moment from 'moment';
import 'moment/locale/ru';
import { Link } from 'react-router-dom';

export default class CommentsList extends Component {
  constructor(props) {
    super(props);

    this.onCreate = this.onCreate.bind(this);

    this.state = {
      comments: [],
    };
  }

  componentDidMount() {
    this.setState({
      ...this.state,
      comments: this.props.comments,
    });
  }

  onCreate() {
    IssuesAPI.getList(1, { person: this.state.personId }).then((result) => {
      this.setState({
        ...this.state,
        comments: result.results.answers,
      });
    });
    this.props.onCreate.bind(this)();
  }
  render() {
    const COMMENT_HISTORY_ICON_CLASS = {
      comment: 'fa fa-commenting',
      meet: 'fa fa-calendar',
      deffered: 'fa fa-history',
      order: 'fa fa-smile-o text-success',
      new: 'fa dot-circle-o',
      cancel: 'fa fa-frown-o text-danger',
      we_cancel: 'fa fa-frown-o text-danger',
      client_cancel: 'fa fa-frown-o text-danger',
      tours: 'fa fa-th-list',
      transfer: 'fa fa-user',
      call: 'fa fa-phone',
    };
    let COMMENTS_REVERSE_LIST = this.state.comments
      ? [...this.state.comments]
      : [];
    return (
      <div>
        <div className="status-life mb-3">
          <i className="fa fa-dot-circle-o"></i>

          {COMMENTS_REVERSE_LIST.reverse().map((comment, key) => (
            <span key={key}>
              <i className="fa fa-long-arrow-right px-3"></i>
              <i
                className={COMMENT_HISTORY_ICON_CLASS[comment.type]}
                title={comment.type}
              ></i>
            </span>
          ))}
        </div>
        {this.state.comments.map((comment, key) => (
          <div
            className="p-2 text-dark issue__comment comment__wrapper"
            key={key}
          >
            {comment.type == 'tours' ? (
              <div>
                Подборка туров{' '}
                <Link
                  to={'https://geograftour.com/geoclient/' + comment.text}
                  target="blank"
                >
                  {comment.text} 
                </Link>{' | '}
                <Link to={`https://crm.geograftour.com/#/quotes/${comment.text}`}> Подборка в CRM</Link>
                <span className="float-right">
                  {moment(comment.created).format('DD.MM.YY H:mm')}
                </span>
              </div>
            ) : (
              <div>
                {comment.text ? comment.text : '-'}{' '}
                <span className="float-right">
                  {moment(comment.created).format('DD.MM.YY H:mm')}
                </span>
              </div>
            )}
          </div>
        ))}
      </div>
    );
  }
}
