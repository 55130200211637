import React, { PureComponent } from 'react';
import { Link } from 'react-router-dom';
import { LeadAPI } from '../../../api/Client/Client';

import LeadAdd from './LeadAddForm';
import LeadAddCustom from './LeadAddCustom';

export default class LeadStats extends PureComponent {
  constructor(props) {
    super(props);
    this.fetchItems = this.fetchItems.bind(this);

    this.state = {
      leads: {},
    };
  }

  fetchItems() {
    const self = this;
    LeadAPI.extModify(`/frontend/api/leads/count_leads_types/`)
      .then((response) => {
        if (response.status === 200) {
          return response.json();
        }
      })
      .then((result) =>
        self.setState({
          ...self.state,
          leads: result || {},
        })
      );
  }

  componentDidMount() {
    this.fetchItems();
  }

  render() {
    const Leads = {
      geo_site_chat: this.state.leads['geo_site_chat'] || 0,
      geo_site_quote: this.state.leads['geo_site_quote'] || 0,
      geo_site_form_hot: this.state.leads['geo_site_form_hot'] || 0,
      cabinet_online: this.state.leads['cabinet_online'] || 0,
      cabinet: this.state.leads['cabinet'] || 0,
      leads_total: this.state.leads['total'] || 0,
    };
    return (
      <>
        <div className="card">
          <div
            className="card-header"
            style={{ background: '#7ab973', color: '#fff' }}
          >
            <Link to={`/persons`}>
              <i
                className="fa fa-align-justify mr-2"
                style={{ color: '#5c9353' }}
              ></i>
            </Link>
            Заведи новый запрос
          </div>
          <LeadAddCustom />
        </div>
        <div className="card-persons card">
          <div className="card-header">
            <Link to={`/persons`}>
              <i className="fa fa-align-justify mr-2"></i>
            </Link>
            Потенциальные клиенты
            <span className="float-right pink-bg badge badge-default badge-pill">
              Всего {Leads.leads_total}
            </span>
          </div>
          <div className="card-body">
            <ul className="list-group">
              <li className="justify-content-between list-group-item">
                <i className="fa fa-star-half-o fa-sm mr-2"></i>
                <span className="">Заявка с сайта на подбор горящего тура</span>
                <span className="float-right badge badge-primary badge-pill">
                  {Leads.geo_site_form_hot}
                </span>
              </li>
              <li className="justify-content-between list-group-item">
                <i className="fa fa-star fa-sm mr-2"></i>
                <span className="">Заявка с сайта на готовый тур</span>
                <span className="float-right badge badge-primary badge-pill">
                  {Leads.cabinet_online}
                </span>
              </li>
              <li className="justify-content-between list-group-item">
                <i className="fa fa-commenting-o fa-sm mr-2"></i>
                <span className="">Запрос из чата</span>
                <span className="float-right badge badge-primary badge-pill">
                  {Leads.geo_site_chat}
                </span>
              </li>
              <li className="justify-content-between list-group-item">
                <i className="fa fa-check-square-o fa-sm mr-2"></i>
                <span className="">Геоподборка</span>
                <span className="float-right badge badge-primary badge-pill">
                  {Leads.geo_site_quote}
                </span>
              </li>
            </ul>
          </div>
        </div>
      </>
    );
  }
}
