import React from 'react';
import {
  Route,
  createBrowserRouter,
  createRoutesFromElements,
} from 'react-router-dom';

import App from './sections/app';
import Login from './sections/login';
import Officein from './sections/login/officein';

export const router = createBrowserRouter(
  createRoutesFromElements(
    <>
      <Route exact path="/login" element={<Login />} />
      <Route exact path="/officein" element={<Officein />} />
      <Route path="*" element={<App />} />
    </>
  )
);
