import React, { Component, useEffect, useState } from 'react';
import { Alert, Card, CardHeader, CardBody } from 'reactstrap';

import OfficeSelectContainer from '../../../components/OfficeSelectContainer';
import UserTelegramBotSettings from '../../../views/Options/components/UserTelegramBotSettings';
import { getUserRole, SUPER_ADMIN, ADMIN } from '../../../connect/auth';
import { POSITIVE_ACTION_STATUSES, UsersAPI } from '../../../api/Client';

import UserRoles from './UserRoles';
import ManagerManagerFp from './ManagerManagerFp';
import { useParams } from 'react-router-dom';

const userImage = '/img/add-user2.jpg';

function FormSubagent(props) {
  const { employeeId } = useParams();

  const [employeeInfo, setEmployeeInfo] = useState({
    username: '',
    email: '',
    first_name: '',
    last_name: '',
    offices: null,
    offices_city: null,
  });
  const [userRole, setUserRole] = useState({});
  const [userRoles, setUserRoles] = useState([]);
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState('');
  const [showSuccessMessage, setShowSuccessMessage] = useState(false);

  useEffect(() => {
    const { isFormCreate } = props;
    fetchRoles();
    if (!isFormCreate) {
      getInfo();
    }
  }, []);

  function getInfo() {
    UsersAPI.fetchJSON(employeeId)
      .then((r) => {
        if (r.ok) {
          return r.json();
        }
      })
      .then((res) => {
        setEmployeeInfo(res);
        setUserRole(res.profile.role);
      })
      .catch((err) => console.log(err));
  }

  function handleSubmit() {
    let { isFormCreate = false } = props;
    const { username, email, first_name, last_name, offices } = employeeInfo;

    const data = {
      username: username,
      email: email,
      first_name: first_name,
      last_name: last_name,
      offices:
        Array.isArray(offices) && offices.length
          ? offices.map((i) => i.id)
          : [],
      profile: {
        role: userRole.value,
      },
    };

    if (!isFormCreate) {
      updateUser(data);
    } else {
      createUser(data);
    }
  }

  function successMessage(callback = () => null) {
    setShowSuccessMessage(true);
    setErrors({});
    callback();
  }

  function updateUser(data) {
    let status;
    UsersAPI.update(`${UsersAPI.resource_url}${employeeId}`, data)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          successMessage();
        } else {
          setErrors(r);
        }
      });
  }

  function createUser(data) {
    let status;
    const { onCreate } = props;

    UsersAPI.create(data)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        setPassword('');
        if (status === POSITIVE_ACTION_STATUSES.create) {
          let password = r.hasOwnProperty('password') ? r.password : '';
          successMessage(handleReset(onCreate, password));
        } else {
          setErrors(r);
        }
      });
  }

  function handleReset(callback, password = '') {
    setEmployeeInfo({
      username: '',
      email: '',
      first_name: '',
      last_name: '',
      offices: null,
      offices_city: null,
    });
    setUserRoles(null);
    setErrors({});
    setPassword(password);
    callback();
  }

  function changeOffice(offices) {
    console.log(offices);
    setEmployeeInfo((prevState) => ({
      ...prevState,
      offices,
    }));
    setShowSuccessMessage(false);
    setErrors({});
  }
  function changeOfficeCity(offices_city) {
    setEmployeeInfo((prevState) => ({
      ...prevState,
      offices_city,
    }));
    setShowSuccessMessage(false);
  }

  function fetchRoles() {
    let status;
    UsersAPI.fetchJSON(`roles/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          setUserRoles(r);
        } else setErrors(r);
      });
  }

  function handleInputchange(e) {
    const { name, value } = e.target;
    setEmployeeInfo((prevState) => ({
      ...prevState,
      [name]: value,
    }));
    setShowSuccessMessage(false);
  }

  function handleChangePassword() {
    let status;
    UsersAPI.update(`${UsersAPI.resource_url}${employeeId}/passwordchanger`, {})
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((r) => {
        setPassword('');
        if (status === POSITIVE_ACTION_STATUSES.partial_update) {
          if (r.hasOwnProperty('password')) {
            setPassword(r.password);
          }
          successMessage();
        } else {
          setErrors(r);
          setShowSuccessMessage(false);
        }
      });
  }

  const {
    username,
    email,
    first_name,
    last_name,
    offices,
    offices_city,
    telegrambotsettings,
  } = employeeInfo;
  let { isFormCreate } = props;
  const currentUserRole = getUserRole();
  return (
    <Card className="mt-3">
      <CardHeader>
        <strong>Сотрудник</strong>
      </CardHeader>
      <CardBody className="card-body">
        <form
          action=""
          method="post"
          className="form-horizontal"
          onSubmit={handleSubmit}
        >
          <div className="row">
            <div className="col-md-8 col-sm-12">
              {showSuccessMessage ? (
                <Alert color="success">
                  <strong>Успешно!</strong>
                </Alert>
              ) : null}

              {Array.isArray(errors.non_field_errors) &&
              errors.non_field_errors.length ? (
                <Alert color="danger">
                  <strong>{errors.non_field_errors}</strong>
                </Alert>
              ) : null}

              <div className="form-group row">
                <label className="text-right col-md-2 col-form-label">
                  Логин
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="employeeUsername"
                    placeholder="Логин"
                    name="username"
                    value={username}
                    onChange={handleInputchange}
                  />
                  <p className="bg-danger">{errors.username}</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="text-right col-md-2 col-form-label">
                  Фамилия
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="EmployeeLastname"
                    placeholder="Введите фамилию"
                    name="last_name"
                    value={last_name}
                    onChange={handleInputchange}
                  />
                  <p className="bg-danger">{errors.last_name}</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="text-right col-md-2 col-form-label">
                  Имя
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    id="personName"
                    placeholder="Введите имя"
                    name="first_name"
                    value={first_name}
                    onChange={handleInputchange}
                  />
                  <p className="bg-danger">{errors.first_name}</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="text-right col-md-2 col-form-label">
                  Почта
                </label>
                <div className="col-md-8">
                  <input
                    type="text"
                    className="form-control"
                    placeholder="Электронная почта"
                    name="email"
                    value={email}
                    onChange={handleInputchange}
                  />
                  <p className="bg-danger">{errors.email}</p>
                </div>
              </div>

              <div className="form-group row">
                <label className="text-right col-md-2 col-form-label">
                  Выберите офис
                </label>
                <div className="col-md-8">
                  <OfficeSelectContainer
                    multi_office={true}
                    selected_city={offices_city}
                    selected_office={offices}
                    changeCity={changeOfficeCity}
                    changeOffice={changeOffice}
                  />
                  <p className="bg-danger">{errors.offices}</p>
                </div>
              </div>
              <div className="form-group row">
                <label className="text-right col-md-2 col-form-label">
                  Тип сотрудника
                </label>
                <div className="col-md-8">
                  <UserRoles
                    handleUserRole={setUserRole}
                    userRole={userRole}
                    userRoles={userRoles}
                  />
                  <p className="bg-danger">
                    {errors.profile && errors.profile.role}
                  </p>
                </div>
              </div>

              {currentUserRole === SUPER_ADMIN ? (
                <div className="form-group row">
                  <div className="col-md-6">
                    <button
                      type="button"
                      className="btn btn-sm btn-info"
                      onClick={handleChangePassword}
                    >
                      <i className="fa fa-dot-circle-o" />
                      {' Изменить пароль и выслать на email'}
                    </button>
                  </div>
                  {password ? (
                    <div className="col-md-6">
                      <Alert color="success">
                        Новый пароль: <strong>{password}</strong>
                      </Alert>
                    </div>
                  ) : null}
                </div>
              ) : null}

              {!isFormCreate ? null : (
                <div className="col-lg-4 col-md-6 col-sm-6">
                  <img src={userImage} width="100%" />
                </div>
              )}
            </div>
          </div>
        </form>
        <button
          type="submit"
          className="btn btn-sm btn-primary mr-2"
          onClick={handleSubmit}
        >
          <i className="fa fa-dot-circle-o"></i>
          {!isFormCreate ? ` Обновить` : ` Создать`}
        </button>
        <button
          type="reset"
          className="btn btn-sm btn-danger"
          onClick={() => handleReset(() => null)}
        >
          <i className="fa fa-ban">{''}</i> Очистить
        </button>
      </CardBody>
      {!isFormCreate && <ManagerManagerFp userId={employeeId} />}
      {!isFormCreate &&
        (currentUserRole === SUPER_ADMIN || currentUserRole === ADMIN) &&
        telegrambotsettings && (
          <UserTelegramBotSettings
            user_id={employeeId}
            data={telegrambotsettings}
          />
        )}
    </Card>
  );
}

export default FormSubagent;
