import React, { Component } from 'react';
import { IssuesAPI, POSITIVE_ACTION_STATUSES } from '../../../api/Client';
import { Alert } from 'reactstrap';

class IssueDeleteForm extends Component {
  constructor(props) {
    super(props);

    this.submit = this.submit.bind(this);
    this.successSubmit = this.successSubmit.bind(this);
    this.errorMessage = this.errorMessage.bind(this);
    this.doWithTimeout = this.doWithTimeout.bind(this);

    this.state = {
      showErrorMessage: false,
      showSuccessMessage: false,
    };
  }

  submit(e) {
    e.preventDefault();
    const { issueId } = this.props;
    let status;
    IssuesAPI.delete(issueId)
      .then((r) => {
        status = r.status;
        if (status === POSITIVE_ACTION_STATUSES.destroy) {
          return {};
        }
        return r.json();
      })
      .then((result) =>
        status === POSITIVE_ACTION_STATUSES.destroy
          ? this.successSubmit(result)
          : this.errorMessage(result)
      );
  }

  doWithTimeout(isSuccess = true, timeout = 2000) {
    setTimeout(() => {
      this.setState(
        {
          showErrorMessage: false,
          showSuccessMessage: false,
        },
        () => {
          if (isSuccess) {
            this.props.onChangeActivity();
          }
        }
      );
    }, timeout);
  }

  successSubmit(r) {
    this.setState(
      {
        ...this.state,
        showSuccessMessage: true,
      },
      () => this.doWithTimeout()
    );
  }

  errorMessage(err) {
    this.setState(
      {
        ...this.state,
        showErrorMessage: true,
      },
      () => this.doWithTimeout(false)
    );
  }

  render() {
    const { isLock, isActive } = this.props;
    const { showErrorMessage, showSuccessMessage } = this.state;
    return (
      <form className='p-3'>
        <button
          className="issue_btn btn btn-success"
          onClick={this.submit}
          disabled={isLock}
          type="button"
        >
          Удалить
        </button>
        {showErrorMessage ? (
          <Alert color="warning" className='mt-2 mb-2'>
            <strong>Ошибка!</strong> Не удалось
          </Alert>
        ) : null}
        {showSuccessMessage ? (
          <Alert color="success" className='mt-2 mb-2'>
            <strong>Успешно</strong> Вы будете перенаправлены
          </Alert>
        ) : null}
      </form>
    );
  }
}

export default IssueDeleteForm;
