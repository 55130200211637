import React, { PureComponent } from 'react';
import {
  StagingsAPI,
  POSITIVE_ACTION_STATUSES,
} from '../../../api/Client/Client';

export default class ListTouristsAll extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      count_tourist: 0,
    };
  }

  componentDidMount() {
    let status;
    StagingsAPI.fetchJSON(`count/`)
      .then((r) => {
        status = r.status;
        return r.json();
      })
      .then((result) => {
        if (status === POSITIVE_ACTION_STATUSES.list) {
          this.setState({ count_tourist: result });
        }
      });
    this.props.onFilter({ type: 'all' });
  }

  render() {
    return (
      <h4 className="text-success">
        Выбрано туристов: {this.state.count_tourist}
      </h4>
    );
  }
}
